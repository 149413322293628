import { ReactNode, useState } from "react";
import { ClientSectionContainer, ConnectWalletContainer } from "./styles";
import { Tabs, TabType } from "./Tabs";
import { Positions } from "./Positions";
import { OpenBids } from "./OpenBids";
import { Expired } from "./Expired";
import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { useClient } from "../../../hooks/useClient";
import { CancellableBids } from "./CancellableBids";
import { Wallet } from "../../Wallet";
import { Loader } from "../../Loader";

interface ClientSectionProps {
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

export const ClientSection = ({ className }: ClientSectionProps) => {
  const [selectedTab, setSelectedTab] = useState<TabType>(TabType.Positions);
  const wallet = useSafeWallet();
  const client = useClient();

  let content: ReactNode;

  switch (selectedTab) {
    case TabType.Positions:
      content = <Positions />;
      break;
    case TabType.OpenBids:
      content = <OpenBids />;
      break;
    case TabType.Expired:
      content = <Expired />;
      break;
    case TabType.CancellableBids:
      content = <CancellableBids />;
      break;
  }

  if (!wallet.connected) {
    content = (
      <ConnectWalletContainer>
        <Wallet />
      </ConnectWalletContainer>
    );
  } else if (!client.isInitialized) {
    content = (
      <ConnectWalletContainer>
        <Loader />
      </ConnectWalletContainer>
    );
  }

  return (
    <ClientSectionContainer className={className}>
      <Tabs selected={selectedTab} onTabChange={(tab) => setSelectedTab(tab)} />
      {content}
    </ClientSectionContainer>
  );
};
