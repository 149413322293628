import { Flex } from "@zetamarkets/flex-sdk";
import Big from "big.js";
import { useMemo } from "react";
import {
  capitalizeFirstLetter,
  getFormattedExpiry,
  getMintMapping,
  getUnderlyingStr,
} from "../../../utils/general";
import {
  Row,
  Data,
  DataText,
  Table,
  TableHead,
  Heading,
  HeadingText,
  TableBody,
  HeaderRow,
  ButtonData,
} from "../../TableStyles";
import {
  NotificationType,
  useNotificationStore,
} from "../../../stores/useNotificationStore";
import { ProgramError } from "@project-serum/anchor";
import { useUtils } from "../../../hooks/useUtils";
import {
  FlexOptionType,
  Option,
  SettlementType,
} from "@zetamarkets/flex-sdk/dist/flex/types";
import { useSnowflakeSafe } from "../../../hooks/useSnowflake";
import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { ExpiredContainer } from "../../DashboardSection/styles";
import { ExpiredButton } from "../../DashboardSection/ExpiredButton";
import { useClient } from "../../../hooks/useClient";
import { useFlex } from "../../../hooks/useFlex";
import { EmptyMessage } from "./EmptyMessage";

const NUM_COLUMNS = 17;

export const Expired = () => {
  const client = useClient();
  const flex = useFlex();
  const {
    convertNativeMintNumberToDecimal,
    convertNativeNumberToDecimal,
    getMultiplier,
    getTokenExchangeRate,
  } = useUtils();
  const wallet = useSafeWallet();
  const { createProposal } = useSnowflakeSafe();
  const notify = useNotificationStore((state) => state.notify);

  const expired = useMemo(() => {
    if (!client.isInitialized) return null;
    if (!flex.isInitialized) return null;
    const expiredPositions = client.data.client.optionPositions.filter(
      (pos) => {
        const optionInfo = Flex.getOption(
          pos.optionAccount,
          FlexOptionType.Normal
        );
        if (optionInfo == null) return false;
        const now = Flex.clockTimestamp;
        const expiry = optionInfo.expiry;
        const settlementStartDate = optionInfo.settlementStart;

        if (optionInfo.settlementType === SettlementType.CASH) {
          return now > expiry;
        } else {
          return settlementStartDate < now && now < expiry;
        }
      }
    );

    if (!expiredPositions.length) {
      return <EmptyMessage message={"There are no expired positions."} />;
    }

    return expiredPositions.map((pos) => {
      const optionInfo = Flex.getOption(
        pos.optionAccount,
        FlexOptionType.Normal
      );

      if (optionInfo == null) return null;
      const auction = flex.data.optionAuctions.find((a) =>
        a.optionInfo.optionAccount.equals(optionInfo.optionAccount)
      );

      const pnl = convertNativeMintNumberToDecimal(
        optionInfo.profitPerOption * pos.amount,
        optionInfo.collateralMint
      );
      const multiplier = getMultiplier(optionInfo.optionMint);

      const putMultiplier =
        1 /
        (convertNativeNumberToDecimal(
          (optionInfo as Option).strike.toNumber()
        ) *
          10);

      const premiumPerUnderlying = () => {
        if (!auction) return "-";
        const exchangeAmount = convertNativeMintNumberToDecimal(
          auction.exchangeAmount,
          auction.bidCurrencyMint
        );
        if (!exchangeAmount) return "-";

        if ((optionInfo as Option).kind === "call" && multiplier) {
          return Big(exchangeAmount)
            .div(auction.amount * multiplier)
            .toNumber();
        } else {
          return Big(exchangeAmount)
            .div(pos.amount * putMultiplier)
            .toNumber();
        }
      };

      const handleSettle = (qty?: number) => {
        const callback = notify(
          {
            title: "Settling option",
          },
          true
        );
        client.data.client
          .exerciseOption(optionInfo.optionAccount, qty, wallet.isSafeApp)
          .then(async (returnData) => {
            await createProposal("Exercise Option", returnData);
            callback({
              title: "Option settled",
              type: NotificationType.SUCCESS,
            });
          })
          .catch((err) => {
            console.error(err);
            if (err instanceof ProgramError)
              callback({
                title: "Settling failed",
                type: NotificationType.ERROR,
                description: err.msg,
                code: err.code,
              });
            else if (err instanceof Error)
              callback({
                title: "Settling failed",
                type: NotificationType.ERROR,
              });
          });
      };

      const underlying = getUnderlyingStr(optionInfo as Option);
      const bidCurrency = auction
        ? getMintMapping(auction.bidCurrencyMint)
        : "-";

      const bidCurrencyStr =
        bidCurrency === "11111111111111111111111111111111"
          ? "Any"
          : bidCurrency;

      const totalPremiumPaid = auction
        ? convertNativeMintNumberToDecimal(
            auction.exchangeAmount,
            auction.bidCurrencyMint
          )
        : "-";

      const premiumPerUnderlyingStr = premiumPerUnderlying().toString();
      const sizeInUnderlying =
        (optionInfo as Option).kind == "call" && multiplier
          ? Big(pos.amount).mul(multiplier).toNumber()
          : pos.amount * putMultiplier;
      const counterparty = optionInfo.creator.toString();
      const expiry = getFormattedExpiry(optionInfo.expiry, "client-expiry");
      const settlementPrice = Big(optionInfo.settlementPrice)
        .div(1000000)
        .toNumber();
      const pnlStr = (pnl || "-").toString();
      const kind = capitalizeFirstLetter((optionInfo as Option).kind);
      const strike = convertNativeNumberToDecimal(
        (optionInfo as Option).strike.toNumber()
      );
      const settlementCurrency = getMintMapping(optionInfo.collateralMint);
      const exerciseCurrency =
        optionInfo.settlementType === SettlementType.PHYSICAL
          ? getMintMapping((optionInfo as Option).exerciseMint)
          : "N/A";

      return (
        <Row columns={NUM_COLUMNS} key={pos.optionAccount.toString()}>
          {/* Direction */}
          <Data>
            <DataText color="positive" title="Buy">
              Buy
            </DataText>
          </Data>
          {/* Underlying */}
          <Data>
            <DataText title={underlying}>{underlying}</DataText>
          </Data>
          {/* Bid Currency*/}
          <Data>
            <DataText title={bidCurrencyStr}>{bidCurrencyStr}</DataText>
          </Data>
          {/* Total Premium Paid */}
          <Data>
            <DataText title={totalPremiumPaid?.toString()}>
              {totalPremiumPaid}
            </DataText>
          </Data>
          {/* Premium Per Underlying*/}
          <Data>
            <DataText title={premiumPerUnderlyingStr}>
              {premiumPerUnderlyingStr}
            </DataText>
          </Data>
          {/* Type */}
          <Data>
            <DataText title="European">European</DataText>
          </Data>
          {/* Instrument */}
          <Data>
            <DataText title={kind}>{kind}</DataText>
          </Data>
          {/* Strike */}
          <Data>
            <DataText title={strike.toString()}>{strike}</DataText>
          </Data>
          {/* Size in Underlying */}
          <Data>
            <DataText title={sizeInUnderlying.toString()}>
              {sizeInUnderlying}
            </DataText>
          </Data>
          {/* Counterparty */}
          <Data>
            <DataText title={counterparty}>{counterparty}</DataText>
          </Data>
          {/* Expiry */}
          <Data>
            <DataText title={expiry}>{expiry}</DataText>
          </Data>
          {/* Settlement Method */}
          <Data>
            <DataText title={capitalizeFirstLetter(optionInfo.settlementType)}>
              {capitalizeFirstLetter(optionInfo.settlementType)}
            </DataText>
          </Data>
          {/* Exercise Currency */}
          <Data>
            <DataText title={exerciseCurrency}>{exerciseCurrency}</DataText>
          </Data>
          {/* Settlement Currency */}
          <Data>
            <DataText title={settlementCurrency}>{settlementCurrency}</DataText>
          </Data>
          {/* Settlement Price */}
          <Data>
            <DataText title={settlementPrice.toString()}>
              {/* Currently this is settled is usdc always hence the hardcoded value */}
              {settlementPrice}
            </DataText>
          </Data>
          {/* PNL */}
          <Data>
            <DataText title={pnlStr}>{pnlStr}</DataText>
          </Data>
          <ButtonData>
            <DataText>
              <ExpiredButton
                option={optionInfo as Option}
                position={pos}
                disabled={pnl === undefined || pnl < 0}
                onSettle={handleSettle}
              />
            </DataText>
          </ButtonData>
        </Row>
      );
    });
  }, [
    client.isInitialized,
    client.data?.expiredPositions,
    client.data?.client,
    flex.isInitialized,
    convertNativeMintNumberToDecimal,
    getMultiplier,
    convertNativeNumberToDecimal,
    getTokenExchangeRate,
    notify,
  ]);

  return (
    <ExpiredContainer>
      <Table>
        <TableHead>
          <HeaderRow
            columns={
              client.data?.expiredPositions.length
                ? NUM_COLUMNS
                : NUM_COLUMNS - 1
            }
          >
            <Heading>
              <HeadingText title="Direction">DIRECTION</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Underlying">UNDERLYING</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Bid Currency">BID CURRENCY</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Total Premium Paid">
                TOTAL PREMIUM PAID
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Premium Per Underlying">
                PREMIUM PER UNDERLYING
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Type">TYPE</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Instrument">INSTRUMENT</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Strike">STRIKE</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Size in Underlying">
                SIZE IN UNDERLYING
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Counterparty">COUNTERPARTY</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Expiry (UTC)">EXPIRY (UTC)</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Settlement Method">
                SETTLEMENT METHOD
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Exercise Currency">
                EXERCISE CURRENCY
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Settlement Currency">
                SETTLEMENT CURRENCY
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Settlement Price">
                SETTLEMENT PRICE
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="PNL">PNL</HeadingText>
            </Heading>
          </HeaderRow>
        </TableHead>
        <TableBody>{expired}</TableBody>
      </Table>
    </ExpiredContainer>
  );
};
