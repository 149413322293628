import { ReactNode, useEffect, useState } from "react";
import { useClickOutside } from "../../hooks/useClickOutside";
import {
  DropdownContainer,
  DropdownHeader,
  DropdownBody,
  DropdownItem,
  DropdownItemText,
  DropdownHeaderText,
  DropdownItemWrapper,
  DropdownArrow,
  DropdownAnchor,
  DropdownIcon,
} from "./styles";
export type DropdownItem = {
  /**
   * This is the value visible when drop not open
   */
  preview?: string;
  /**
   * This is the value visible when drop *is* open
   */
  value: string;
  /**
   * Anchor link for dropdown item
   */
  link?: string;
  /**
   * Anchor link icon for dropdown item
   */
  src?: string;
  /**
   * Icon passed in as ReactNode
   */
  icon?: ReactNode;
  /**
   * Additional on click callback when dropdown item is selected
   */
  onClick?: () => void;
};

export type DropdownProps = {
  className?: string;
  /**
   * Child to use as dropdown header
   */
  children?: ReactNode | ReactNode[];
  /**
   * Key displays on header, value displays in body for each item in list
   */
  list: DropdownItem[];
  /**
   * A callback with the index of the selected option as the argument
   */
  onClick?: (idx: number) => void;
  /**
   * Selected index for dropdown (set as default)
   */
  selectedIndex?: number;
  /**
   * Default preview text to show on dropdown
   */
  defaultPreview?: string;
  /**
   * Disable selected dropdown item
   */
  disableSelected?: boolean;
  /**
   * Reactour reference as string
   */
  dataTut?: string;
};

export const Dropdown = ({
  className,
  children,
  list,
  onClick,
  selectedIndex,
  disableSelected,
  defaultPreview,
  dataTut,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // TODO: Get rid of this
  const [selectedIdx, setSelectedIdx] = useState<number>(0);
  const dropdownRef = useClickOutside(() => setIsOpen(false));

  useEffect(() => {
    if (selectedIndex !== undefined) {
      setSelectedIdx(selectedIndex);
    }
  }, [selectedIndex]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleItemClick =
    (idx: number, additionalOnClick?: () => void) => () => {
      if (!disableSelected && idx === selectedIdx) return;

      setSelectedIdx(idx);
      toggleDropdown();
      onClick && onClick(idx);
      // Any specific logic for individual dropdown items runs here
      additionalOnClick && additionalOnClick();
    };

  const dropdownItems = list.map((item, idx) => {
    const selectedIcon = disableSelected ? false : selectedIdx === idx;

    return item.link ? (
      <DropdownAnchor key={idx} href={item.link} target="_blank">
        <DropdownItem>
          <DropdownIcon src={item.src} />
          <DropdownItemText>{item.preview || item.value}</DropdownItemText>
        </DropdownItem>
      </DropdownAnchor>
    ) : (
      <DropdownItemWrapper selected={selectedIcon} key={idx}>
        <DropdownItem
          selected={selectedIcon}
          onClick={handleItemClick(idx, item.onClick)}
        >
          {item.icon}
          <DropdownItemText>{item.preview || item.value}</DropdownItemText>
        </DropdownItem>
      </DropdownItemWrapper>
    );
  });
  return (
    <DropdownContainer
      ref={dropdownRef}
      className={className}
      open={isOpen}
      data-tut={dataTut}
    >
      <DropdownHeader open={isOpen} onClick={toggleDropdown}>
        {children || (
          <DropdownHeaderText>
            {defaultPreview ||
              list[selectedIdx]?.preview ||
              list[selectedIdx]?.value ||
              "-"}
          </DropdownHeaderText>
        )}
        {!children && <DropdownArrow open={isOpen} />}
      </DropdownHeader>
      <DropdownBody open={isOpen}>{dropdownItems}</DropdownBody>
    </DropdownContainer>
  );
};
