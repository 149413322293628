/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled, { css } from "styled-components";
import { Button } from "../Button";

export const ButtonToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: ${(props) => props.theme.background.secondary};
  border-radius: 4px;
  height: fit-content;

  ${Button} {
    font-size: 16px;
    font-weight: 600;
    flex-grow: 1;
    flex-basis: 0;
  }
`;

export const ButtonOne = styled(Button)<{
  inverse: boolean;
  noBorder?: boolean;
  selected?: boolean;
}>`
  width: fit-content;
  border-top-right-radius: ${(props) =>
    !props.inverse || (!props.selected && !props.noBorder) ? "0px" : "4px"};
  border-bottom-right-radius: ${(props) =>
    !props.inverse || (!props.selected && !props.noBorder) ? "0px" : "4px"};
  color: ${(props) =>
    props.color
      ? props.theme.buttons[props.color]
      : props.selected
      ? props.theme.font.highlight
      : props.theme.font.subtext};
  background: ${(props) => props.selected && props.theme.background.primary};
  &:hover {
    color: ${(props) => !props.selected && props.theme.font.primary};
  }
`;
export const ButtonTwo = styled(Button)<{
  inverse: boolean;
  noBorder?: boolean;
  selected?: boolean;
}>`
  width: fit-content;
  border-top-left-radius: ${(props) =>
    !props.inverse || (!props.selected && !props.noBorder) ? "0px" : "4px"};
  border-bottom-left-radius: ${(props) =>
    !props.inverse || (!props.selected && !props.noBorder) ? "0px" : "4px"};
  color: ${(props) =>
    props.color
      ? props.theme.buttons[props.color]
      : props.selected
      ? props.theme.font.highlight
      : props.theme.font.subtext};
  background: ${(props) => props.selected && props.theme.background.primary};
  &:hover {
    filter: brightness(1.2);
  }

  ${(props) =>
    props.disabled &&
    css`
      background: none;
      border: 1px solid transparent;
      cursor: not-allowed;
      color: ${(props) => props.theme.font.unselectable};
    `}
`;
