export type ZetaTheme = typeof theme;
export type ButtonColors = keyof ZetaTheme["buttons"];

export const colors = {
  primary: {
    royalBlue: "#1241E5",
    lightBlue: "#79A8D7",
    offBlue: "#C4D8E8",
    green: "#66D370",
    emerald: "#00C68A",
    red: "#FF2459",
    yellow: "#E4FE49",
    orange: "#FF7A00",
    purple: "rgb(214, 177, 254)",
  },
  secondary: {
    white: "#fff",
    navyDark: "#030C1C",
    greyBlue: "#171D2E",
    grey: "#464A5F",
    greyDark: "#5f5b6c",
    lightGrey: "#BCBEC0",
    greyBlueMid: "#242847",
  },
  feedback: {
    grey: "#5f5b6c",
  },
  design: {
    purpleLight: "#d8d6ff",
  },
  hover: {
    grey: "#cccccc",
  },
} as const;

export const buttons = {
  primary: colors.secondary.greyBlue,
  positive: colors.primary.green,
  product: colors.primary.emerald,
  negative: colors.primary.red,
  callToAction: colors.primary.royalBlue,
  highlight: colors.primary.lightBlue,
  selectable: colors.secondary.lightGrey,
  unselectable: colors.secondary.greyDark,
  greyHover: colors.hover.grey,
  success: colors.primary.emerald,
  error: colors.primary.red,
  info: colors.primary.lightBlue,
  warning: colors.primary.orange,
  loading: colors.primary.yellow,
} as const;

export const mobile = {
  footerHeight: "70px",
  headerFooterHeight: "150px",
};

export const theme = {
  colors,
  background: {
    primary: colors.secondary.navyDark,
    secondary: colors.secondary.greyBlue,
    divider: colors.secondary.greyDark,
    table: colors.secondary.grey,
    feedback: colors.feedback.grey,
    selected: colors.secondary.greyBlueMid,
  },
  font: {
    primary: colors.secondary.white,
    positive: colors.primary.green,
    negative: colors.primary.red,
    product: colors.primary.emerald,
    callToAction: colors.primary.royalBlue,
    highlight: colors.primary.lightBlue,
    subtext: colors.primary.offBlue,
    selectable: colors.secondary.lightGrey,
    unselectable: colors.secondary.greyDark,
    success: colors.primary.emerald,
    error: colors.primary.red,
    info: colors.primary.lightBlue,
    warning: colors.primary.orange,
    loading: colors.primary.yellow,
  },
  buttons,
  mobile,
};
