import { ReactNode } from "react";
import styled from "styled-components";
import { ZetaTheme } from "../../utils/theme";
import { ButtonWrapper } from "./styles";

interface ButtonProps {
  /**
   * Children passed down as a prop
   */
  children?: ReactNode;
  /**
   * Button contents
   */
  label: string;
  /**
   * Boolean statement on whether or not the button is selected
   */
  selected?: boolean;
  /**
   * Optional color for button
   */
  color?: keyof ZetaTheme["buttons"];
  /**
   * Boolean for an inverse button style
   */
  inverse?: boolean;
  /**
   * Boolean if a border is required
   */
  noBorder?: boolean;
  /**
   * Boolean if button text is to be bolded
   */
  isBold?: boolean;
  /**
   * Optional click handler
   */
  onClick?: (label: string) => void;
  /**
   * Optional class name
   */
  className?: string;
  /**
   * Boolean if button can be disabled
   */
  disabled?: boolean;
  title?: string;
}

export const Button = styled(
  ({
    children,
    className,
    label,
    onClick,
    selected,
    color,
    inverse,
    noBorder,
    isBold,
    disabled,
    title,
  }: ButtonProps) => {
    return (
      <ButtonWrapper
        title={title}
        className={className}
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick(label);
        }}
        selected={selected ? selected : false}
        color={color ? color : "selectable"}
        inverse={inverse ? inverse : false}
        noBorder={noBorder ? noBorder : false}
        isBold={isBold ? isBold : false}
        disabled={disabled || false}
      >
        {children}
        {label}
      </ButtonWrapper>
    );
  }
)``;
