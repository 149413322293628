import styled, { keyframes } from "styled-components";
import { Modal } from "../Modal";
import { Label, Paragraph, Subtitle } from "../Text";
import { Input } from "../sharedStyles";
import { Button } from "../Button";
import { EmptyMessage } from "../ClientSection/Option/EmptyMessage";

const loading = keyframes`
  0% {
    content: "Loading Client";
  }
  25% {
    content: "Loading Client.";
  }
  50% {
    content: "Loading Client..";
  }
  75% {
    content: "Loading Client...";
  }
`;

export const DashboardSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ClientSectionViewContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

export const ButtonText = styled(Paragraph)`
  color: #0d1238;
`;

export const CooldownLabel = styled(Label)``;

export const TableTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ConnectWalletContainer = styled(TableTextContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ClientLoader = styled.div`
  height: 100%;
  color: ${(props) => props.theme.font.primary};
  &::after {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;

    content: "Loading Client";
    animation: ${loading} 2s linear infinite;
  }
`;

export const AttributeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const HelperTextContainer = styled(AttributeContainer)`
  margin-bottom: 0;
  margin-top: 1rem;
  > * + * {
    margin-top: 0.5rem;
  }

  ${Paragraph} {
    font-size: 12px;
  }

  ${Subtitle} {
    font-size: 16px;
  }
`;

export const SettleText = styled(Paragraph)`
  display: flex;
  align-items: center;
`;
export const SettleButton = styled(Button)<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.buttons.unselectable
      : props.theme.buttons.callToAction};

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: 100%;
  height: 32px;
  border-radius: 5px;
  transition: 200ms;
  font-size: 16px;
  &:hover {
    filter: brightness(1.2);
  }
  color: ${(props) =>
    props.disabled ? props.theme.background.primary : props.theme.font.primary};
`;

export const TokenAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: ${(props) => `1px solid ${props.theme.background.secondary}`};
  margin-bottom: 1rem;
  padding: 0.5rem;
  align-items: flex-start;
  > * + * {
    margin-top: 0.2rem;
  }
`;
export const TextLabel = styled(Paragraph)`
  font-weight: 600;
  font-size: 12px;
`;
export const TextValue = styled(Subtitle)`
  font-size: 16px;
  color: ${(props) => props.theme.font.subtext};
`;

export const InputWrapper = styled.div`
  background: ${(props) => props.theme.background.secondary};
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.background.secondary};
  padding: 0 0.5rem 0 0;
  border-radius: 4px 4px 0px 0px;
  margin-top: 0.2rem;

  ${Input} {
    border: none;
    color: ${(props) => props.theme.font.subtext};
    font-size: 16px;
    padding: 0.52rem;
  }
`;

export const MaxButton = styled(Label)`
  cursor: pointer;
  color: ${(props) => props.theme.font.highlight};
`;

export const ExerciseInput = styled(Input)`
  background: ${(props) => props.theme.background.secondary};
`;

export const InputHeading = styled(Subtitle)`
  font-size: 24px;
  margin-bottom: 1.5rem;
`;

export const InterfaceContainer = styled.div`
  margin-bottom: 1rem;
`;

export const SettlementModalContainer = styled(Modal)`
  width: 300px;
`;

export const ClientButton = styled(Button)<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 0.5rem 2rem;
  border-radius: 6px;
  font-weight: 700;
  font-size: 16px;
  height: 28px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => props.disabled && "none"};
  background: ${(props) => props.theme.background.secondary};
  transition: 200ms;

  &:hover {
    filter: brightness(1.2);
  }
`;

export const CollectButton = styled(ClientButton)``;

export const ExpiredButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${ClientButton} {
    width: -webkit-fill-available;
  }
`;
export const ExpiredContainer = styled(ClientSectionViewContainer)``;

export const InlineHighlightParagraph = styled.span`
  color: ${(props) => props.theme.buttons.highlight};
  font-weight: 600;
`;

export const HeadingSpan = styled.div`
  display: flex;
  > * + * {
    margin-left: 0.4rem;
  }
`;

export const DashboardEmptyMessage = styled(EmptyMessage)`
  margin: 2rem 0;
`;
