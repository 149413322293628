import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  font-size: 16px;
  padding: 0.25rem 0.5rem;

  background: ${(props) => props.theme.background.selected};
  border-radius: 4px;
  color: ${(props) => props.theme.font.primary};
`;
