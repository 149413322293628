import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { Flex } from "@zetamarkets/flex-sdk";
import { DummyWallet } from "@zetamarkets/flex-sdk/dist/common/types";
import { Auction } from "@zetamarkets/flex-sdk/dist/flex/types";
import { useEffect, useState } from "react";
import shallow from "zustand/shallow";
import { DataOnInitialized } from "../@types/custom";
import { OptionAuction, useFlexStore } from "../stores/useFlexStore";
import { getSolanaConnection, getSolanaNetwork } from "../utils/connection";
import { envVars } from "../utils/envVars";
import { AuctionState, getAuctionState, isOption } from "../utils/general";
import { useClient } from "./useClient";

export const useFlexSetup = (): void => {
  const client = useClient();
  const [flexInterval, setFlexInterval] = useState<NodeJS.Timer>();
  const { setIsInitialized, setAuctions, isInitialized } = useFlexStore(
    (s) => ({
      setIsInitialized: s.setIsInitialized,
      setAuctions: s.setAuctions,
      isInitialized: s.isInitialized,
    }),
    shallow
  );
  const wallet = useSafeWallet();

  useEffect(() => {
    Flex.load(
      new PublicKey(envVars.VITE_FLEX_PROGRAM_ID),
      getSolanaNetwork(),
      getSolanaConnection(),
      new DummyWallet(),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    )
      .then(async () => {
        setIsInitialized(true);
        await Flex.updateState();
      })
      .catch((err) => {
        console.error(err);
      });
  }, [setIsInitialized]);

  useEffect(() => {
    if (!isInitialized) return;

    let interval: NodeJS.Timer | undefined = undefined;
    if (!client.isInitialized) {
      // Interval already exists don't need to make another
      if (flexInterval) return;
      interval = setInterval(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        Flex.updateState();
      }, parseInt(envVars.VITE_FLEX_UPDATE_INTERVAL));
      setFlexInterval(interval);
    } else {
      // At this point client has been intialised
      // Client will take over the updateState()
      // We must stop now.
      if (flexInterval) {
        clearInterval(flexInterval);
      }
    }
  }, [client.isInitialized, flexInterval, isInitialized, wallet.connected]);

  useEffect(() => {
    if (!isInitialized) return;
    const setLiveAuctions = () => {
      // Sort to have soonest expiring auctions first
      const sorted = Flex.liveAuctions.sort((a, b) =>
        a.bidEnd < b.bidEnd ? -1 : a.bidEnd > b.bidEnd ? 1 : 0
      );
      const liveIndex = sorted.findIndex((a) => {
        const state = getAuctionState(a);

        return state !== AuctionState.Cooldown;
      });

      let formatted: Auction[];
      if (liveIndex === -1) {
        // Predicate not found (all in cooldown)
        formatted = sorted;
      } else {
        // Move the auctions in cooldown to the bottom
        formatted = [...sorted.slice(liveIndex), ...sorted.slice(0, liveIndex)];
      }

      const optionAuctions: OptionAuction[] = formatted.filter((a) =>
        isOption(a.optionInfo)
      ) as OptionAuction[];

      const spotAuctions = formatted.filter((a) => !isOption(a.optionInfo));

      setAuctions({ option: optionAuctions, spot: spotAuctions });
    };

    setLiveAuctions();

    const interval = setInterval(() => {
      setLiveAuctions();
    }, parseInt(envVars.VITE_FLEX_UPDATE_INTERVAL));

    return () => clearInterval(interval);
  }, [isInitialized, setAuctions]);
};

type FlexHook = {
  optionAuctions: OptionAuction[];
  spotAuctions: Auction[];
};

type FlexHookReturnType = DataOnInitialized<FlexHook>;

export const useFlex = (): FlexHookReturnType => {
  const { isInitialized, auctions } = useFlexStore(
    (s) => ({
      isInitialized: s.isInitialized,
      auctions: s.auctions,
    }),
    shallow
  );

  if (!isInitialized) return { isInitialized: false };

  return {
    isInitialized: true,
    data: {
      optionAuctions: auctions.option,
      spotAuctions: auctions.spot,
    },
  };
};
