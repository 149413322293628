import { useEffect, useMemo, useState } from "react";
import { Paragraph, Subtitle } from "../../Text";
import {
  AuctionPropertiesContainer,
  AuctionProperty,
  PurchaseSectionContainer,
  TextSpan,
} from "./styles";
import { useFlex } from "../../../hooks/useFlex";
import {
  getFormattedExpiry,
  getMintMapping,
  shortenAddress,
} from "../../../utils/general";
import { PublicKey } from "@solana/web3.js";
import { PurchaseInterface } from "./PurchaseInterface";
import { Orderbook } from "./Orderbook";
import { Button } from "../../Button";
import styled from "styled-components";
import axios from "axios";
import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { useClient } from "../../../hooks/useClient";
import { getSolanaConnection } from "../../../utils/connection";
import { envVars } from "../../../utils/envVars";
import Big from "big.js";
import { useUtils } from "../../../hooks/useUtils";
import { BlindAuction } from "./BlindAuction";
import { Dropdown } from "../../Dropdown";
import { DropdownBody } from "../../Dropdown/styles";

interface PurchaseSectionProps {
  selectedAuction: PublicKey | undefined;
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

export const PurchaseSection = ({
  selectedAuction,
  className,
}: PurchaseSectionProps) => {
  const flex = useFlex();
  const client = useClient();
  const {
    getBidCurrencyBalance,
    convertNativeNumberToDecimal,
    getOptionAuction,
  } = useUtils();
  const wallet = useSafeWallet();

  const [nativeSolBal, setNativeSolBal] = useState<number>();
  const [underlyingMintDropdownIndex, setUnderlyingMintDropdownIndex] =
    useState<number>(0);

  const auction = getOptionAuction(selectedAuction);

  const walletTokensDropdownList = useMemo(() => {
    const walletTokens = client.data?.walletTokens;
    if (!walletTokens) return [];
    return walletTokens.map((token) => {
      const formattedPreview = shortenAddress(token);
      return { value: token, preview: formattedPreview };
    });
  }, [client.data?.walletTokens]);

  const isAnyBidCurrencyAuction = useMemo(() => {
    return (
      auction?.bidCurrencyMint.toString() === "11111111111111111111111111111111"
    );
  }, [auction]);

  const anyBidCurrencyMint = useMemo(() => {
    if (!isAnyBidCurrencyAuction || !walletTokensDropdownList.length)
      return undefined;
    return walletTokensDropdownList[underlyingMintDropdownIndex].value;
  }, [
    isAnyBidCurrencyAuction,
    underlyingMintDropdownIndex,
    walletTokensDropdownList,
  ]);

  const optionDescStr = useMemo(() => {
    if (auction === undefined) return "-";
    const optionExpiry = auction.optionInfo.expiry;
    const underlyingStr = getMintMapping(auction.optionInfo.underlyingMint);

    return `${underlyingStr} ${getFormattedExpiry(
      optionExpiry,
      "purchase-option-detail"
    ).toUpperCase()} ${convertNativeNumberToDecimal(
      auction.optionInfo.strike.toNumber()
    )}`;
  }, [auction, convertNativeNumberToDecimal]);

  const optionKindStr = useMemo(() => {
    if (auction === undefined) return "-";

    return `${auction.optionInfo.kind.toUpperCase()}`;
  }, [auction]);

  const optionExpiryStr = useMemo(() => {
    const optionExpiry = auction?.optionInfo.expiry;
    if (optionExpiry === undefined) return "-";

    return getFormattedExpiry(optionExpiry, "purchase-auction-expiry");
  }, [auction?.optionInfo.expiry]);

  const optionSettlement = useMemo(() => {
    const optionSettlement = auction?.optionInfo.oracleMethod;
    if (optionSettlement === undefined) return "-";

    if (optionSettlement === "spotPrint") return "Pyth Spot Print";

    if (optionSettlement === "manualPrint") return "Manual Settlement Print";
  }, [auction?.optionInfo.oracleMethod]);

  const bidCurrencyBalanceStr = useMemo(() => {
    const fallback = "-";
    if (!flex.isInitialized || !client.isInitialized) return fallback;

    const auction = getOptionAuction(selectedAuction);

    if (!auction) return fallback;

    if (
      isAnyBidCurrencyAuction &&
      walletTokensDropdownList.length &&
      anyBidCurrencyMint
    ) {
      const bidCurrencyBal = getBidCurrencyBalance(
        auction,
        client.data.client,
        new PublicKey(anyBidCurrencyMint)
      );
      const bidCurrencyStr = shortenAddress(anyBidCurrencyMint);
      return `${bidCurrencyBal || 0} (${bidCurrencyStr})`;
    }

    const bidCurrencyStr = getMintMapping(auction.bidCurrencyMint);

    if (bidCurrencyStr === "SOL") {
      return nativeSolBal;
    }

    const bidCurrencyBal = getBidCurrencyBalance(auction, client.data.client);

    if (!bidCurrencyBal) return fallback;

    return `${bidCurrencyBal} (${bidCurrencyStr})`;
  }, [
    flex.isInitialized,
    client.isInitialized,
    client.data?.client,
    getOptionAuction,
    selectedAuction,
    getBidCurrencyBalance,
    nativeSolBal,
    underlyingMintDropdownIndex,
  ]);

  const handleAirdropUSDC = async () => {
    if (!wallet.publicKey) return;
    try {
      await axios.post(
        "https://flex-devnet-webserver.zeta.markets/faucet/USDC",
        {
          key: wallet.publicKey.toString(),
          amount: 10000000000,
        }
      );
    } catch (err) {
      console.error("Error airdropping USDC");
      console.error(err);
    }
  };

  const handleAirdropSol = async () => {
    if (!wallet.publicKey) return;
    try {
      await axios.post(
        "https://flex-devnet-webserver.zeta.markets/faucet/SOL",
        {
          key: wallet.publicKey.toString(),
          amount: 1000000000000,
        }
      );
    } catch (err) {
      console.error("Error airdropping SOL");
      console.error(err);
    }
  };

  const handleAirdropSRM = async () => {
    if (!wallet.publicKey) return;
    try {
      await axios.post(
        "https://flex-devnet-webserver.zeta.markets/faucet/SRM",
        {
          key: wallet.publicKey.toString(),
          amount: 1000000000000,
        }
      );
    } catch (err) {
      console.error("Error airdropping SOL");
      console.error(err);
    }
  };

  useEffect(() => {
    if (!wallet.publicKey) return;
    const conn = getSolanaConnection();

    conn
      .getAccountInfo(wallet.publicKey)
      .then((info) => {
        if (!info?.lamports) return;

        const balance = Big(info?.lamports).div(Math.pow(10, 9));

        setNativeSolBal(balance.toNumber());
      })
      .catch((err) => {
        console.error("Error fetching sol balance");
        console.error(err);
      });
  }, [
    wallet.publicKey,
    client.data?.bids.length,
    client.data?.expiredPositions.length,
  ]);

  return (
    <PurchaseSectionContainer className={className}>
      <AuctionPropertiesContainer>
        <AuctionProperty>
          <TextSpan>
            <Subtitle bold color="subtext">
              {optionDescStr}
            </Subtitle>
            {optionDescStr !== "-" && (
              <Subtitle bold color="product">
                {optionKindStr}
              </Subtitle>
            )}
          </TextSpan>
          <Text bold>Option</Text>
        </AuctionProperty>
        <AuctionProperty>
          <Subtitle bold color="subtext">
            {optionExpiryStr}
          </Subtitle>
          <Text bold>Expiry Details</Text>
        </AuctionProperty>
        <AuctionProperty>
          <Subtitle bold color="subtext">
            {optionSettlement}
          </Subtitle>
          <Text bold>Settlement Method</Text>
        </AuctionProperty>
        <AuctionProperty>
          <Subtitle bold color="subtext">
            {auction?.amount || "-"}
          </Subtitle>
          <Text bold>Option Quantity</Text>
        </AuctionProperty>
      </AuctionPropertiesContainer>
      {envVars.VITE_DISABLE_BLIND_AUCTIONS === "true" ? (
        <Orderbook selectedAuction={selectedAuction} />
      ) : (
        <BlindAuction />
      )}
      <PurchaseInterface
        selectedAuction={selectedAuction}
        bidCurrencyMint={anyBidCurrencyMint}
      />
      {isAnyBidCurrencyAuction && (
        <PurchaseDetail>
          <Label bold>Bid Currency</Label>
          <Dropdown
            selectedIndex={underlyingMintDropdownIndex}
            list={walletTokensDropdownList}
            onClick={setUnderlyingMintDropdownIndex}
          />
        </PurchaseDetail>
      )}
      <PurchaseDetail>
        <Label bold>Bid Currency Balance</Label>
        <PurchaseDetailText color="subtext" bold>
          {bidCurrencyBalanceStr}
        </PurchaseDetailText>
      </PurchaseDetail>
      <PurchaseDetail>
        <Label bold>Native SOL Balance</Label>
        <PurchaseDetailText color="subtext" bold>
          {nativeSolBal || "-"}
        </PurchaseDetailText>
      </PurchaseDetail>
      {envVars.VITE_NETWORK_TYPE === "devnet" && (
        <AirdropButtons>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <AirdropButton onClick={handleAirdropSRM} label="" color="positive">
            Airdrop $10K SRM
          </AirdropButton>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <AirdropButton onClick={handleAirdropUSDC} label="" color="highlight">
            Airdrop $10K USDC
          </AirdropButton>
          <AirdropButton
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleAirdropSol}
            label=""
            color="callToAction"
          >
            Airdrop 1000 SOL
          </AirdropButton>
        </AirdropButtons>
      )}
    </PurchaseSectionContainer>
  );
};

const Text = styled(Paragraph)`
  font-weight: 500;
  text-align: center;
`;

const PurchaseDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
  width: 100%;

  ${DropdownBody} {
    top: 20px;
    max-height: 200px;
    scoll-x: auto;
  }
`;

const PurchaseDetailText = styled(Subtitle)``;
const Label = styled(Text)``;

const AirdropButton = styled(Button)`
  width: 100%;
`;

const AirdropButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  margin-top: auto;
`;
