import { ButtonToggle } from "../../../components/ButtonToggle";
import { AUCTION_TYPE } from "../AuctionInterface";
import { AuctionHeaderContainer } from "./styles";

interface AuctionHeaderProps {
  currentAuction: AUCTION_TYPE;
  handleAuctionType: (newAuction: AUCTION_TYPE) => void;
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

export const AuctionHeader = ({
  className,
  currentAuction,
  handleAuctionType,
}: AuctionHeaderProps) => {
  const changeToSpotAuction = () => {
    handleAuctionType(AUCTION_TYPE.SPOT);
  };
  const changeToOptionAuction = () => {
    handleAuctionType(AUCTION_TYPE.OPTION);
  };

  const isSpotAuction = currentAuction !== AUCTION_TYPE.OPTION;

  return (
    <AuctionHeaderContainer className={className}>
      <ButtonToggle
        colorLeft={"highlight"}
        colorRight={"highlight"}
        labelLeft={"Option Auctions"}
        labelRight={"Spot Auctions"}
        selected={isSpotAuction ? "Spot Auctions" : "Option Auctions"}
        inverse
        onClickLeft={changeToOptionAuction}
        onClickRight={changeToSpotAuction}
      />
    </AuctionHeaderContainer>
  );
};
