/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PublicKey } from "@solana/web3.js";
import { useEffect, useMemo, useState } from "react";
import { useClient } from "../../hooks/useClient";
import { useUtils } from "../../hooks/useUtils";
import * as anchor from "@project-serum/anchor";
import {
  flexConstants,
  flexTypes,
  flexUtils,
  utils,
} from "@zetamarkets/flex-sdk";
import {
  OptionGeneratorContainer,
  AppContainer,
  InputGroupContainer,
  InputRow,
  InputColumn,
  MintContainer,
  OptionsGeneratorInput,
  StyledDateTimePicker,
  AddButtonRow,
  AddButton,
  InnerRow,
  InputGroupDescription,
  NumberIcon,
  SummaryContainer,
  SummaryRow,
  Label,
  MintButton,
  StaticContentText,
  StaticTitle,
  ExpiryInputRow,
  ExpiryInputColumn,
  SummaryInputColumn,
  SubtitleTextSpan,
} from "./styles";
import { getSolanaNetwork } from "../../utils/connection";
import { Header, Paragraph, Subtitle } from "../../components/Text";
import { Dropdown } from "../../components/Dropdown";
import { getFormattedExpiry } from "../../utils/general";
import {
  NotificationType,
  useNotificationStore,
} from "../../stores/useNotificationStore";
import { envVars } from "../../utils/envVars";
import { Wallet } from "../../components/Wallet";
import { Kind } from "@zetamarkets/flex-sdk/dist/flex/types";
import PlusSVG from "../../assets/images/plus.svg";
import { getCollateralRequiredForComboOption } from "@zetamarkets/flex-sdk/dist/flex/flex-utils";
import { ComboOptionRow } from "./ComboOptionRow";
import { COMBO_SIZE_PRECISION } from "@zetamarkets/flex-sdk/dist/flex/constants";
import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { useSnowflakeSafe } from "../../hooks/useSnowflake";
import { UNDERLYINGS_TO_PUBKEY_MAP } from "@zetamarkets/flex-sdk/dist/common/constants";
import { ConnectWalletContainer } from "../../components/ClientSection/Option/styles";
import { ButtonToggle } from "../../components/ButtonToggle";
import { useFlex } from "../../hooks/useFlex";

const physExerciseCollateralMintDropdownList = [
  { value: UNDERLYINGS_TO_PUBKEY_MAP.mainnet["USDC"], preview: "USDC" },
  { value: UNDERLYINGS_TO_PUBKEY_MAP.mainnet["USDH"], preview: "USDH" },
  { value: UNDERLYINGS_TO_PUBKEY_MAP.mainnet["USH"], preview: "USH" },
];
const exerciseCollateralMintDropdownList = [
  { value: UNDERLYINGS_TO_PUBKEY_MAP.mainnet["USDC"], preview: "USDC" },
  { value: UNDERLYINGS_TO_PUBKEY_MAP.mainnet["USDH"], preview: "USDH" },
];

const devnetExerciseCollateralMintDropdownList = [
  {
    value: UNDERLYINGS_TO_PUBKEY_MAP.devnet["MOCK_USDC"],
    preview: "MOCK USDC",
  },
];

const timezoneOffsetMs = new Date().getTimezoneOffset() * 60 * 1000;
const hourInMs = 60 * 60 * 1000;
const defaultOption: flexTypes.ComboOptionParams = {
  size: 0,
  strike: 0,
  kind: Kind.CALL,
};

export const MintingInterface = () => {
  const wallet = useSafeWallet();
  const { createProposal } = useSnowflakeSafe();
  const client = useClient();
  const flex = useFlex();
  const {
    convertDecimalToNativeMintNumberAsync,
    convertNativeMintNumberToDecimal,
    convertDecimalToNativeMintNumber,
    getOptionPrecision,
  } = useUtils();
  const notify = useNotificationStore((s) => s.notify);

  const [optionForm, setOptionForm] = useState<"Normal" | "Combo">();
  const [settlementType, setSettlementType] = useState<"Cash" | "Physical">();
  const [optionType, setOptionType] = useState<"Call" | "Put">();
  const [optionAmount, setOptionAmount] = useState<string>("");
  const [assetStrike, setAssetStrike] = useState<string>("");
  const [settlementStart, setSettlementStart] = useState(
    new Date(new Date().getTime() + timezoneOffsetMs + hourInMs)
  );
  const [expiry, setExpiry] = useState(
    new Date(new Date().getTime() + timezoneOffsetMs + hourInMs)
  );
  const [underlyingMintDropdownIndex, setUnderlyingMintDropdownIndex] =
    useState<number>(0);
  const [exerciseMintDropdownIndex, setExerciseMintDropdownIndex] =
    useState<number>(0);
  const [collateralMintDropdownIndex, setCollateralMintDropdownIndex] =
    useState<number>(0);
  const [comboOptionList, setComboOptionList] = useState<
    Record<number, flexTypes.ComboOptionParams>
  >({ 0: defaultOption, 1: defaultOption });
  const [comboOptionListCount, setComboOptionListCount] = useState<number>(2);
  const [formattedComboOptionList, setFormattedComboOptionList] = useState<
    flexTypes.ComboOptionParams[]
  >([]);
  const [collatRequired, setCollatRequired] = useState<number | undefined>(
    undefined
  );
  const [collatError, setCollatError] = useState<string | undefined>(undefined);
  const [comboCollatRequired, setComboCollatRequired] = useState<
    number | undefined
  >(undefined);
  const [comboCollatError, setComboCollatError] = useState<string | undefined>(
    undefined
  );

  const cashSettled = useMemo(() => {
    return settlementType === "Cash";
  }, [settlementType]);

  const isCall = useMemo(() => {
    return optionType === "Call";
  }, [optionType]);

  const isCombo = useMemo(() => {
    return optionForm === "Combo";
  }, [optionForm]);

  const typesSelected = useMemo(() => {
    return (
      optionForm === "Combo" ||
      (optionForm === "Normal" &&
        settlementType !== undefined &&
        optionType !== undefined)
    );
  }, [optionForm, settlementType, optionType]);

  const validComboOptionList = useMemo(() => {
    return Object.values(comboOptionList).filter((option) => {
      return (
        (option.kind === Kind.CALL || option.kind === Kind.PUT) &&
        !isNaN(option.size) &&
        !isNaN(option.strike) &&
        option.strike > 0
      );
    });
  }, [comboOptionList]);

  const whitelistedMintDropdownList = useMemo(() => {
    const mints =
      envVars.VITE_NETWORK_TYPE === "devnet"
        ? UNDERLYINGS_TO_PUBKEY_MAP.devnet
        : UNDERLYINGS_TO_PUBKEY_MAP.mainnet;
    let whitelistMints = Object.keys(mints);
    if (cashSettled || isCombo) {
      whitelistMints = whitelistMints.filter((mint) => {
        return mint !== "HBB";
      });
    }
    if (envVars.VITE_NETWORK_TYPE === "devnet") {
      return Object.keys(UNDERLYINGS_TO_PUBKEY_MAP.devnet).map(
        (key: keyof typeof UNDERLYINGS_TO_PUBKEY_MAP.devnet) => {
          return { value: UNDERLYINGS_TO_PUBKEY_MAP.devnet[key], preview: key };
        }
      );
    }
    return whitelistMints.map(
      (key: keyof typeof UNDERLYINGS_TO_PUBKEY_MAP.mainnet) => {
        return { value: UNDERLYINGS_TO_PUBKEY_MAP.mainnet[key], preview: key };
      }
    );
  }, [isCombo, cashSettled]);

  const whitelistedWalletTokensDropdownList = useMemo(() => {
    let walletTokens = client.data?.whitelistedWalletTokens;
    if (!walletTokens) return [];
    if (cashSettled) {
      walletTokens = walletTokens.filter((token) => {
        return token.underlying !== "HBB";
      });
    }
    return walletTokens.map((token) => {
      let formattedPreview = token.underlying;
      if (token.underlying.split("_").length > 1) {
        formattedPreview = token.underlying.split("_").join(" ");
      }
      return { value: token.pubkey, preview: formattedPreview };
    });
  }, [client.data?.walletTokens, cashSettled]);

  const underlying = useMemo(() => {
    if (!whitelistedWalletTokensDropdownList.length) return "";
    if ((cashSettled && !isCall) || isCombo) {
      return whitelistedMintDropdownList[underlyingMintDropdownIndex].value;
    }
    if (
      underlyingMintDropdownIndex >
      whitelistedWalletTokensDropdownList.length - 1
    ) {
      return whitelistedWalletTokensDropdownList[0].value;
    } else {
      return whitelistedWalletTokensDropdownList[underlyingMintDropdownIndex]
        .value;
    }
  }, [
    optionForm,
    optionType,
    settlementType,
    whitelistedMintDropdownList,
    whitelistedWalletTokensDropdownList,
    underlyingMintDropdownIndex,
  ]);

  const underlyingAsset = useMemo(() => {
    if (!whitelistedWalletTokensDropdownList.length) return "";
    if ((cashSettled && !isCall) || isCombo) {
      return whitelistedMintDropdownList[underlyingMintDropdownIndex].preview;
    }
    if (
      underlyingMintDropdownIndex >
      whitelistedWalletTokensDropdownList.length - 1
    ) {
      return whitelistedWalletTokensDropdownList[0].preview;
    } else {
      return whitelistedWalletTokensDropdownList[underlyingMintDropdownIndex]
        .preview;
    }
  }, [
    optionForm,
    optionType,
    settlementType,
    whitelistedMintDropdownList,
    whitelistedWalletTokensDropdownList,
    underlyingMintDropdownIndex,
  ]);

  const collateral = useMemo(() => {
    if (isCall && !isCombo) {
      return underlying;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    if (envVars.VITE_NETWORK_TYPE === "devnet")
      return devnetExerciseCollateralMintDropdownList[0].value;
    return exerciseCollateralMintDropdownList[collateralMintDropdownIndex]
      .value;
  }, [
    settlementType,
    optionType,
    collateralMintDropdownIndex,
    cashSettled,
    isCall,
    underlying,
    isCombo,
  ]);

  const collateralAsset = useMemo(() => {
    if (isCall && !isCombo) {
      return underlyingAsset;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    if (envVars.VITE_NETWORK_TYPE === "devnet")
      return devnetExerciseCollateralMintDropdownList[0].preview;
    return exerciseCollateralMintDropdownList[collateralMintDropdownIndex]
      .preview;
  }, [
    settlementType,
    optionType,
    collateralMintDropdownIndex,
    isCall,
    underlyingAsset,
    isCombo,
  ]);

  const exercise = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    if (envVars.VITE_NETWORK_TYPE === "devnet")
      return devnetExerciseCollateralMintDropdownList[0].value;
    return !cashSettled && !isCombo
      ? physExerciseCollateralMintDropdownList[exerciseMintDropdownIndex].value
      : exerciseCollateralMintDropdownList[exerciseMintDropdownIndex].value;
  }, [settlementType, optionType, optionForm, exerciseMintDropdownIndex]);

  const sizePrecision = useMemo(() => {
    const fallback = 6;
    if (!collateral || collateral === undefined) return fallback;
    return collateral === ""
      ? fallback - flexConstants.OPTION_DECIMAL_ADJUSTMENT
      : (getOptionPrecision(new PublicKey(collateral)) ?? fallback) -
          flexConstants.OPTION_DECIMAL_ADJUSTMENT;
  }, [collateral, getOptionPrecision]);

  const strikePrecision = useMemo(() => {
    const fallback = 6;
    if (!flex.isInitialized || cashSettled) return fallback;
    return getOptionPrecision(new PublicKey(exercise)) ?? fallback;
  }, [getOptionPrecision, flex.isInitialized, cashSettled, exercise]);

  useEffect(() => {
    const getFormattedComboOptionList = async () => {
      const formattedList = await Promise.all(
        validComboOptionList.map(async (option) => {
          const formattedStrike =
            (await convertDecimalToNativeMintNumberAsync(
              option.strike,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              new PublicKey(collateral)
            )) || 0;

          const formattedSize =
            option.size * Math.pow(10, COMBO_SIZE_PRECISION);

          return {
            size: formattedSize,
            strike: formattedStrike,
            kind: option.kind,
          };
        })
      );
      setFormattedComboOptionList(formattedList);
    };

    if (!validComboOptionList.length) {
      setFormattedComboOptionList([]);
      return;
    }
    void getFormattedComboOptionList();
  }, [validComboOptionList, collateral]);

  const optionDescription = useMemo(() => {
    if (
      !optionAmount ||
      !assetStrike ||
      !expiry ||
      !underlying ||
      !underlyingAsset ||
      !optionType
    )
      return undefined;

    const expiryStr = getFormattedExpiry(
      expiry.getTime() / 1000,
      "option-generator"
    )
      .split(" ")
      .join("")
      .toUpperCase();
    const settlementStr = settlementType === "Cash" ? "CASH" : "PHYS";
    return (
      <SubtitleTextSpan>
        <Subtitle bold color="subtext">
          {underlyingAsset} USD {expiryStr}{" "}
          {parseFloat(assetStrike).toLocaleString(undefined, {
            maximumFractionDigits: strikePrecision,
          })}{" "}
          {settlementStr}{" "}
        </Subtitle>
        <Subtitle bold color={optionType === "Call" ? "product" : "negative"}>
          {optionType.toUpperCase()}
        </Subtitle>
      </SubtitleTextSpan>
    );
  }, [
    optionAmount,
    assetStrike,
    expiry,
    underlying,
    underlyingAsset,
    optionType,
    settlementType,
    strikePrecision,
  ]);

  /**
   * Calculates collateral required for normal options with underlying.
   */
  useEffect(() => {
    if (!assetStrike || !optionAmount) return undefined;
    const collatAmount: number = isCall
      ? parseFloat(optionAmount)
      : parseFloat(assetStrike) * parseFloat(optionAmount);
    try {
      setCollatRequired(
        collatAmount +
          (convertNativeMintNumberToDecimal(
            flexUtils.calculateMintingFees(
              convertDecimalToNativeMintNumber(
                collatAmount,
                new PublicKey(underlying)
              ) || 0
            ),
            new PublicKey(underlying)
          ) || 0)
      );
      setCollatError(undefined);
    } catch (e) {
      console.log("Failed to calculate collat amount", e);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setCollatError(e.message);
      setCollatRequired(undefined);
    }
  }, [
    assetStrike,
    optionAmount,
    underlying,
    isCall,
    convertNativeMintNumberToDecimal,
    convertDecimalToNativeMintNumber,
  ]);

  const collatRequiredStr = useMemo(() => {
    if (!typesSelected) return "-";
    if (!optionAmount || !assetStrike) return "Quantity and strike required.";
    if (collatError !== undefined || !collatRequired) return collatError;
    return `${
      collatRequired < 0.0001
        ? "<0.0001"
        : collatRequired?.toLocaleString(undefined, {
            maximumFractionDigits: 5,
          })
    } ${!cashSettled || isCall ? underlyingAsset : collateralAsset}`;
  }, [
    typesSelected,
    optionAmount,
    assetStrike,
    collatError,
    collatRequired,
    cashSettled,
    isCall,
    underlyingAsset,
    collateralAsset,
  ]);

  useEffect(() => {
    setUnderlyingMintDropdownIndex(0);
  }, [optionType, optionForm]);

  const comboCollatRequiredStr = useMemo(() => {
    if (!typesSelected) return "-";
    if (comboCollatError !== undefined || comboCollatRequired === undefined)
      return comboCollatError;
    return `${comboCollatRequired.toLocaleString(undefined, {
      maximumFractionDigits: 5,
    })} ${collateralAsset}`;
  }, [comboCollatRequired, comboCollatError, collateralAsset]);

  const startEarlierThanNowError = useMemo(() => {
    if (!settlementStart) return false;
    return (
      settlementType === "Physical" &&
      settlementStart.getTime() <= new Date().getTime() + timezoneOffsetMs
    );
  }, [settlementStart, settlementType]);

  const startLaterThanEndError = useMemo(() => {
    if (!expiry || !settlementStart) return false;
    return (
      settlementType === "Physical" &&
      settlementStart.getTime() >= expiry.getTime()
    );
  }, [expiry, settlementStart, settlementType]);

  const nowLaterThanExpiryError = useMemo(() => {
    if (!expiry) return false;
    return new Date().getTime() + timezoneOffsetMs >= expiry.getTime();
  }, [expiry]);

  const handleAddNewOption = () => {
    if (Object.keys(comboOptionList).length === 10) return;
    setComboOptionList({
      ...comboOptionList,
      [comboOptionListCount]: defaultOption,
    });
    setComboOptionListCount(comboOptionListCount + 1);
  };

  const handleComboOptionChange = (
    optionNumber: number,
    newOption: flexTypes.ComboOptionParams
  ) => {
    if (!(optionNumber in comboOptionList)) return;
    comboOptionList[optionNumber] = newOption;
    setComboOptionList({ ...comboOptionList });
  };

  const handleDeleteComboOption = (optionNumber: number) => {
    if (!(optionNumber in comboOptionList)) return;
    delete comboOptionList[optionNumber];
    setComboOptionList({ ...comboOptionList });
  };

  const handleMintClick = async () => {
    if (!wallet.publicKey || !client.isInitialized || !expiry) return;

    // For cash calls, underlying mint has to match collateral, exercise input is empty -> pubkey.default()
    // For cash puts, underlying mint can be anything, but collateral has to be USDC or USDT (we are going with USC), exercise input is empty
    // For phys calls, underlying mint match collateral (doesn't have to), exercise should be quote -> change to pubkey
    // For phys puts, underlying mint has to be the same as exercise, collateral mint should be adjustable -> TODO: don't show puts

    const underlyingInput = new PublicKey(underlying);
    let collateralInput = underlyingInput;
    let exerciseInput;

    if (cashSettled) {
      // Cash settled calls - min quantity is variable depending on the option mint
      exerciseInput = utils.getUnderlyingToKeyMapping(
        getSolanaNetwork(),
        envVars.VITE_NETWORK_TYPE === "devnet" ? "MOCK_USDC" : "USDC"
      );

      // Cash settled puts - min quantity is 0.1 -> min increment is 0.1
      if (!isCall) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        collateralInput = new PublicKey(collateral);
      }
    } else {
      // Physically settled calls
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      exerciseInput = new PublicKey(exercise);
    }

    const settlementStartBN = new anchor.BN(
      Math.floor((settlementStart.getTime() - timezoneOffsetMs) / 1000)
    );

    const expiryBN = new anchor.BN(
      Math.floor((expiry.getTime() - timezoneOffsetMs) / 1000)
    );
    const optionQty = isCall
      ? parseFloat(optionAmount)
      : parseFloat(optionAmount) * parseFloat(assetStrike);
    const formattedOptionQty = new anchor.BN(
      (await convertDecimalToNativeMintNumberAsync(
        optionQty,
        collateralInput
      )) || 0
    );
    const strike = parseFloat(assetStrike);
    const formattedStrike = new anchor.BN(
      (await convertDecimalToNativeMintNumberAsync(strike, exerciseInput)) || 0
    );

    const callback = notify(
      {
        title: "Minting normal option",
      },
      true
    );

    try {
      console.log(
        "inputs",
        underlyingInput.toString(),
        collateralInput.toString(),
        exerciseInput.toString(),
        "settlement start",
        settlementStartBN.toNumber(),
        "expiry",
        expiryBN.toNumber(),
        "problem",
        formattedOptionQty.toString(),
        formattedStrike.toString(),
        settlementType,
        optionType
      );

      const returnData = await client.data.client.initializeOption(
        underlyingInput,
        collateralInput,
        exerciseInput,
        cashSettled ? expiryBN : settlementStartBN,
        cashSettled ? new anchor.BN(0) : expiryBN,
        formattedOptionQty,
        formattedStrike,
        isCall ? flexTypes.Kind.CALL : flexTypes.Kind.PUT,
        cashSettled
          ? flexTypes.SettlementType.CASH
          : flexTypes.SettlementType.PHYSICAL,
        cashSettled
          ? flexTypes.OracleMethod.SPOT_PRINT
          : flexTypes.OracleMethod.MANUAL_PRINT,
        wallet.isSafeApp
      );
      client.data.updateClient();

      await createProposal("Initialize Option", returnData);

      callback({
        title: "Successfully minted normal option",
        type: NotificationType.SUCCESS,
      });
    } catch (err) {
      console.error("mint error", err);
      callback({
        title: "Failed to mint normal option",
        description: err.msg || err.toString(),
        code: err.code,
        type: NotificationType.ERROR,
      });
    }
  };
  const handleComboMintClick = async () => {
    if (!wallet.publicKey || !client.isInitialized || !expiry) return;

    const underlyingInput = new PublicKey(underlying);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const collateralInput = new PublicKey(collateral);

    const settlementStartBN = new anchor.BN(
      Math.floor((settlementStart.getTime() - timezoneOffsetMs) / 1000)
    );

    const expiryBN = new anchor.BN(
      Math.floor((expiry.getTime() - timezoneOffsetMs) / 1000)
    );

    const callback = notify(
      {
        title: "Minting combo option",
      },
      true
    );

    try {
      console.log(
        "underlying",
        underlyingInput.toString(),
        "collateral",
        collateralInput.toString(),
        "settlement start",
        settlementStartBN.toNumber(),
        "expiry",
        expiryBN.toNumber(),
        "settlement type",
        flexTypes.SettlementType.CASH,
        "oracle method",
        flexTypes.OracleMethod.SPOT_PRINT
      );

      const returnData = await client.data.client.initializeComboOption(
        underlyingInput,
        collateralInput,
        expiryBN,
        new anchor.BN(0),
        formattedComboOptionList,
        flexTypes.SettlementType.CASH,
        cashSettled
          ? flexTypes.OracleMethod.SPOT_PRINT
          : flexTypes.OracleMethod.MANUAL_PRINT,
        wallet.isSafeApp
      );
      client.data.updateClient();

      await createProposal("Initialize Combo Option", returnData);

      callback({
        title: "Successfully minted combo option",
        type: NotificationType.SUCCESS,
      });
    } catch (err) {
      console.error("mint error", err);
      callback({
        title: "Failed to mint combo option",
        description: err.msg || err.toString(),
        code: err.code,
        type: NotificationType.ERROR,
      });
    }
  };

  useEffect(() => {
    try {
      if (!formattedComboOptionList.length) {
        setComboCollatError("No valid combo option selected.");
        return;
      }

      const newComboCollat = convertNativeMintNumberToDecimal(
        getCollateralRequiredForComboOption(formattedComboOptionList),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        new PublicKey(collateral)
      );

      const fee = convertNativeMintNumberToDecimal(
        flexUtils.calculateMintingFees(
          getCollateralRequiredForComboOption(formattedComboOptionList)
        ),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        new PublicKey(collateral)
      ) as number;
      setComboCollatRequired((newComboCollat || 0) + fee);
      setComboCollatError(undefined);
    } catch (e) {
      setComboCollatRequired(undefined);
      setComboCollatError(e.message as string);
      console.log("Failed to calculate combo collateral reqiured:", e);
    }
  }, [convertNativeMintNumberToDecimal, formattedComboOptionList, collateral]);

  useEffect(() => {
    if (!cashSettled && settlementType !== undefined) {
      setExpiry(
        new Date(new Date().getTime() + timezoneOffsetMs + 60 * 60 * 1000)
      );
      setOptionType("Call");
    }
  }, [settlementType]);

  return (
    <AppContainer>
      <OptionGeneratorContainer>
        <Header bold color="subtext">
          Mint your Options
        </Header>

        {/* TYPE */}
        <InputGroupContainer>
          <InputGroupDescription>
            <InnerRow>
              <NumberIcon>1</NumberIcon>
              <Subtitle bold color="subtext">
                Type
              </Subtitle>
            </InnerRow>
            <Paragraph color="subtext">Customize your option type</Paragraph>
          </InputGroupDescription>
          <InputRow>
            <InputColumn>
              <Label color="subtext" bold>
                FORM OF OPTION
              </Label>
              <ButtonToggle
                colorLeft={"highlight"}
                colorRight={"highlight"}
                inverse
                labelLeft={"Normal"}
                labelRight={"Combo"}
                onClickLeft={() => {
                  setOptionForm("Normal");
                }}
                onClickRight={() => {
                  setOptionForm("Combo");
                }}
                selected={optionForm}
              />
            </InputColumn>
            {!isCombo ? (
              <>
                <InputColumn>
                  <Label color="subtext" bold>
                    FORM OF SETTLEMENT
                  </Label>
                  <ButtonToggle
                    colorLeft={"highlight"}
                    colorRight={"highlight"}
                    inverse
                    labelLeft={"Cash"}
                    labelRight={"Physical"}
                    onClickLeft={() => {
                      setSettlementType("Cash");
                    }}
                    onClickRight={() => {
                      setSettlementType("Physical");
                    }}
                    selected={settlementType}
                  />
                </InputColumn>
                <InputColumn>
                  <Label color="subtext" bold>
                    OPTION TYPE
                  </Label>
                  <ButtonToggle
                    colorLeft={"product"}
                    colorRight={"negative"}
                    inverse
                    labelLeft={"Call"}
                    labelRight={"Put"}
                    onClickLeft={() => {
                      setOptionType("Call");
                    }}
                    onClickRight={() => {
                      setOptionType("Put");
                    }}
                    selected={optionType}
                    disableRight={!cashSettled && settlementType !== undefined}
                  />
                </InputColumn>
              </>
            ) : (
              <>
                <InputColumn /> <InputColumn />
              </>
            )}
          </InputRow>
        </InputGroupContainer>

        {/* CURRENCIES */}
        <InputGroupContainer>
          <InputGroupDescription>
            <InnerRow>
              <NumberIcon>2</NumberIcon>
              <Subtitle bold color="subtext">
                Currencies
              </Subtitle>
            </InnerRow>
            <Paragraph color="subtext">
              Select the currencies you would like to use
            </Paragraph>
          </InputGroupDescription>
          <InputRow>
            <InputColumn>
              <Label color="subtext" bold>
                UNDERLYING MINT
              </Label>
              <Dropdown
                selectedIndex={underlyingMintDropdownIndex}
                list={
                  (cashSettled && !isCall) || isCombo
                    ? whitelistedMintDropdownList
                    : whitelistedWalletTokensDropdownList
                }
                onClick={setUnderlyingMintDropdownIndex}
              />
            </InputColumn>
            {(cashSettled || settlementType === undefined) && (
              <InputColumn>
                <StaticTitle color="subtext" bold>
                  QUOTE
                </StaticTitle>
                <StaticContentText bold>USD</StaticContentText>
              </InputColumn>
            )}
            <InputColumn>
              {(cashSettled || settlementType === undefined) &&
              (isCall || optionType === undefined) &&
              !isCombo ? (
                <StaticTitle color="subtext" bold>
                  COLLATERAL MINT
                </StaticTitle>
              ) : (cashSettled || settlementType === undefined) &&
                (!isCall || isCombo || optionType === undefined) ? (
                <Label color="subtext" bold>
                  COLLATERAL MINT
                </Label>
              ) : (
                <Label color="subtext" bold>
                  EXERCISE MINT
                </Label>
              )}
              {!cashSettled && settlementType !== undefined && !isCombo ? (
                <Dropdown
                  selectedIndex={exerciseMintDropdownIndex}
                  list={
                    envVars.VITE_NETWORK_TYPE === "devnet"
                      ? devnetExerciseCollateralMintDropdownList
                      : physExerciseCollateralMintDropdownList
                  }
                  onClick={setExerciseMintDropdownIndex}
                />
              ) : (isCall || optionType === undefined) &&
                (!isCombo || optionForm === undefined) ? (
                <StaticContentText bold gradient>
                  {underlyingAsset || "-"}
                </StaticContentText>
              ) : (
                <Dropdown
                  selectedIndex={collateralMintDropdownIndex}
                  list={
                    envVars.VITE_NETWORK_TYPE === "devnet"
                      ? devnetExerciseCollateralMintDropdownList
                      : exerciseCollateralMintDropdownList
                  }
                  onClick={setCollateralMintDropdownIndex}
                />
              )}
            </InputColumn>
            {!cashSettled && settlementType !== undefined && (
              <InputColumn>
                <StaticTitle color="subtext" bold>
                  COLLATERAL MINT
                </StaticTitle>
                <StaticContentText bold gradient>
                  {underlyingAsset || "-"}
                </StaticContentText>
              </InputColumn>
            )}
          </InputRow>
        </InputGroupContainer>

        {/* OPTION SPECS */}
        <InputGroupContainer disabled={!typesSelected}>
          <InputGroupDescription>
            <InnerRow>
              <NumberIcon>3</NumberIcon>
              <Subtitle bold color="subtext">
                Option Specs
              </Subtitle>
            </InnerRow>
            <Paragraph color="subtext">
              Specify the parameters of your options
            </Paragraph>
          </InputGroupDescription>
          {!isCombo ? (
            <>
              {/* QUANTITY */}
              <InputRow stack={!cashSettled && settlementType !== undefined}>
                <InputColumn>
                  <Label color="subtext" bold>
                    QUANTITY OF OPTIONS
                  </Label>
                  <OptionsGeneratorInput
                    min="0"
                    placeholder="0.00"
                    fixedDecimalScale={true}
                    decimalScale={sizePrecision}
                    allowNegative={false}
                    onChange={(event) => {
                      setOptionAmount(event.target.value);
                    }}
                    value={optionAmount}
                  />
                </InputColumn>
                <InputColumn>
                  <Label color="subtext" bold>
                    STRIKE PRICE
                  </Label>
                  <OptionsGeneratorInput
                    min="0"
                    placeholder="0.00"
                    fixedDecimalScale={true}
                    decimalScale={strikePrecision}
                    allowNegative={false}
                    onChange={(event) => {
                      setAssetStrike(event.target.value);
                    }}
                    value={assetStrike}
                  />
                </InputColumn>
                {(cashSettled || settlementType === undefined) && (
                  <InputColumn style={{ width: "auto" }}>
                    <Label color="subtext" bold>
                      EXPIRY (UTC)
                    </Label>
                    <StyledDateTimePicker
                      onChange={setExpiry}
                      value={expiry}
                      maxDetail="second"
                      disableClock
                      clearIcon={null}
                      minDate={settlementStart}
                    />
                    <Label color="subtext" style={{ fontSize: "10px" }}>
                      Local:{" "}
                      {!expiry
                        ? "-"
                        : getFormattedExpiry(
                            (expiry.getTime() - 2 * timezoneOffsetMs) / 1000,
                            "exercisable-options-expiry"
                          ).split("UTC")}
                    </Label>
                  </InputColumn>
                )}
              </InputRow>
            </>
          ) : (
            <>
              {Object.keys(comboOptionList).map((key, index) => {
                return (
                  <ComboOptionRow
                    key={key}
                    optionNumber={index + 1}
                    listKey={parseInt(key)}
                    handleComboOptionChange={handleComboOptionChange}
                    handleDeleteComboOption={handleDeleteComboOption}
                    showCross={Object.keys(comboOptionList).length > 1}
                    collateralMint={new PublicKey(collateral)}
                  />
                );
              })}
              {Object.keys(comboOptionList).length < 10 && (
                <AddButtonRow style={{ minHeight: "40px" }}>
                  <Subtitle bold></Subtitle>
                  <AddButton onClick={handleAddNewOption}>
                    <img src={PlusSVG} />
                    <Paragraph color="highlight" bold>
                      Add another option
                    </Paragraph>
                  </AddButton>
                </AddButtonRow>
              )}
              <ExpiryInputRow>
                <ExpiryInputColumn>
                  <Paragraph bold color="subtext">
                    Expiry
                  </Paragraph>
                </ExpiryInputColumn>
                <ExpiryInputColumn style={{ width: "auto" }}>
                  <Label color="subtext" bold>
                    EXPIRY (UTC)
                  </Label>
                  <StyledDateTimePicker
                    onChange={setExpiry}
                    value={expiry}
                    maxDetail="second"
                    disableClock
                    clearIcon={null}
                    minDate={settlementStart}
                  />
                  <Label color="subtext" style={{ fontSize: "10px" }}>
                    Local:{" "}
                    {!expiry
                      ? "-"
                      : getFormattedExpiry(
                          (expiry.getTime() - 2 * timezoneOffsetMs) / 1000,
                          "exercisable-options-expiry"
                        ).split("UTC")}
                  </Label>
                </ExpiryInputColumn>
              </ExpiryInputRow>
            </>
          )}
          {/* SETTLEMENT START */}
          {!cashSettled && settlementType !== undefined && (
            <InputRow>
              <InputColumn style={{ width: "auto" }}>
                <Label color="subtext" bold>
                  SETTLEMENT START IN UTC TIME
                </Label>
                <StyledDateTimePicker
                  onChange={setSettlementStart}
                  value={settlementStart}
                  maxDetail="second"
                  disableClock
                  clearIcon={null}
                  minDate={new Date(new Date().getTime() + timezoneOffsetMs)}
                />
                <Label color="subtext" style={{ fontSize: "10px" }}>
                  Local:{" "}
                  {!expiry
                    ? "-"
                    : getFormattedExpiry(
                        (expiry.getTime() - 2 * timezoneOffsetMs) / 1000,
                        "exercisable-options-expiry"
                      ).split("UTC")}
                </Label>
              </InputColumn>
              <InputColumn style={{ width: "auto" }}>
                <Label color="subtext" bold>
                  {cashSettled ? "EXPIRY" : "SETTLEMENT END"} IN UTC TIME
                </Label>
                <StyledDateTimePicker
                  onChange={setExpiry}
                  value={expiry}
                  maxDetail="second"
                  disableClock
                  clearIcon={null}
                  minDate={settlementStart}
                />
                <Label color="subtext" style={{ fontSize: "10px" }}>
                  Local:{" "}
                  {!expiry
                    ? "-"
                    : getFormattedExpiry(
                        (expiry.getTime() - 2 * timezoneOffsetMs) / 1000,
                        "exercisable-options-expiry"
                      ).split("UTC")}
                </Label>
              </InputColumn>
            </InputRow>
          )}
        </InputGroupContainer>

        {/* SUMMARY */}
        <SummaryContainer>
          {validComboOptionList.length && isCombo && (
            <SummaryRow>
              <Subtitle bold>Composition</Subtitle>
              <SummaryInputColumn>
                {validComboOptionList.map((option, index) => {
                  const kindStr = option.kind === Kind.CALL ? "Call" : "Put";
                  if (!option.size) return null;

                  const expiryStr = getFormattedExpiry(
                    expiry.getTime() / 1000,
                    "option-generator"
                  )
                    .split(" ")
                    .join("")
                    .toUpperCase();
                  return (
                    <SubtitleTextSpan key={index} smaller>
                      <Paragraph bold color="subtext">
                        {option.size}x {underlyingAsset} USD {expiryStr}{" "}
                        {option.strike} CASH{" "}
                      </Paragraph>
                      <Paragraph
                        bold
                        color={
                          option.kind === Kind.CALL ? "product" : "negative"
                        }
                      >
                        {kindStr.toUpperCase()}
                      </Paragraph>
                    </SubtitleTextSpan>
                  );
                })}
              </SummaryInputColumn>
            </SummaryRow>
          )}
          <SummaryRow>
            <Subtitle bold>Your Option</Subtitle>
            {!wallet.connected || !typesSelected ? (
              <Subtitle bold color="subtext">
                -
              </Subtitle>
            ) : !isCombo ? (
              <Subtitle color="subtext" bold>
                {startLaterThanEndError
                  ? "End date must be later than start date."
                  : nowLaterThanExpiryError
                  ? "Expiry must be later than current time."
                  : startEarlierThanNowError
                  ? "Start date must be later than current time."
                  : optionDescription || "Please fill in above fields."}
              </Subtitle>
            ) : !underlying ? (
              <Subtitle bold color="subtext">
                Underlying field required.
              </Subtitle>
            ) : !collateral ? (
              <Subtitle bold color="subtext">
                Collateral field required.
              </Subtitle>
            ) : validComboOptionList.length === 0 ? (
              <Subtitle bold color="subtext">
                Please fill in at least one option.
              </Subtitle>
            ) : (
              <SummaryInputColumn>
                <StaticContentText bold gradient>
                  {underlyingAsset} USD{" "}
                  {getFormattedExpiry(
                    expiry.getTime() / 1000,
                    "option-generator"
                  )
                    .split(" ")
                    .join("")
                    .toUpperCase()}{" "}
                  CASH COMBO
                </StaticContentText>
              </SummaryInputColumn>
            )}
          </SummaryRow>
          <SummaryRow>
            <Subtitle bold style={{ minWidth: "fit-content" }}>
              Required Collateral
            </Subtitle>
            <Subtitle color="subtext" bold style={{ textAlign: "end" }}>
              {!wallet.connected
                ? "Please connect your wallet."
                : isCombo
                ? comboCollatRequiredStr
                : collatRequiredStr}
            </Subtitle>
          </SummaryRow>
          <SummaryRow>
            <Subtitle />
            <MintContainer>
              {!wallet.connected ? (
                <ConnectWalletContainer>
                  <Wallet />
                </ConnectWalletContainer>
              ) : (
                <MintButton
                  label={"Mint Option"}
                  color="callToAction"
                  onClick={() => {
                    if (isCombo) {
                      void handleComboMintClick();
                    } else {
                      void handleMintClick();
                    }
                  }}
                  isBold
                  disabled={
                    !isCombo
                      ? optionDescription === undefined ||
                        startLaterThanEndError ||
                        nowLaterThanExpiryError ||
                        !wallet.connected ||
                        collatError !== undefined
                      : !wallet.connected ||
                        !validComboOptionList.length ||
                        typeof comboCollatRequired !== "number"
                  }
                />
              )}
            </MintContainer>
          </SummaryRow>
        </SummaryContainer>
      </OptionGeneratorContainer>
    </AppContainer>
  );
};
