import styled from "styled-components";
import { ButtonToggleContainer } from "../../../components/ButtonToggle/styles";

export const AuctionHeaderContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-left: 1rem;

  ${ButtonToggleContainer} {
    * {
      width: 135px;
      flex-basis: 1;
    }
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 1rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > * + * {
    margin-left: 1rem;
  }
`;
