import styled from "styled-components";

export const Loader = styled.div`
  position: relative;
  background: conic-gradient(
    from 0deg at 50% 50%,
    #001e9d 0deg,
    #15d3ff 360deg
  );
  border-radius: 50%;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  animation: spin 1.75s linear infinite;
  margin-right: 0.5rem;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const InnerLoader = styled.div`
  background: ${(props) => props.theme.background.primary};
  border-radius: 50%;
  height: 18px;
  width: 18px;
  position: absolute;
  top: 6px;
  left: 6px;
`;
