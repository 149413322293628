import styled, { css } from "styled-components";
import { Subtitle } from "../Text";

interface TabProps {
  /**
   * The selected tab
   */
  selected: TabType;
  /**
   * Callback that will be fired on tab change
   */
  onTabChange: (tab: TabType) => void;
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

export enum TabType {
  ExercisableOptions,
  Options,
  UserCreatedOptions,
}

export const Tabs = ({ selected, onTabChange, className }: TabProps) => {
  return (
    <TabsContainer className={className}>
      <Tab
        onClick={() => onTabChange(TabType.ExercisableOptions)}
        selected={selected === TabType.ExercisableOptions}
      >
        <TabText>Exercisable Positions</TabText>
      </Tab>
      <Tab
        onClick={() => onTabChange(TabType.Options)}
        selected={selected === TabType.Options}
      >
        <TabText>All Positions</TabText>
      </Tab>
      <Tab
        onClick={() => onTabChange(TabType.UserCreatedOptions)}
        selected={selected === TabType.UserCreatedOptions}
      >
        <TabText>User Created Options</TabText>
      </Tab>
    </TabsContainer>
  );
};

const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  background: ${(props) => props.theme.background.secondary};
  border-radius: 4px;
  width: fit-content;
  margin: 1.45rem 1rem;
`;

const Tab = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  height: 24px;
  border-radius: 4px;

  ${(props) =>
    props.selected &&
    css`
      background: ${props.theme.background.primary};
      * {
        color: ${props.theme.font.highlight};
      }
      border: ${(props) => `1px solid ${props.theme.buttons.highlight}`};
    `};
  ${(props) =>
    !props.selected &&
    css`
      * {
        cursor: pointer;
      }
      color: ${props.theme.font.subtext};
      border: 1px solid transparent;
    `};
`;

const TabText = styled(Subtitle).attrs(() => ({
  color: "subtext",
}))`
  font-weight: 600;
`;
