import styled from "styled-components";
import { Paragraph, Subtitle } from "../Text";

export const TermsAndConditionsText = () => {
  return (
    <TCsContainer>
      <OrderedList>
        {/* 1 */}
        <ListItem>
          <Header bold>GENERAL</Header>
          <Text>
            These terms and conditions of service (“Terms”) govern your use of
            the Zeta Flex Program (as defined in Clause 2 below), and all
            programs, software, user interfaces, infrastructure, material,
            protocols, information and data offered by Zeta Protocol Platform
            Corp (“Zeta”) made available in relation to the Zeta Flex Program on
            mobile, web-based or other applications at http://flex.zeta.markets
            (collectively, “Zeta Flex”). Access and use of the Zeta Flex
            Software Development Kit will be governed by its own Terms of Use,
            which is separate and distinct from these Terms. If you do not
            accept any of these Terms, please do not use Zeta Flex. Your access
            and/or use of Zeta Flex shall be deemed to be an acceptance of these
            Terms.
          </Text>
          <OrderedList>
            {/* 1.1 */}
            <ListItem>
              <Text>
                In these Terms, “we”, “us” and “our” refers to <b>Zeta</b>.
                “You” and “your” refers to any person who (a) has used Zeta Flex
                using a Wallet (as defined below at Clause 2); and/or (b)
                accesses or uses Zeta Flex without using a Wallet.
              </Text>
            </ListItem>
            {/* 1.2 */}
            <ListItem>
              <Text>
                We may revise these Terms at any time without notice to you. You
                should visit this page from time to time and review the Terms.
                You are deemed to agree to such changes to these Terms, if you
                continue the use of Zeta Flex after such change has been
                published on this page.
              </Text>
            </ListItem>
            {/* 1.3 */}
            <ListItem>
              <Text>
                We may from time to time publish additional guidelines, rules,
                and conditions applicable to your use of Zeta Flex. You agree to
                comply with these additional guidelines, rules and conditions,
                which are incorporated by reference into these Terms.
              </Text>
            </ListItem>
            {/* 1.4 */}
            <ListItem>
              <Text>
                We have no obligation to provide, or continue to provide Zeta
                Flex, or any part thereof, now or in the future. Subject to
                Clause 24, we reserve the right, at any time, temporarily or
                permanently, in whole or in part, without incurring any
                liability to you, to: modify, suspend or discontinue Zeta Flex;
                restrict or modify access to Zeta Flex; and modify and/or waive
                any charges in connection with Zeta Flex.
              </Text>
            </ListItem>
            {/* 1.5 */}
            <ListItem>
              <Text>
                These Terms will also apply to any updates or upgrades provided
                by us that replace and/or supplement any part of Zeta Flex,
                unless such updates or upgrades are accompanied by separate
                terms in which case those separate terms shall apply.
              </Text>
            </ListItem>
            {/* 1.6 */}
            <ListItem>
              <Text>
                We may offer additional specific products and services to
                complement or supplement the product offerings comprised within
                Zeta Flex. Additional or separate terms and conditions may apply
                these products and services, and you will need to accept those
                terms and conditions to enjoy them. You agree to comply with
                these additional guidelines, rules and conditions, which are
                also incorporated by reference into these Terms.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 2 */}
        <ListItem>
          <Header bold>DEFINITIONS</Header>
          {/* 2.1 */}
          <FeeTable>
            <tr>
              <td>
                <Text style={{ wordBreak: "normal" }}>Auction</Text>
              </td>
              <td>
                <Text>
                  Zeta Flex’s functionality that enables Projects to create an
                  auction for a particular Option, for the purpose of enabling
                  market participants, such as market makers, to place all or
                  nothing bids on Options created using the Option Factory based
                  on specified parameters, including:
                </Text>
                <ol type="a">
                  <li>
                    <Text>bid currency; and</Text>
                  </li>
                  <li>
                    <Text>escrow option token.</Text>
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <Text style={{ wordBreak: "normal" }}>
                  Excluded Jurisdiction
                </Text>
              </td>
              <td>
                <Text>means any of the following jurisdictions:</Text>
                <ol type="i">
                  <li>
                    <Text>
                      the United States of America and its territories and
                      possessions (collectively “United States”);
                    </Text>
                  </li>
                  <li>
                    <Text>the People’s Republic of China;</Text>
                  </li>
                  <li>
                    <Text>Republic of Singapore;</Text>
                  </li>
                  <li>
                    <Text>
                      a jurisdiction identified by the Financial Action Task
                      Force (FATF) for strategic AML/CFT deficiencies and
                      included in FATF’s listing of “High-risk and Other
                      Monitored Jurisdictions” accessible at
                      http://www.fatf-gafi.org/publications/high-risk-and-other-monitored-jurisdictions;
                      and/or
                    </Text>
                  </li>
                  <li>
                    <Text>
                      a jurisdiction in which the SDK and/or the Protocol, and
                      the use of which are prohibited or would be subject of
                      licensing, permissions or approvals;
                    </Text>
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <Text style={{ wordBreak: "normal" }}>Excluded Persons</Text>
              </td>
              <td>
                <Text>refers to the following:</Text>
                <ol type="i">
                  <li>
                    <Text>a person who is:</Text>
                    <ol type="a">
                      <li>
                        <Text>
                          a citizen of an Excluded Jurisdiction (which for
                          purposes of this sub-clause (i) shall not include
                          United States);
                        </Text>
                      </li>
                      <li>
                        <Text>
                          domiciled in, resident of, or physically present /
                          located in an Excluded Jurisdiction;
                        </Text>
                      </li>
                      <li>
                        <Text>Republic of Singapore;</Text>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <Text>a body corporate:</Text>
                    <ol type="a">
                      <li>
                        <Text>
                          which is incorporated in, or operates out of, an
                          Excluded Jurisdiction; or
                        </Text>
                      </li>
                      <li>
                        <Text>
                          which is under the control of one or more individuals
                          who is/are citizen(s) of, domiciled in, residents of,
                          or physically present / located in, an Excluded
                          Jurisdiction;
                        </Text>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <Text>
                      an individual or body corporate accessing and/or using SDK
                      and/or Protocol(s) from within an Excluded Jurisdiction;
                    </Text>
                  </li>
                  <li>
                    <Text>
                      an individual or body corporate included in the United
                      Nations Consolidated List (accessible at
                      https://www.un.org/securitycouncil/content/un-sc-consolidated-list);
                    </Text>
                  </li>
                  <li>
                    <Text>
                      an individual or body corporate which is otherwise
                      prohibited or ineligible in any way, whether in full or in
                      part, under any laws applicable to such individual or body
                      corporate from accessing and/or using the SDK and/or
                      Protocol(s); and/or
                    </Text>
                  </li>
                  <li>
                    <Text>a U.S. person;</Text>
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <Text style={{ wordBreak: "normal" }}>Option</Text>
              </td>
              <td>
                <Text>
                  Fully collateralised cryptocurrency- or token-denominated,
                  Smart Contract that gives the holder the right to buy or sell
                  a particular cryptocurrency or token at a particular price,
                  and that is settled in stablecoins, tokens, or cryptocurrency;
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text style={{ wordBreak: "normal" }}>Option Factory</Text>
              </td>
              <td>
                <Text>
                  Zeta Flex’s functionality that enables Projects to create
                  Options, upon the locking of collateral with the Smart
                  Contract, with the ability to specify the following
                  parameters:
                </Text>
                <ol type="a">
                  <li>
                    <Text>asset;</Text>
                  </li>
                  <li>
                    <Text>expiry;</Text>
                  </li>
                  <li>
                    <Text>strike price;</Text>
                  </li>
                  <li>
                    <Text>type (American / European); and</Text>
                  </li>
                  <li>
                    <Text>oracle.</Text>
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <Text style={{ wordBreak: "normal" }}>Project</Text>
              </td>
              <td>
                <Text>
                  A group, organisation, or corporate body seeking to develop a
                  cryptocurrency-related product or service;
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text style={{ wordBreak: "normal" }}>Smart Contract</Text>
              </td>
              <td>
                <Text>
                  A self-executing contract that contains executable code and
                  that requires a blockchain to facilitate transactions;
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text style={{ wordBreak: "normal" }}>U.S. person</Text>
              </td>
              <td>
                <Text>means:</Text>
                <ol type="i">
                  <li>
                    <Text>
                      any natural person resident in the United States;
                    </Text>
                  </li>
                  <li>
                    <Text>
                      any partnership or corporation organized or incorporated
                      under the laws of the United States;
                    </Text>
                  </li>
                  <li>
                    <Text>
                      any estate of which any executor or administrator is a
                      U.S. person;
                    </Text>
                  </li>
                  <li>
                    <Text>
                      any trust of which any trustee is a U.S. person;
                    </Text>
                  </li>
                  <li>
                    <Text>
                      any agency or branch of a foreign entity located in the
                      United States;
                    </Text>
                  </li>
                  <li>
                    <Text>
                      any non-discretionary account or similar account (other
                      than an estate or trust) held by a dealer or other
                      fiduciary for the benefit or account of a U.S. person;
                    </Text>
                  </li>
                  <li>
                    <Text>
                      any discretionary account or similar account (other than
                      an estate or trust) held by a dealer or other fiduciary
                      organized, incorporated, or (if an individual) resident in
                      the United States;
                    </Text>
                  </li>
                  <li>
                    <Text>any partnership or corporation if:</Text>
                    <ol type="a">
                      <li>
                        <Text>
                          organized or incorporated under the laws of any
                          foreign jurisdiction; and
                        </Text>
                      </li>
                      <li>
                        <Text>
                          formed by a U.S. person principally for the purpose of
                          investing in securities not registered under the Act,
                          unless it is organized or incorporated, and owned, by
                          accredited investors (as defined in Regulation D of
                          the Securities Act of 1933 of the United States of
                          America) who are not natural persons, estates or
                          trusts; and
                        </Text>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <Text>
                      any citizen of United States who is a military personnel
                      of United States who is not resident in or outside of the
                      United States,
                    </Text>
                    <Text>but does not include:</Text>
                    <ol type="a">
                      <li>
                        <Text>
                          any discretionary account or similar account (other
                          than an estate or trust) held for the benefit or
                          account of a non-U.S. person by a dealer or other
                          professional fiduciary organized, incorporated, or (if
                          an individual) resident in the United States;
                        </Text>
                      </li>
                      <li>
                        <Text>
                          any estate of which any professional fiduciary acting
                          as executor or administrator is a U.S. person if:
                        </Text>
                        <ol>
                          <li>
                            <Text>
                              an executor or administrator of the estate who is
                              not a U.S. person has sole or shared investment
                              discretion with respect to the assets of the
                              estate; and
                            </Text>
                          </li>
                          <li>
                            <Text>the estate is governed by foreign law;</Text>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <Text>
                          any trust of which any professional fiduciary acting
                          as trustee is a U.S. person, if a trustee who is not a
                          U.S. person has sole or shared investment discretion
                          with respect to the trust assets, and no beneficiary
                          of the trust (and no settlor if the trust is
                          revocable) is a U.S. person;
                        </Text>
                      </li>
                      <li>
                        <Text>
                          an employee benefit plan established and administered
                          in accordance with the law of a country other than the
                          United States and customary practices and
                          documentation of such country;
                        </Text>
                      </li>
                      <li>
                        <Text>
                          any agency or branch of a U.S. person located outside
                          the United States if:
                        </Text>
                        <ol>
                          <li>
                            <Text>
                              agency or branch operates for valid business
                              reasons; and
                            </Text>
                          </li>
                          <li>
                            <Text>
                              the agency or branch is engaged in the business of
                              insurance or banking and is subject to substantive
                              insurance or banking regulation, respectively, in
                              the jurisdiction where located; and
                            </Text>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <Text>
                          The International Monetary Fund, the International
                          Bank for Reconstruction and Development, the
                          Inter-American Development Bank, the Asian Development
                          Bank, the African Development Bank, the United
                          Nations, and their agencies, affiliates and pension
                          plans, and any other similar international
                          organizations, their agencies, affiliates and pension
                          plans;
                        </Text>
                      </li>
                    </ol>
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <Text style={{ wordBreak: "normal" }}>Wallet</Text>
              </td>
              <td>
                <Text>
                  A digital vault for the storage of cryptocurrencies and
                  chain-based tokens, which can be used to trade or as
                  collateral for trades, that is owned by one or more persons;
                  and
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text style={{ wordBreak: "normal" }}>Zeta Flex Program</Text>
              </td>
              <td>
                <Text>
                  The software package constituted by the Auction and Option
                  Factory functionalities, and other features and/or
                  functionalities for the purposes of enabling users to create
                  and manage Options, and Auctions.
                </Text>
              </td>
            </tr>
          </FeeTable>
        </ListItem>
        <br />
        {/* 3 */}
        <ListItem>
          <Header bold>WALLET</Header>
          <OrderedList>
            {/* 3.1 */}
            <ListItem>
              <Text>
                You will be required to set-up a Wallet at with a third-party
                provider before you are entitled to access and/or use the
                features and/or services as set out in Clause 3.4.
              </Text>
            </ListItem>
            {/* 3.2 */}
            <ListItem>
              <Text>
                Certain features and/or services will require you to connect
                your Wallet to the feature and/or service. While we will make
                commercially reasonable efforts to protect the linkage to your
                Wallet, you acknowledge and agree to ensure the security of, and
                your continuous control over, your Wallet.
              </Text>
            </ListItem>
            {/* 3.3 */}
            <ListItem>
              <Text>
                You agree that it is your responsibility to provide the correct
                Wallet address(es) for all your interactions with Zeta Flex. We
                shall not be responsible for any loss arising from or in
                connection with the wrong or inaccurate provision of a Wallet
                address by you.
              </Text>
            </ListItem>
            {/* 3.4 */}
            <ListItem>
              <Text>
                To set-up a Wallet and to enable certain features and functions,
                you will be required to complete the set-up procedures specified
                by the third-party Wallet provider. You agree that it is your
                sole responsibility to do so, to comply with whatever terms and
                conditions may govern your access and use of the provision of
                the Wallet by the third-party provider, and to ensure the
                security of, and your continuous control over your Wallet.
              </Text>
            </ListItem>
            {/* 3.5 */}
            <ListItem>
              <Text>
                We do not have access to, receive or store your private keys or
                mnemonic phrase associated with your Wallet (“
                <b>Private Keys</b>”). It is not possible for us to generate new
                Private Keys for your Wallets in any circumstances. If you have
                misplaced your Private Keys, and/or the device containing the
                Private Keys is lost, damaged and/or stolen, you will not be
                able to gain access to the Options associated with your Wallet
                unless you have made alternative arrangements with a third-party
                provider to store your Private Keys.
              </Text>
            </ListItem>
            {/* 3.6 */}
            <ListItem>
              <Text>
                While we will make commercially reasonable efforts to protect
                your Wallet’s information on our Protocol, it is solely your
                responsibility to ensure that your Wallet and Private Keys are
                securely protected both online and offline. You acknowledge and
                agree that we are not responsible for any loss arising out of or
                in connection with any lost, mishandled and/or stolen Private
                Keys.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 4 */}
        <ListItem>
          <Header bold>ZETA FLEX</Header>
          <OrderedList>
            {/* 4.1 */}
            <ListItem>
              <Text>
                In order for us to provide Zeta Flex to you, your Smart
                Contract, logic, or code (“<b>Your Codes</b>”), which
                facilitates your intended transactions with Zeta Flex, must be
                interfaced with our systems and applications through the Zeta
                Flex Program and/or SDK. Use of any user interface provided by
                us and the Zeta Flex Program are licensed to you under these
                Terms, while access and use of the SDK is governed by said SDK’s
                separate Terms of Use.
              </Text>
            </ListItem>
            {/* 4.2 */}
            <ListItem>
              <Text>
                In order to access and use Zeta Flex, you are required to have a
                compatible computing device with connectivity to the internet,
                and such other equipment meeting the technical specifications we
                may from time to time specify. You are solely responsible for
                obtaining suitable devices, the necessary connectivity services
                and/or the equipment to meet such specifications.
              </Text>
            </ListItem>
            {/* 4.3 */}
            <ListItem>
              <Text>
                You agree and acknowledge that Zeta Flex is provided to you as a
                service, subject to these Terms, and that any transaction
                between you and any counterparty concluded on or using Zeta
                Flex, which includes but is not limited to Auction transactions,
                does not constitute a contractual arrangement between you and
                Zeta. Such transactions are direct contractual arrangements
                between you and your counterparty.
              </Text>
            </ListItem>
            {/* 4.4 */}
            <ListItem>
              <Text>
                We are not liable and have no obligation to provide you with
                Zeta Flex if:
              </Text>
              <ol>
                {/* 4.4.1 */}
                <li>you breach these Terms;</li>
                {/* 4.4.1 */}
                <li>
                  a Force Majeure Event occurs affecting our ability to provide
                  Zeta Flex;
                </li>
                {/* 4.4.1 */}
                <li>
                  you fail to implement recommendations in respect of, or
                  solutions to, issues previously advised by us;
                </li>
                {/* 4.4.1 */}
                <li>
                  you use Zeta Flex for purposes or in a manner for which it is
                  not designed or intended or is otherwise disallowed under any
                  law or regulation; or
                </li>
                {/* 4.4.1 */}
                <li>
                  our ability to provide Zeta Flex is affected by the factors
                  beyond our control, including the performance, or
                  non-performance, acts or omissions of persons other than us,
                  or the accessibility to and operations of technologies outside
                  our control.
                </li>
              </ol>
            </ListItem>
            {/* 4.5 */}
            <ListItem>
              <Text>
                We are entitled to and may have any of our obligations under
                these Terms performed by or through our sub-contractors. You
                agree to provide to our sub-contractors the same information,
                assistance, co-operation and access as you are required to
                provide to us under these Terms.
              </Text>
            </ListItem>
            {/* 4.6 */}
            <ListItem>
              <Text>
                Zeta Flex may include references to or provide information about
                third parties (and/or products and services offered by such
                third parties). We do not endorse, and are not associated with
                such third parties, and are not responsible for their products,
                services and/or content.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 5 */}
        <ListItem>
          <Header bold>THIRD-PARTY SERVICES AND MARKETING</Header>
          <OrderedList>
            {/* 5.1 */}
            <ListItem>
              <Text>
                Zeta Flex may provide functionalities and services to allow you
                to access, use, interface and/or interact with third party
                products and services (e.g. community and incentive programs
                with other blockchain projects and communities). The use of such
                third party products and services will be subject to separate
                terms as agreed between you and such third parties. We are not
                responsible and disclaim all liability for any act or omission
                of these third parties, or any dealings between you and these
                third parties, whether or not such dealings have been
                facilitated or conducted on or through Zeta Flex.
              </Text>
            </ListItem>
            {/* 5.2 */}
            <ListItem>
              <Text>
                Zeta Flex allows third parties to market their events and
                promotional activities to you. Should you wish to participate in
                such events and promotional activities, such engagement will be
                subject to separate terms as agreed between you and the relevant
                third parties. We are not responsible and disclaim all liability
                for any act or omission of these third parties, or any dealings
                between you and these third parties, whether or not such
                dealings have been facilitated or conducted on or through Zeta
                Flex.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 6 */}
        <ListItem>
          <Header bold>
            INFORMATION, CREATION OF OPTION CONTRACTS AND AUCTIONS THROUGH ZETA
            FLEX
          </Header>
          <OrderedList>
            {/* 6.1 */}
            <ListItem>
              <Text>
                As part of Zeta Flex, you may request from us, information, and
                data relating to tokens and transactions on the Solana
                blockchain (“<b>Chain Information</b>”) when interacting with
                our user interfaces or otherwise. Our Chain Information is based
                on selected publicly available sources, including the Solana
                blockchain, then available to us at the point of your request.
                Records on such publicly available sources are kept as current
                as possible, and reasonable endeavours will be taken to ensure
                that our Chain Information is based on current records at the
                time of your request. Due to the large amount of data involved,
                errors or omissions cannot be excluded. We make no
                representations, warranties or guarantees that the Chain
                Information is accurate, complete or up-to-date.
              </Text>
            </ListItem>
            {/* 6.2 */}
            <ListItem>
              <Text>
                Our Chain Information may not cover all information available or
                relevant to your request, including any particular information
                you may require. Our Services and Chain Information do not
                provide, or purport to provide, any professional advice, and is
                not to be relied upon as professional advice. Use of the Chain
                Information or any content on Zeta Flex is entirely at your own
                risk.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 7 */}
        <ListItem>
          <Header bold>LICENSE AND ACCESS TO ZETA FLEX</Header>
          <OrderedList>
            {/* 7.1 */}
            <ListItem>
              <Text>
                The accessibility and operation of Zeta Flex relies on
                technologies outside our control.
              </Text>
            </ListItem>
            {/* 7.2 */}
            <ListItem>
              <Text>We do not guarantee that Zeta Flex will be:</Text>
              <ol>
                {/* 7.2.1 */}
                <li>error-free;</li>
                {/* 7.2.2 */}
                <li>continuously available or uninterrupted in operation;</li>
                {/* 7.2.3 */}
                <li>
                  unhackable, uncompromisable and/or free of any data breach
                  (inadvertently or intentionally through interference or
                  interception by third parties); or
                </li>
                {/* 7.2.4 */}
                <li>
                  free of bugs or other harmful codes and components not
                  specifically mentioned herein.
                </li>
              </ol>
            </ListItem>
            {/* 7.3 */}
            <ListItem>
              <Text>
                We have no obligation to provide any maintenance, support or
                other services in relation to Zeta Flex, including providing any
                telephone assistance, documentation, error corrections, updates,
                upgrades, bug fixes, patches, and/or enhancements. However, if
                we do provide any updates, upgrade, bug fixes and/or patches,
                and/or enhancements in respect of Zeta Flex, you shall install
                them and keep your installation of Zeta Flex updated to the most
                recent release or version made available by us.
              </Text>
            </ListItem>
            {/* 7.4 */}
            <ListItem>
              <Text>
                Your use of Zeta Flex is subject to your compliance with these
                Terms. Where we provide you with programs and software, you are
                granted a limited, royalty-free, non-exclusive, personal,
                revocable and non-transferable, licence and right to download
                and use them for your own personal, non-commercial purposes. Our
                programs and software are licensed, not sold, to you, and you
                may only use them as permitted by these Terms. This licence does
                not transfer any title in Zeta Flex to you. We retain all rights
                in Zeta Flex and reserve all rights not expressly granted to
                you.
              </Text>
            </ListItem>
            {/* 7.5 */}
            <ListItem>
              <Text>
                Zeta Flex and its contents (including trademarks, source code,
                pages, documents and online graphics, audio and video), and all
                proprietary and intellectual property rights associated
                therewith, whether registered or not, are protected by law and
                owned by or licensed to us. You acknowledge that you have no
                right, title, interest in and to Zeta Flex and you agree not to
                challenge or assist others to challenge the validity of our
                ownership of or rights to Zeta Flex. You shall not reproduce,
                republish, distribute or otherwise exploit any content of Zeta
                Flex in any way without our prior express consent, which may be
                varied, conditioned or withheld for any or no reason and at any
                time.
              </Text>
            </ListItem>
            {/* 7.6 */}
            <ListItem>
              <Text>
                In order for us to provide you with the facilities and
                functionalities offered by Zeta Flex, you hereby grant us a
                non-exclusive, transferable, irrevocable, sub-licensable,
                royalty-free, worldwide licence to use, reproduce, distribute,
                create derivative works of, display and perform any information
                that you upload, submit, store, send or receive through Zeta
                Flex for our business and operational purposes.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 8 */}
        <ListItem>
          <Header bold>YOUR OBLIGATIONS</Header>
          <OrderedList>
            {/* 8.1 */}
            <ListItem>
              <Text>You represent, undertake and warrant that you:</Text>
              <ol>
                {/* 8.1.1 */}
                <li>
                  will provide us with all necessary information as may be
                  required by us in order to provide you with products and
                  services you need on Zeta Flex;
                </li>
                {/* 8.1.2 */}
                <li>
                  are not an Excluded Person, and you are not accessing and/or
                  using the SDK from an Excluded Jurisdiction;
                </li>
                {/* 8.1.3 */}
                <li>
                  will comply with all applicable laws and regulations with
                  respect to your activities in connection with Zeta Flex; and
                </li>
                {/* 8.1.4 */}
                <li>
                  will comply with all applicable security or encryption
                  standards, rules, procedures and guidelines.
                </li>
              </ol>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 9 */}
        <ListItem>
          <Header bold>USAGE RESTRICTIONS</Header>
          <OrderedList>
            {/* 9.1 */}
            <ListItem>
              <Text>You will not, and will not permit any other party to:</Text>
              <ol>
                {/* 9.1.1 */}
                <li>
                  modify, adapt, improve, enhance, alter, translate or create
                  derivative works of Zeta Flex;
                </li>
                {/* 9.1.2 */}
                <li>
                  reverse engineer, decompile, disassemble or otherwise attempt
                  to derive the source code or structure of Zeta Flex, or
                  decrypt our Zeta Flex;
                </li>
                {/* 9.1.3 */}
                <li>
                  use or merge Zeta Flex, or any component or element thereof,
                  with other software, databases or services not provided or
                  authorised by Zeta Flex;
                </li>
                {/* 9.1.4 */}
                <li>
                  publish, transmit, post, upload or maintain any defamatory,
                  obscene, threatening, malicious or otherwise objectionable
                  material, or any other material in violation of any statute,
                  rule or regulation in any jurisdiction to which you are
                  subject;
                </li>
                {/* 9.1.5 */}
                <li>
                  publish, transmit, post, upload or maintain any content or
                  material that is inaccurate or that violates or infringes the
                  right of a third party, including, without limitation,
                  contractual, moral, or privacy rights;
                </li>
                {/* 9.1.6 */}
                <li>
                  create a database by downloading and storing the contents of
                  Zeta Flex;
                </li>
                {/* 9.1.7 */}
                <li>
                  interfere or attempt to interfere with services we provide on
                  Zeta Flex ("<b>denial of service attacks</b>") including, but
                  not limited to, "flooding" of networks, deliberate attempts to
                  overload a service, attempts to "crash" us and taking any
                  action that imposes an unreasonable or disproportionately
                  large load on the services provided by us or the associated
                  infrastructure;
                </li>
                {/* 9.1.8 */}
                <li>
                  circumvent, or attempt to circumvent, user authentication or
                  security measures ("<b>cracking</b>") of any Internet or
                  intranet site or any of the accounts of our any other person
                  using Zeta Flex, including but not limited to, accessing data
                  and/or information not intended for you, logging into an
                  account you are not expressly authorised to access or in a
                  manner which is not authorised by us;
                </li>
                {/* 9.1.9 */}
                <li>
                  use any kind of program/script/command/application, or send
                  messages of any kind, designed to, in any manner interfere
                  with any user’s terminal session;
                </li>
                {/* 9.1.10 */}
                <li>
                  upload files that are corrupted, contain viruses, bugs or any
                  other similar software, code, component or programs which may
                  damage Zeta Flex or cause interference to the services
                  provided by us;
                </li>
                {/* 9.1.11 */}
                <li>
                  use any robot, spider, site search/retrieval application or
                  other manual or automatic device to retrieve, index, "scrape,"
                  "data mine", “crawl” or in any way gather Zeta Flex or
                  reproduce or circumvent the navigational structure or
                  presentation of Zeta Flex without our express prior written
                  consent;
                </li>
                {/* 9.1.12 */}
                <li>
                  violate any applicable laws, rules or regulations in
                  connection with your access or use of Zeta Flex;
                </li>
                {/* 9.1.13 */}
                <li>
                  use Zeta Flex to engage in fraudulent, illegal or suspicious
                  activities and/or transactions, including fraud, money
                  laundering and financing terrorism;
                </li>
                {/* 9.1.14 */}
                <li>
                  develop, distribute or sell any software or other
                  functionality capable of launching, being launched from or
                  otherwise integrated with Zeta Flex; and
                </li>
                {/* 9.1.15 */}
                <li>
                  remove, alter or obscure any copyright notice or any other
                  proprietary notice that appears on or in Zeta Flex.
                </li>
              </ol>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 10 */}
        <ListItem>
          <Header bold>LINKS FROM ZETA FLEX TO OTHER APPLICATIONS</Header>
          <OrderedList>
            {/* 10.1 */}
            <ListItem>
              <Text>
                Zeta Flex may contain hyperlinks which are not maintained by us.
                Use and/or access of the hyperlinks and access to such
                hyperlinked applications are entirely at your own risk.
              </Text>
            </ListItem>
            {/* 10.2 */}
            <ListItem>
              <Text>
                All hyperlinks to other applications are provided to you for
                convenience only. We do not, in any circumstances approve,
                endorse or accept any responsibility for any hyperlinked
                applications or any hyperlink contained in a hyperlinked
                application.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 11 */}
        <ListItem>
          <Header bold>FEES</Header>
          <OrderedList>
            {/* 11.1 */}
            <ListItem>
              <Text>
                We reserve the right to charge for services, and use of features
                and functionalities within Zeta Flex from time to time.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 12 */}
        <ListItem>
          <Header bold>SECURITY AND RISKS</Header>
          <OrderedList>
            {/* 12.1 */}
            <ListItem>
              <Text>
                Where appropriate, we use commercially and generally available
                technology to protect the security of communication made through
                Zeta Flex. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE
                LAWS, WE DO NOT ACCEPT LIABILITY FOR THE SECURITY, AUTHENTICITY,
                INTEGRITY OR CONFIDENTIALITY OF ANY TRANSACTIONS AND OTHER
                COMMUNICATIONS MADE THROUGH ZETA FLEX.
              </Text>
            </ListItem>
            {/* 12.2 */}
            <ListItem>
              <Text>
                Internet communications may be susceptible to interference or
                interception by third parties. You acknowledge that there are
                inherent risks (including risks related to security,
                authenticity, integrity and confidentiality) in electronic
                communications, messaging and conducting transactions over
                electronic networks. TO THE MAXIMUM EXTENT PERMITTED UNDER
                APPLICABLE LAW, WE EXPRESSLY DISCLAIM ANY LIABILITY FOR SUCH
                RISKS, WHICH YOU AGREE TO ASSUME. WE MAKE NO WARRANTIES THAT
                ZETA FLEX IS FREE OF INFECTION BY COMPUTER VIRUSES OR OTHER
                UNAUTHORISED SOFTWARE.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 13 */}
        <ListItem>
          <Header bold>GENERAL DISCLAIMER AND LIMITATION OF LIABILITY</Header>
          <OrderedList>
            {/* 13.1 */}
            <ListItem>
              <Text>
                ZETA FLEX IS PROVIDED OR MADE AVAILABLE ON AN "AS IS" AND "AS
                AVAILABLE" BASIS WITH NO WARRANTIES WHATSOEVER. TO THE FULLEST
                EXTENT PERMITTED BY LAW, WE DISCLAIM ALL REPRESENTATIONS,
                CONDITIONS AND WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED,
                STATUTORY OR OTHERWISE, IN RELATION TO THE FOREGOING, INCLUDING
                WITHOUT LIMITATION TO ALL CONDITIONS AND WARRANTIES OF
                SATISFACTORY QUALITY, ACCURACY, COMPLETENESS, CORRECTNESS,
                CURRENCY, TIMELINESS, RELIABILITY, AVAILABILITY, TITLE, QUALITY,
                MERCHANTABILITY, INTEROPERABILITY, SECURITY, FITNESS FOR A
                PARTICULAR PURPOSE, NON-INFRINGEMENT OR THAT ZETA FLEX WILL BE
                WITHOUT FAILURE, DELAY INTERRUPTION, MALWARE OR ERROR.
              </Text>
            </ListItem>
            {/* 13.2 */}
            <ListItem>
              <Text>
                To the maximum extent permitted under applicable laws, we make
                no guarantee, representation or warranty:
              </Text>
              <ol>
                {/* 13.2.1 */}
                <li>
                  regarding the timeliness, reliability, accuracy, completeness,
                  accessibility, quality, fitness for a particular purpose,
                  operation or usefulness of any portion of Zeta Flex;
                </li>
                {/* 13.2.2 */}
                <li>
                  regarding the availability or appropriateness of Zeta Flex;
                </li>
                {/* 13.2.3 */}
                <li>
                  that Zeta Flex or your use of Zeta Flex will not infringe the
                  intellectual property or other proprietary rights of any third
                  party;
                </li>
                {/* 13.2.4 */}
                <li>
                  that Zeta Flex will be error-free, continuously available or
                  uninterrupted in operation, or free of bugs or other harmful
                  codes or components;
                </li>
                {/* 13.2.5 */}
                <li>
                  that Zeta Flex will operate or function properly on your
                  devices or operating systems;
                </li>
                {/* 13.2.6 */}
                <li>
                  that Zeta Flex will not cause any damage to your devices or
                  operating systems; or
                </li>
                {/* 13.2.7 */}
                <li>
                  that there will not be any unauthorised access and/or use of
                  your Wallet and/or Private Key.
                </li>
              </ol>
            </ListItem>
            {/* 13.3 */}
            <ListItem>
              <Text>
                Your use of Zeta Flex is voluntary and at your sole risk. You
                are solely responsible for:
              </Text>
              <ol>
                {/* 13.3.1 */}
                <li>
                  your reliance on Zeta Flex and the information or content
                  available through Zeta Flex;
                </li>
                {/* 13.3.2 */}
                <li>
                  any liability, loss or damage that you may incur through use
                  of Zeta Flex; and
                </li>
                {/* 13.3.3 */}
                <li>
                  all decisions or actions resulting from your use of Zeta Flex
                  and its contents.
                </li>
              </ol>
            </ListItem>
            {/* 13.4 */}
            <ListItem>
              <Text>
                We will not be liable for any loss, damage, expense or costs:
              </Text>
              <ol>
                {/* 13.4.1 */}
                <li>
                  that you may incur as a result of your using or relying on any
                  data, conclusion, statement, opinion, representation or
                  information on Zeta Flex;
                </li>
                {/* 13.4.2 */}
                <li>
                  that you may incur as a result of your dealing with a third
                  party service provider or another user whether or not through
                  Zeta Flex;
                </li>
                {/* 13.4.3 */}
                <li>
                  resulting from any delay in operation or transmission,
                  communication failure, Internet access difficulties or
                  malfunction of equipment or software;
                </li>
                {/* 13.4.4 */}
                <li>
                  arising out of or in connection with any unauthorised access
                  to your Wallet and/or Private Keys;
                </li>
                {/* 13.4.5 */}
                <li>arising out of the loss of your Private Keys; and/or</li>
                {/* 13.4.6 */}
                <li>
                  the conduct or the views of any person who accesses or uses
                  Zeta Flex.
                </li>
              </ol>
            </ListItem>
            {/* 13.5*/}
            <ListItem>
              <Text>
                We are not responsible for the underlying technologies that are
                used or supported on Zeta Flex. You agree to bear all risks
                associated with these technologies.
              </Text>
            </ListItem>
            {/* 13.6 */}
            <ListItem>
              <Text>
                WE, AND OUR PARENTS, SUBSIDIARIES, AFFILIATES, OFFICERS,
                DIRECTORS, SHAREHOLDERS, EMPLOYEES AND AGENTS, SHALL NOT BE
                LIABLE, IN ANY CIRCUMSTANCES OR LEGAL THEORIES WHATSOEVER, FOR
                ANY LOSS OF DATA, BUSINESS, PROFITS OR GOODWILL, LOSS OF USE,
                INTERRUPTION OF BUSINESS, OR FOR ANY INDIRECT, SPECIAL,
                INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY CHARACTER, EVEN IF WE
                ARE AWARE OF THE RISK OF SUCH DAMAGES, THAT RESULT IN ANY WAY
                FROM YOUR USE OF, ACCESS TO OR RELIANCE ON ZETA FLEX. Our
                liability to you shall not, for any reason, exceed:
              </Text>
              <ol>
                {/* 13.6.1 */}
                <li>
                  the amount paid by you to us in relation to your use or access
                  to Zeta Flex in the one (1) year period preceding the incident
                  giving rise to your claim against us; or
                </li>
                {/* 13.6.2 */}
                <li>
                  S$100 (Singapore Dollars one hundred only), whichever is
                  lower.
                </li>
              </ol>
            </ListItem>
            {/* 13.7*/}
            <ListItem>
              <Text>
                This limitation applies to all causes of action in the aggregate
                including, without limitation, breach of contract, breach of
                warranty, negligence, strict liability, misrepresentations and
                other torts. In any jurisdiction in which these limitations of
                liability are restricted, our liability is limited to the
                greatest extent permitted by law.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 14 */}
        <ListItem>
          <Header bold>INDEMNITY</Header>
          <OrderedList>
            {/* 14.1 */}
            <ListItem>
              <Text>
                You agree to indemnify us and our directors, officers,
                shareholders, employees, agents, sub-contractors and licensors
                (collectively the “<b>Indemnified Persons</b>”) in respect of
                any and all claims, losses, damages, expenses (including legal
                costs on a full indemnity basis), or liabilities (whether
                criminal or civil) and costs of settlement suffered or incurred
                by the Indemnified Persons or asserted against them in respect
                of:
              </Text>
              <ol>
                {/* 14.1.1 */}
                <li>any unauthorised access and use of Zeta Flex by you;</li>
                {/* 14.1.2 */}
                <li>any breach of these Terms by you;</li>
                {/* 14.1.3 */}
                <li>
                  our reliance on information, data or records provided by you
                  in connection with your use of Zeta Flex;
                </li>
                {/* 14.1.4 */}
                <li>
                  any dispute between you and a third party service provider or
                  another user of Zeta Flex; and/or
                </li>
                {/* 14.1.5 */}
                <li>
                  the occurrence of any event due to your act, omission or
                  default which comprises the security or integrity of Zeta Flex
                  or its contents.
                </li>
              </ol>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 15 */}
        <ListItem>
          <Header bold>FORCE MAJEURE</Header>
          <OrderedList>
            {/* 15.1 */}
            <ListItem>
              <Text>
                We are not liable for any loss, damage or penalty resulting from
                delays or failures in performance if the delay or failure
                results from events beyond our reasonable control (a “
                <b>Force Majeure Event</b>”). Force Majeure Events shall
                include, but are not limited to, acts of God, war, hostility,
                invasion, act of foreign enemies, rebellion, revolution, riots,
                civil war, disturbances, requisitioning or other acts of civil
                or military authority, laws, regulations, acts or orders of any
                governmental authority, body, agency or official, fires,
                inclement weather, rain or floods (however caused), strikes,
                lock-outs or other labour disputes, epidemics, outbreaks,
                embargoes, breakdown of equipment, plant or machinery
                (including, but not limited to, data centre, telecommunications
                systems and utility services) or other catastrophe.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 16 */}
        <ListItem>
          <Header bold>CONFIDENTIALITY</Header>
          <OrderedList>
            {/* 15.1 */}
            <ListItem>
              <Text>
                You shall, at all times, keep confidential all information
                provided by us or on our behalf that is marked or is by its
                nature confidential or proprietary (“
                <b>Confidential Information</b>”). You shall not disclose or
                permit to be disclosed the Confidential Information to any third
                party without our prior written consent. These obligations of
                confidentiality do not apply to information that is publicly
                available, already in your possession without confidentiality
                restrictions or required to be disclosed by order of a court or
                the relevant governmental authorities.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 17 */}
        <ListItem>
          <Header bold>PRIVACY POLICY</Header>
          <OrderedList>
            {/* 17.1 */}
            <ListItem>
              <Text>
                Any personal data or information which you provide to us is also
                subject to our Privacy Policy (available at:
                https://link.ap1.storjshare.io/s/jxk37z64cqkmo4542xwkvfvx27xa/privacypolicy/privacy_policy.pdf),
                which is incorporated by reference into these Terms.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 18 */}
        <ListItem>
          <Header bold>MONITORING OF USE AND AUDIT</Header>
          <OrderedList>
            {/* 18.1 */}
            <ListItem>
              <Text>
                We reserve the right to monitor usage of Zeta Flex by any person
                and have the sole discretion to refuse access or use by any
                person at any time without having to give any reason or any
                prior notice.
              </Text>
            </ListItem>
            {/* 18.2 */}
            <ListItem>
              <Text>
                You will be asked to account for any unauthorised access. You
                shall provide us with your fullest cooperation and all necessary
                support for the conduct of the audits at no cost to us.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 19 */}
        <ListItem>
          <Header bold>TERMINATION</Header>
          <OrderedList>
            {/* 19.1 */}
            <ListItem>
              <Text>
                We reserve the right to terminate your access and use of Zeta
                Flex, immediately without prior notice and without liability to
                you, if:
              </Text>
              <ol>
                {/* 19.1.1 */}
                <li>
                  you are in breach, or will be in breach, of these Terms and
                  (if the breach is remediable) have not remedied the breach to
                  our satisfaction and within such time we have stipulated;
                </li>
                {/* 19.1.2 */}
                <li>
                  you have used or are using Zeta Flex for any illegal or
                  impermissible purpose or activity including any activity which
                  may expose or potentially expose us to civil or criminal
                  liability;
                </li>
                {/* 19.1.3 */}
                <li>
                  we deem fit to do so for security reasons or to protect our
                  interests or the interests of other users of Zeta Flex
                  (including other users);
                </li>
                {/* 19.1.4 */}
                <li>we determine that your account is inactive;</li>
                {/* 19.1.5 */}
                <li>
                  a Force Majeure Event continues for more than one (1) month;
                  and/or
                </li>
                {/* 19.1.6 */}
                <li>
                  we are required to do so in compliance with any applicable law
                  or regulation, or we are ordered to do so by any enforcement,
                  regulatory or other governmental authority.
                </li>
              </ol>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 20 */}
        <ListItem>
          <Header bold>APPLICABLE LAWS</Header>
          <OrderedList>
            {/* 20.1 */}
            <ListItem>
              <Text>
                Use of Zeta Flex and these Terms are governed by the laws of the
                Republic of Singapore, without reference to conflicts of laws
                rules.
              </Text>
            </ListItem>
            {/* 20.2 */}
            <ListItem>
              <Text>
                You submit to the non-exclusive jurisdiction of the courts of
                Singapore in relation to all disputes and any claim relating to
                use of this Zeta Flex.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        {/* 21 */}
        <ListItem>
          <Header bold>MISCELLANEOUS</Header>
          <OrderedList>
            {/* 21.1 */}
            <ListItem>
              <Text>
                If any provision in these Terms is found invalid or
                unenforceable, that provision shall be enforced to the maximum
                extent possible, and the other provisions contained herein will
                remain in full force and effect. These Terms constitute the
                entire agreement between us in relation to the subject matter
                herein and supersede any and all prior or contemporaneous
                written or oral agreements or representations. Our failure to
                insist upon or enforce strict performance of any provision of
                these Terms shall not be construed as a waiver of any provision
                or right. Other than the Indemnified Persons, a person who is
                not a party to this these Terms shall have no right under the
                Contracts (Rights of Third Parties) Act (Cap. 53B) to enforce
                these Terms.
              </Text>
            </ListItem>
            {/* 21.2 */}
            <ListItem>
              <Text>
                You may not transfer or assign any of your contractual rights or
                obligations without our prior written consent. We may, by notice
                in writing to you, transfer or assign or novate any of our
                contractual rights and obligations.
              </Text>
            </ListItem>
            {/* 21.3 */}
            <ListItem>
              <Text>
                The headings used in these Terms are included for convenience
                only and will not limit or otherwise affect the provisions
                herein. Any amendment of these Terms, if sought by you, shall
                not be effective unless it is made in writing and signed or
                agreed to by our duly authorised representative.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
      </OrderedList>
    </TCsContainer>
  );
};

const ListItem = styled.li`
  display: block;
  &:before {
    content: counters(item, ".") " ";
    counter-increment: item;
  }

  /* font-weight: bold; */
`;
const UnorderedList = styled.ul``;
const OrderedList: any = styled(UnorderedList).attrs({
  as: "ol",
})`
  counter-reset: item;
  padding-left: 10px;
`;

const TCsContainer = styled.div`
  li {
    font-size: 12px;
  }

  & > ${OrderedList} > ${ListItem}:before {
    content: counters(item, ".") ". ";
  }

  a,
  a:visited {
    color: ${(props) => props.theme.font.callToAction};
  }
`;

const Text = styled(Paragraph)`
  margin: 0.5rem 0;
  word-break: break-word;
`;

const Header = styled(Paragraph)`
  display: inline-block;
`;

const FeeTable = styled.table`
  border-collapse: collapse;
  border: 1px solid white;

  th,
  td {
    border: 1px solid white;
    padding: 0.5rem;
    word-break: normal;
  }
`;

const AnnexTitle = styled.div`
  text-align: center;
`;
const DefinitionsTitle = styled.div`
  text-align: center;
`;
