import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { Flex, FlexClient, solendUtils } from "@zetamarkets/flex-sdk";
import {
  Auction,
  Kind,
  Option,
  TokenAcc,
  UnderlyingType,
} from "@zetamarkets/flex-sdk/dist/flex/types";
import Big from "big.js";
import { useCallback, useEffect, useState } from "react";
import shallow from "zustand/shallow";
import { useFlexStore } from "../stores/useFlexStore";
import { getSolanaConnection, getSolanaNetwork } from "../utils/connection";
import { getMintMapping } from "../utils/general";
import { useClient } from "./useClient";
import { getAccount, getMint } from "@solana/spl-token";

export const useUtils = () => {
  const [nativeSolBal, setNativeSolBal] = useState<number>();
  const client = useClient();
  const {
    isInitialized,
    auctions,
    mintsInfo,
    addMint,
    exchangeRateCache,
    addExchangeRate,
  } = useFlexStore(
    (s) => ({
      isInitialized: s.isInitialized,
      auctions: s.auctions,
      mintsInfo: s.mintsInfo,
      addMint: s.addMint,
      exchangeRateCache: s.exchangeRateCache,
      addExchangeRate: s.addExchangeRate,
    }),
    shallow
  );
  const wallet = useSafeWallet();

  useEffect(() => {
    if (!wallet.publicKey) return;
    const conn = getSolanaConnection();

    conn
      .getAccountInfo(wallet.publicKey)
      .then((info) => {
        if (!info?.lamports) return;

        const balance = Big(info?.lamports).div(Math.pow(10, 9));

        setNativeSolBal(balance.toNumber());
      })
      .catch((err) => {
        console.error("Error fetching sol balance");
        console.error(err);
      });
  }, [
    wallet.publicKey,
    client.data?.bids.length,
    client.data?.expiredPositions.length,
  ]);

  const convertNativeNumberToDecimal = useCallback((num: number) => {
    const divAmount = Math.pow(10, 6);
    const adjusted = Big(num).div(divAmount).toNumber();

    return adjusted;
  }, []);

  const convertNativeMintNumberToDecimal = useCallback(
    (num: number, mintAddr: PublicKey) => {
      const mintAddrStr = mintAddr.toString();
      if (!mintsInfo[mintAddrStr]) {
        getMint(getSolanaConnection(), mintAddr)
          .then((info) => {
            addMint(mintAddrStr, info);
          })
          .catch((err) => console.error("error retrieving mint info", err));
        return undefined;
      }

      const mintInfo = mintsInfo[mintAddrStr];
      const divAmount = Math.pow(10, mintInfo.decimals);
      const adjusted = Big(num).div(divAmount).toNumber();

      return adjusted || 0;
    },
    [addMint, mintsInfo]
  );

  /**
   * Get the user's bid currency balance based on the provided auction
   *
   * @param auction The auction to determine the bid currency off
   * @param client The flex client instance
   * @returns The bid currency balance of the user based on the provided auction
   */
  const getBidCurrencyBalance = useCallback(
    (auction: Auction, client: FlexClient, customBidMint?: PublicKey) => {
      let tokenAcc: TokenAcc | undefined;

      const bidCurrencyMint = customBidMint || auction.bidCurrencyMint;
      const bidCurrencyStr =
        customBidMint || getMintMapping(auction.bidCurrencyMint);

      if (
        bidCurrencyStr === "SOL" ||
        bidCurrencyStr.toString() ===
          "So11111111111111111111111111111111111111112"
      ) {
        return nativeSolBal;
      }

      client.tokenAccounts.forEach((acc) => {
        if (!acc.mint.equals(bidCurrencyMint)) return;

        tokenAcc = acc;
      });

      if (!tokenAcc) return;

      return convertNativeMintNumberToDecimal(
        Number(tokenAcc.amount),
        bidCurrencyMint
      );
    },
    [convertNativeMintNumberToDecimal, nativeSolBal]
  );

  const getTokenAccountMint = useCallback(async (address: PublicKey) => {
    try {
      // eslint-disable-next-line
      return (await getAccount(getSolanaConnection(), address)).mint;
    } catch (err) {
      console.error("error retrieving token account info", err);
    }
  }, []);

  const convertDecimalToNativeMintNumber = useCallback(
    (num: number, mintAddr: PublicKey) => {
      const mintAddrStr = mintAddr.toString();
      if (!mintsInfo[mintAddrStr]) {
        getMint(getSolanaConnection(), mintAddr)
          .then((info) => {
            addMint(mintAddrStr, info);
          })
          .catch((err) => console.error("error retrieving mint info", err));
        return undefined;
      }

      const mintInfo = mintsInfo[mintAddrStr];
      const mulFactor = Math.pow(10, mintInfo.decimals);
      const adjusted = Big(num).mul(mulFactor).toNumber();

      return adjusted;
    },
    [addMint, mintsInfo]
  );

  const convertDecimalToNativeMintNumberAsync = async (
    num: number,
    mintAddr: PublicKey
  ) => {
    try {
      const mintInfo = await getMint(getSolanaConnection(), mintAddr);
      const mulFactor = Math.pow(10, mintInfo.decimals);
      const adjusted = Big(num).mul(mulFactor).toNumber();

      return adjusted;
    } catch (e) {
      console.log(JSON.stringify(e));
    }
  };

  const convertDecimalToNativeNumber = useCallback((num: number) => {
    const mulFactor = Math.pow(10, 6);
    const adjusted = Big(num).mul(mulFactor).toNumber();

    return adjusted;
  }, []);

  const getOptionAuction = useCallback(
    (addr: PublicKey | undefined) => {
      if (!addr || !isInitialized) return undefined;

      const found = auctions.option.find((a) => a.address.equals(addr));

      return found;
    },
    [auctions.option, isInitialized]
  );

  const getSpotAuction = useCallback(
    (addr: PublicKey | undefined) => {
      if (!addr || !isInitialized) return undefined;

      const found = auctions.spot.find((a) => a.address.equals(addr));

      return found;
    },
    [auctions.spot, isInitialized]
  );

  /**
   * Get's an options multiplier. Returns undefined if multiper not in cahce.
   */
  const getMultiplier = useCallback(
    (optionMint: PublicKey) => {
      const mintAddrStr = optionMint.toString();
      if (!mintsInfo[mintAddrStr]) {
        getMint(getSolanaConnection(), optionMint)
          .then((info) => {
            addMint(mintAddrStr, info);
          })
          .catch((err) => {
            console.error("error retrieving mint info", err);
          });

        return undefined;
      }

      const mintInfo = mintsInfo[mintAddrStr];

      return Big(1).div(Big(10).pow(mintInfo.decimals)).toNumber();
    },
    [addMint, mintsInfo]
  );

  const getTokenExchangeRate = useCallback(
    (option: Option) => {
      const underlying = Flex.getUnderlying(option.underlyingMint);

      let mintAddr: PublicKey;
      if (option.kind === Kind.CTOKENPUT) mintAddr = option.collateralMint;
      else if (underlying.underlyingType === UnderlyingType.SOLEND_C_TOKEN)
        mintAddr = option.underlyingMint;
      else return;

      if (!exchangeRateCache[mintAddr.toString()]) {
        const reserveAddr = solendUtils.getSolendReserveMapping(
          getSolanaNetwork(),
          mintAddr
        );

        solendUtils
          .getSolendExchangeRate(getSolanaConnection(), reserveAddr)
          .then((cTokenExchageRate) => {
            addExchangeRate(mintAddr.toString(), cTokenExchageRate);
          })
          .catch((err) => {
            console.error("error retrieving cToken exchange rate", err);
          });
        return undefined;
      }

      const exchangeRate = exchangeRateCache[mintAddr.toString()];

      return exchangeRate;
    },
    [addExchangeRate, exchangeRateCache]
  );

  /**
   * Return the max number of decimal places allowed for the given option
   */
  const getOptionPrecision = useCallback(
    (optionMint: PublicKey) => {
      const mintAddrStr = optionMint.toString();
      if (!mintsInfo[mintAddrStr]) {
        getMint(getSolanaConnection(), optionMint)
          .then((info) => {
            addMint(mintAddrStr, info);
          })
          .catch((err) => {
            console.error("error retrieving mint info", err);
          });
        return undefined;
      }

      const mintInfo = mintsInfo[mintAddrStr];

      return mintInfo.decimals;
    },
    [addMint, mintsInfo]
  );

  return {
    nativeSolBal,
    convertNativeMintNumberToDecimal,
    convertNativeNumberToDecimal,
    getBidCurrencyBalance,
    convertDecimalToNativeMintNumber,
    convertDecimalToNativeMintNumberAsync,
    convertDecimalToNativeNumber,
    getTokenAccountMint,
    getOptionAuction,
    getSpotAuction,
    getMultiplier,
    getOptionPrecision,
    getTokenExchangeRate,
  };
};
