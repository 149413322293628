import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { WalletMultiButton } from "@snowflake-so/safe-adapter-react-ui";
import { useEffect } from "react";
import { WalletContainer } from "./styles";
// Default styles that can be overridden by your app
import "@solana/wallet-adapter-react-ui/styles.css";
import "./index.css";
import {
  NotificationType,
  useNotificationStore,
} from "../../stores/useNotificationStore";
import { shortenAddress } from "../../utils/general";

export const Wallet = ({
  className,
}: {
  className?: string;
}): React.ReactElement => {
  const wallet = useSafeWallet();
  const { connected } = wallet;
  const notify = useNotificationStore((state) => state.notify);

  useEffect(() => {
    if (connected && wallet.publicKey) {
      notify({
        title: "Wallet connected",
        description: `${shortenAddress(
          wallet.publicKey.toString()
        )} connected successfully`,
      });
    }
  }, [connected, notify, wallet.publicKey]);

  /**
   * When wallet is disconnected, clear the client store
   */
  useEffect(() => {
    if (wallet.disconnecting) {
      notify({
        type: NotificationType.INFO,
        title: "Wallet disconnected",
        description: `${shortenAddress(
          wallet.publicKey?.toString() || "-"
        )} disconnected successfully`,
      });
    }
  }, [wallet.disconnecting]);

  return (
    <WalletContainer connected={connected} className={className}>
      <WalletMultiButton />
    </WalletContainer>
  );
};
