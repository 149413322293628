import { Flex } from "@zetamarkets/flex-sdk";
import Big from "big.js";
import { useMemo } from "react";
import styled from "styled-components";
import { useClient } from "../../../hooks/useClient";
import { useFlex } from "../../../hooks/useFlex";
import {
  capitalizeFirstLetter,
  getFormattedExpiry,
  getMintMapping,
  getUnderlyingStr,
} from "../../../utils/general";
import { ClientSectionViewContainer } from "./styles";
import {
  Row,
  Data,
  DataText,
  Table,
  TableHead,
  Heading,
  HeadingText,
  TableBody,
  HeaderRow,
} from "../../TableStyles";
import { useUtils } from "../../../hooks/useUtils";
import {
  FlexOptionType,
  Option,
  SettlementType,
} from "@zetamarkets/flex-sdk/dist/flex/types";
import { EmptyMessage } from "./EmptyMessage";

const NUM_COLUMNS = 13;

export const Positions = () => {
  const client = useClient();
  const flex = useFlex();
  const {
    convertNativeMintNumberToDecimal,
    convertNativeNumberToDecimal,
    getMultiplier,
    getTokenExchangeRate,
  } = useUtils();

  const positions = useMemo(() => {
    if (!client.isInitialized || !flex.isInitialized) return null;

    if (!client.data.livePositions.length) {
      return <EmptyMessage message={"There are no positions."} />;
    }

    return client.data.livePositions.map((pos) => {
      const optionInfo = Flex.getOption(
        pos.optionAccount,
        FlexOptionType.Normal
      );

      if (optionInfo == null) return null;

      // Auction doesn't always exist.
      const auction = flex.data.optionAuctions.find((a) =>
        a.optionInfo.optionAccount.equals(optionInfo.optionAccount)
      );

      const multiplier = getMultiplier(optionInfo.optionMint);

      const putMultiplier =
        1 /
        (convertNativeNumberToDecimal(
          (optionInfo as Option).strike.toNumber()
        ) *
          10);

      const premiumPerUnderlying = () => {
        if (!auction) return "-";

        const exchangeAmount = convertNativeMintNumberToDecimal(
          auction.exchangeAmount,
          auction.bidCurrencyMint
        );

        if (!exchangeAmount) return "-";

        if ((optionInfo as Option).kind === "call" && multiplier) {
          return Big(exchangeAmount)
            .div(auction.amount * multiplier)
            .toNumber();
        } else {
          return Big(exchangeAmount)
            .div(pos.amount * putMultiplier)
            .toNumber();
        }
      };

      const underlying = getUnderlyingStr(optionInfo as Option);

      const bidCurrency = auction
        ? getMintMapping(auction.bidCurrencyMint)
        : "-";

      const bidCurrencyStr =
        bidCurrency === "11111111111111111111111111111111"
          ? "Any"
          : bidCurrency;

      const totalPremiumPaid = auction
        ? convertNativeMintNumberToDecimal(
            auction.exchangeAmount,
            auction.bidCurrencyMint
          )
        : "-";

      const premiumPerUnderlyingStr = premiumPerUnderlying();
      const sizeInUnderlying =
        (optionInfo as Option).kind == "call" && multiplier
          ? Big(pos.amount).mul(multiplier).toNumber()
          : pos.amount * putMultiplier;

      const expiry = getFormattedExpiry(optionInfo.expiry, "client-expiry");
      const kind = capitalizeFirstLetter((optionInfo as Option).kind);
      const strike = convertNativeNumberToDecimal(
        (optionInfo as Option).strike.toNumber()
      );
      const exerciseCurrency =
        optionInfo.settlementType === SettlementType.PHYSICAL
          ? getMintMapping((optionInfo as Option).exerciseMint)
          : "-";
      return (
        <Row columns={NUM_COLUMNS} key={pos.optionAccount.toString()}>
          {/* Direction */}
          <Data>
            <DataText color="positive" title="Buy">
              Buy
            </DataText>
          </Data>
          {/* Underlying */}
          <Data>
            <DataText title={underlying}>{underlying}</DataText>
          </Data>
          {/* Bid Currency*/}
          <Data>
            <DataText title={bidCurrencyStr}>{bidCurrencyStr}</DataText>
          </Data>
          {/* Total Premium Paid */}
          <Data>
            <DataText title={totalPremiumPaid?.toString()}>
              {totalPremiumPaid}
            </DataText>
          </Data>
          {/* Premium Per Underlying*/}
          <Data>
            <DataText title={premiumPerUnderlyingStr.toString()}>
              {premiumPerUnderlyingStr}
            </DataText>
          </Data>
          {/* Type */}
          <Data>
            <DataText title="European">European</DataText>
          </Data>
          {/* Instrument */}
          <Data>
            <DataText title={kind}>{kind}</DataText>
          </Data>
          {/* Strike */}
          <Data>
            <DataText title={strike.toString()}>{strike}</DataText>
          </Data>
          {/* Size in Underlying */}
          <Data>
            <DataText title={sizeInUnderlying.toString()}>
              {sizeInUnderlying}
            </DataText>
          </Data>
          {/* Expiry */}
          <Data>
            <DataText title={expiry}>{expiry}</DataText>
          </Data>
          {/* Settlement Method */}
          <Data>
            <DataText title={capitalizeFirstLetter(optionInfo.settlementType)}>
              {capitalizeFirstLetter(optionInfo.settlementType)}
            </DataText>
          </Data>
          {/* Exercise Currency */}
          <Data>
            <DataText title={exerciseCurrency}>{exerciseCurrency}</DataText>
          </Data>
          {/* Settlement Currency */}
          <Data>
            <DataText>{getMintMapping(optionInfo.collateralMint)}</DataText>
          </Data>
        </Row>
      );
    });
  }, [
    client.data?.livePositions,
    client.isInitialized,
    convertNativeMintNumberToDecimal,
    convertNativeNumberToDecimal,
    flex.isInitialized,
    getMultiplier,
    getTokenExchangeRate,
  ]);

  return (
    <PositionsContainer>
      <Table>
        <TableHead>
          <HeaderRow columns={NUM_COLUMNS}>
            <Heading>
              <HeadingText title="Direction">DIRECTION</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Underlying">UNDERLYING</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Bid Currency">BID CURRENCY</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Total Premium Paid">
                TOTAL PREMIUM PAID
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Premium Per Underlying">
                PREMIUM PER UNDERLYING
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Type">TYPE</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Instrument">INSTRUMENT</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Strike">STRIKE</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Size in Underlying">
                SIZE IN UNDERLYING
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Expiry (UTC)">EXPIRY (UTC)</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Settlement Method">
                SETTLEMENT METHOD
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Exercise Currency">
                EXERCISE CURRENCY
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Settlement Currency">
                SETTLEMENT CURRENCY
              </HeadingText>
            </Heading>
          </HeaderRow>
        </TableHead>
        <TableBody>{positions}</TableBody>
      </Table>
    </PositionsContainer>
  );
};
const PositionsContainer = styled(ClientSectionViewContainer)``;
