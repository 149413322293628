import { DashboardSection } from "../../components/DashboardSection";
import { DashboardContainer } from "./styles";

export const Dashboard = () => {
  return (
    <DashboardContainer>
      <DashboardSection />
    </DashboardContainer>
  );
};
