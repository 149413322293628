import { ZetaTheme } from "../../utils/theme";
import { ButtonToggleContainer, ButtonOne, ButtonTwo } from "./styles";

// TODO: type properly
type ButtonToggleProps = {
  colorLeft: keyof ZetaTheme["buttons"];
  colorRight: keyof ZetaTheme["buttons"];
  labelLeft: string;
  labelRight: string;
  // Boolean for an inverse theme for buttons in the toggle
  inverse?: boolean;
  // Boolean for no border on unselected button
  noBorder?: boolean;
  // Pass down function from parent component
  onClickLeft?: () => void;
  onClickRight?: () => void;
  /**
   * Either the value of labelLeft or labelRight
   * to indicate what's currenlty selected
   */
  selected?: string;
  disableRight?: boolean;
  className?: string;
};

export const ButtonToggle = ({
  inverse,
  colorLeft,
  colorRight,
  labelLeft,
  labelRight,
  selected,
  onClickLeft,
  onClickRight,
  disableRight,
  className,
}: ButtonToggleProps) => {
  return (
    <ButtonToggleContainer className={className}>
      <ButtonOne
        label={labelLeft}
        color={selected === labelLeft ? colorLeft : "selectable"}
        noBorder={inverse && selected !== labelLeft}
        inverse={inverse || selected !== labelLeft}
        selected={selected === labelLeft}
        onClick={onClickLeft}
      />
      <ButtonTwo
        label={labelRight}
        color={selected == labelRight ? colorRight : "selectable"}
        noBorder={inverse && selected !== labelRight}
        inverse={inverse || selected !== labelRight}
        selected={selected === labelRight}
        onClick={onClickRight}
        disabled={disableRight}
      />
    </ButtonToggleContainer>
  );
};
