import styled from "styled-components";
import { Paragraph, Subtitle } from "./Text";

export const Table = styled.table`
  display: grid;
  /* 60px for header, then grow the rest */
  grid-template-rows: auto 1fr;
  /* Table consists of two rows and one column */
  grid-template-columns: 1fr;

  width: 100%;
  height: 100%;

  overflow: hidden;
`;
export const TableHead = styled.thead`
  margin: 0 1rem;
  padding: 1rem 0;
  border-bottom: ${(props) => `1px solid ${props.theme.background.table}`};
`;
export const Heading = styled.th`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 0.25rem;
  box-sizing: border-box;
`;
export const HeadingText = styled(Paragraph).attrs(() => ({
  color: "subtext",
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-weight: 500;
`;
export const TableBody = styled.tbody`
  height: 100%;
  overflow: auto;
  margin: 0 1rem;
`;
export const Row = styled.tr<{
  columns: number;
  selected?: boolean;
  selectable?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  justify-items: start;
  align-items: start;
  padding: 1rem 0;

  background: ${(props) =>
    props.selected ? props.theme.background.selected : undefined};

  border-bottom: ${(props) => `1px solid ${props.theme.background.table}`};

  transition: 200ms;
  cursor: ${(props) => (props.selectable ? "pointer" : "auto")};
  &:hover {
    background: ${(props) =>
      props.selectable ? props.theme.background.selected : undefined};
  }
`;

export const HeaderRow = styled(Row)`
  border-bottom: none;
  padding: 0;
`;
export const Data = styled.td`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 0.25rem;
  box-sizing: border-box;
  * {
    font-size: 14px;
  }
`;
export const ButtonData = styled(Data)`
  padding: 0 0.5rem;
`;

export const DataText = styled(Subtitle)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;

  * {
    font-size: 14px;
  }
`;

export const DataTextLink = styled.a`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: ${(props) => props.theme.buttons.highlight};

  * {
    font-size: 14px;
  }
`;
