import styled from "styled-components";
import { ZetaTheme } from "../../utils/theme";

export const ButtonWrapper = styled.button<{
  selected: boolean;
  color: keyof ZetaTheme["buttons"];
  inverse: boolean;
  noBorder: boolean;
  isBold: boolean;
  disabled: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  text-align: center;
  border-radius: 3px;
  transition: 200ms;
  &:hover {
    cursor: ${(props) =>
      props.disabled ? "not-allowed" : props.selected ? "auto" : "pointer"};
  }
  font-size: 12px;
  width: 100%;
  height: auto;
  padding: 0.25rem 0.5rem;
  box-shadow: none;

  border: ${(props) =>
    props.disabled
      ? `1px solid ${props.theme.buttons.unselectable}`
      : props.noBorder && !props.selected
      ? "1px solid transparent"
      : props.inverse || props.color
      ? `1px solid ${props.theme.buttons[props.color]}`
      : `1px solid ${props.theme.buttons.selectable}`};

  color: ${(props) =>
    props.inverse
      ? props.theme.buttons[props.color]
      : props.theme.background.primary};

  background: ${(props) =>
    props.disabled
      ? props.theme.buttons.unselectable
      : props.inverse
      ? "none"
      : props.theme.buttons[props.color]};
  font-weight: ${(props) => (props.isBold ? 600 : 400)};
`;
