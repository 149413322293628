import styled, { css } from "styled-components";
import { Check } from "styled-icons/boxicons-regular";
import { NotificationType } from "../../stores/useNotificationStore";
import { ButtonColors } from "../../utils/theme";

export const NotificationsListContainer = styled.div<{ isVisible: boolean }>`
  display: ${(props) => `${props.isVisible ? "flex" : "none"}`};
  flex-direction: column;

  z-index: 40;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 2rem 2rem 0;

  > * + * {
    margin-top: 1rem;
  }

  @media only screen and (max-width: 450px) {
    top: 0;
    right: 0;
    left: 0;
    max-height: 120px;
    overflow-y: scroll;
    top: 25px;
    background: none;
    margin: 2rem 0 0 0;
    padding: 0 2rem 2rem 2rem;
  }
`;

export const NotificationContainer = styled.div<{
  notificationType?: NotificationType;
}>`
  transition: opacity linear 200ms;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: ${(props) => props.theme.background.primary};
  box-shadow: 0px 10px 22px rgb(0 0 0 / 25%), 0px 21px 25px rgb(0 0 0 / 35%);
  border-radius: 8px;
  padding: 0.7rem 1rem;

  border: ${(props) =>
    props.notificationType === NotificationType.SUCCESS
      ? `1px solid ${props.theme.font.success}`
      : props.notificationType === NotificationType.INFO
      ? `1px solid ${props.theme.font.info}`
      : props.notificationType === NotificationType.WARNING
      ? `1px solid ${props.theme.font.warning}`
      : props.notificationType === NotificationType.LOADING
      ? `1px solid ${props.theme.font.loading}`
      : `1px solid ${props.theme.font.error}`};

  @media only screen and (min-width: 450px) {
    width: 300px;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.3);
  }

  @media only screen and (max-width: 450px) {
    &:first-child {
      margin-top: 2px;
    }
  }
`;

export const IconImg = styled.img`
  margin-right: 0.5rem;
`;

export const NotificationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const NotificationHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Circle = styled.div<{ color: ButtonColors }>`
  min-height: 12px;
  min-width: 12px;
  font-size: ${(props) => (props.color === "callToAction" ? "6px" : "8px")};
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${(props) =>
    props.color
      ? props.theme.buttons[props.color]
      : props.theme.buttons.callToAction};
  border: ${(props) =>
    props.color
      ? `1px solid ${props.theme.buttons[props.color]}`
      : `1px solid ${props.theme.buttons.callToAction}`};
  margin-right: 1rem;
`;

export const CrossIcon = styled.img`
  height: 12px;
  width: 12px;
  transition: 200ms;
  position: relative;
  z-index: 41;
  &:hover {
    cursor: pointer;
    filter: brightness(1.4);
  }
  margin-left: 0.5rem;
`;

export const TxidLink = styled.a<{ hasTxid?: string }>`
  ${(props) =>
    props.hasTxid &&
    css`
      ${NotificationHeaderSpan} {
        svg {
          color: ${(props) => props.theme.buttons.selectable};
          height: 12px;
          margin-left: 0.2rem;
          margin-bottom: 0.1rem;
          &:hover {
            transition: 200ms;
            filter: brightness(1.5);
          }
        }
      }

      ${NotificationContainer} {
        &:hover {
          transition: 200ms;
          background: ${(props) => props.theme.background.primary};
        }
      }
    `};
`;

export const NotificationHeaderSpan = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CheckIcon = styled(Check)`
  min-height: 12px;
  min-width: 12px;
`;

export const Loader = styled.div`
  position: relative;
  background: conic-gradient(
    from 0deg at 50% 50%,
    #e4fe49 0deg,
    #0d1238 360deg
  );
  border-radius: 50%;
  height: 18px;
  min-height: 18px;
  max-height: 18px;
  width: 18px;
  min-width: 18px;
  max-width: 18px;
  animation: spin 1.75s linear infinite;
  margin-right: 0.5rem;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const InnerLoader = styled.div`
  background: ${(props) => props.theme.background.primary};
  border-radius: 50%;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 3px;
  left: 3px;
`;
