import styled from "styled-components";

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const AccordionHeader = styled.div<{ selected: boolean }>`
  cursor: pointer;
  background: ${(props) => props.selected && props.theme.background.secondary};
`;
export const AccordionBody = styled.div<{ selected: boolean }>`
  background: ${(props) => props.theme.background.primary};
  overflow: hidden;
  transition: max-height 400ms ease;
  border-bottom: ${(props) =>
    props.selected && `1px solid ${props.theme.background.secondary}`};
`;
