import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ClientContext, useSetupClient } from "../../hooks/useClient";
import { useFlexSetup } from "../../hooks/useFlex";
import { AuctionInterface } from "../../pages/AuctionInterface";
import { Dashboard } from "../../pages/Dashboard";
import { MintingInterface } from "../../pages/MintingInterface";
import { Footer } from "../Footer";
import { Navbar } from "../Navbar";
import { NotificationsList } from "../Notification";
import { TermsAndConditions } from "../TermsAndConditions";
import { AppContainer } from "./styles";

export function App() {
  const client = useSetupClient();
  useFlexSetup();

  return (
    <BrowserRouter>
      <ClientContext.Provider value={client}>
        <AppContainer>
          <TermsAndConditions />
          <NotificationsList />
          <Navbar />
          <Routes>
            <Route path="/" element={<AuctionInterface />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/mint" element={<MintingInterface />} />
          </Routes>
          <Footer />
        </AppContainer>
      </ClientContext.Provider>
    </BrowserRouter>
  );
}
