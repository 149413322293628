import styled, { keyframes } from "styled-components";
import { Label, Paragraph } from "../../Text";

const loading = keyframes`
  0% {
    content: "Loading Client";
  }
  25% {
    content: "Loading Client.";
  }
  50% {
    content: "Loading Client..";
  }
  75% {
    content: "Loading Client...";
  }
`;

export const ClientSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ClientSectionViewContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

export const ClientButton = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80%;
  padding: 0.3rem 0;

  border-radius: 5px;
  background: ${(props) =>
    props.disabled
      ? props.theme.buttons.unselectable
      : props.theme.buttons.selectable};
  pointer-events: ${(props) => props.disabled && "none"};
  cursor: pointer;
`;

export const ButtonText = styled(Paragraph)`
  color: #0d1238;
`;

export const CooldownLabel = styled(Label)``;

export const ConnectWalletContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ClientLoader = styled.div`
  height: 100%;
  color: ${(props) => props.theme.font.primary};
  &::after {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;

    content: "Loading Client";
    animation: ${loading} 2s linear infinite;
  }
`;
