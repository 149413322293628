import { Wallet } from "../Wallet";
import {
  HeaderContainer,
  LeftContainer,
  PageLink,
  RightContainer,
  ZetaLogo,
} from "./styles";
import ZetaLogoUrl from "../../assets/images/zeta-logo.svg";
import { Subtitle } from "../Text";
import { useLocation } from "react-router-dom";
import { Tabs, TabType } from "./Tabs";
import { useState } from "react";

interface NavbarProps {
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

export const Navbar = ({ className }: NavbarProps) => {
  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState<TabType>(TabType.FLEX);
  return (
    <HeaderContainer className={className}>
      <LeftContainer>
        <ZetaLogo src={ZetaLogoUrl} />
        <Tabs
          selected={selectedTab}
          onTabChange={(tab) => setSelectedTab(tab)}
        />

        <PageLink to="/" selected={pathname === "/"}>
          <Subtitle bold color={pathname === "/" ? "highlight" : "selectable"}>
            Auctions
          </Subtitle>
        </PageLink>
        <PageLink to="/mint" selected={pathname === "/mint"}>
          <Subtitle
            bold
            color={pathname === "/mint" ? "highlight" : "selectable"}
          >
            Mint
          </Subtitle>
        </PageLink>
        <PageLink to="/dashboard" selected={pathname === "/dashboard"}>
          <Subtitle
            bold
            color={pathname === "/dashboard" ? "highlight" : "selectable"}
          >
            Dashboard
          </Subtitle>
        </PageLink>
      </LeftContainer>
      <RightContainer>
        <Wallet />
      </RightContainer>
    </HeaderContainer>
  );
};
