import { PublicKey } from "@solana/web3.js";
import { Flex } from "@zetamarkets/flex-sdk";
import { useMemo, useState } from "react";
import { useFlex } from "../../../../hooks/useFlex";
import { useUtils } from "../../../../hooks/useUtils";
import {
  AuctionState,
  getAuctionState,
  getMintMapping,
} from "../../../../utils/general";
import { EmptyMessage } from "../../../ClientSection/Option/EmptyMessage";
import { CooldownLabel } from "../../../ClientSection/Spot/styles";
import { Loader } from "../../../Loader";
import {
  Data,
  DataText,
  HeaderRow,
  Heading,
  HeadingText,
  Row,
  Table,
  TableBody,
  TableHead,
} from "../../../TableStyles";
import { TimeCountdown } from "../../../TimeCountdown";
import { LoaderContainer } from "../../Option/AuctionTable/styles";
import { AuctionTableContainer } from "./styles";

interface AuctionTableProps {
  selectedAuction: PublicKey | undefined;
  onSelect: (idx: PublicKey) => void;
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

const NUM_TABLE_COLUMNS = 5;

export const AuctionTable = ({
  selectedAuction,
  onSelect,
  className,
}: AuctionTableProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [cooldownTrigger, setCooldownTrigger] = useState<{}>({});
  const flex = useFlex();
  const {
    convertNativeMintNumberToDecimal,
    convertNativeNumberToDecimal,
    getMultiplier,
    getTokenExchangeRate,
  } = useUtils();

  const bodyContent = useMemo(() => {
    if (!flex.isInitialized) {
      return (
        <LoaderContainer>
          <Loader />;
        </LoaderContainer>
      );
    } else {
      if (!flex.data.spotAuctions.length) {
        return (
          <EmptyMessage
            isAuction
            message={"There are currently no active spot auctions."}
          />
        );
      }

      return flex.data.spotAuctions.map((auction) => {
        const msTillExpiry = (auction.bidEnd - Flex.clockTimestamp) * 1000;
        const bidEndExpiryDate = new Date(Date.now() + msTillExpiry);
        const cooldownEndExpiryDate = new Date(auction.cooldownEnd * 1000);
        const state = getAuctionState(auction);

        const BidTimeRemaining = () => {
          switch (state) {
            case AuctionState.Live:
              return (
                <DataText>
                  <TimeCountdown
                    key={AuctionState.Live}
                    date={bidEndExpiryDate}
                    onComplete={() => setCooldownTrigger({})}
                  />
                </DataText>
              );
            case AuctionState.Cooldown:
              return (
                <>
                  <CooldownLabel>Cooldown</CooldownLabel>
                  <DataText>
                    <TimeCountdown
                      key={AuctionState.Cooldown}
                      date={cooldownEndExpiryDate}
                      onComplete={() => setCooldownTrigger({})}
                    />
                  </DataText>
                </>
              );
            default:
              return null;
          }
        };

        const counterparty = auction.creator.toString();
        const underlyingStr: string = getMintMapping(auction.auctionTokenMint);
        const bidCurrency = getMintMapping(auction.bidCurrencyMint);
        const bidCurrencyStr =
          bidCurrency === "11111111111111111111111111111111"
            ? "Any"
            : bidCurrency;
        const sizeInUnderlying = convertNativeMintNumberToDecimal(
          auction.amount,
          auction.auctionTokenMint
        );

        return (
          <Row
            key={auction.address.toString()}
            onClick={() => onSelect(auction.address)}
            selected={
              selectedAuction && auction.address.equals(selectedAuction)
            }
            columns={NUM_TABLE_COLUMNS}
            selectable={!selectedAuction?.equals(auction.address)}
          >
            {/* Counterparty */}
            <Data>
              <DataText title={counterparty}>{counterparty}</DataText>
            </Data>
            {/* Underlying */}
            <Data>
              <DataText title={underlyingStr}>{underlyingStr}</DataText>
            </Data>
            {/* Bid Currency */}
            <Data>
              <DataText title={bidCurrencyStr}>{bidCurrencyStr}</DataText>
            </Data>
            {/* Size in Underlying */}
            <Data>
              <DataText
                title={sizeInUnderlying ? sizeInUnderlying.toString() : "-"}
              >
                {sizeInUnderlying}
              </DataText>
            </Data>
            {/* Bid Time Remaining */}
            <Data>
              <DataText>
                <BidTimeRemaining />
              </DataText>
            </Data>
          </Row>
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    flex.isInitialized,
    flex.data?.spotAuctions,
    getMultiplier,
    convertNativeNumberToDecimal,
    getMintMapping,
    convertNativeMintNumberToDecimal,
    selectedAuction,
    getTokenExchangeRate,
    onSelect,
    cooldownTrigger,
  ]);

  return (
    <AuctionTableContainer className={className}>
      <Table>
        <TableHead>
          <HeaderRow selected={false} columns={NUM_TABLE_COLUMNS}>
            <Heading>
              <HeadingText title="Counterparty">COUNTERPARTY</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Auction Token">AUCTION TOKEN</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Bid Currency">BID CURRENCY</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Quantity">QUANTITY</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Bid Time Remaining">
                BID TIME REMAINING
              </HeadingText>
            </Heading>
          </HeaderRow>
        </TableHead>
        <TableBody>{bodyContent}</TableBody>
      </Table>
    </AuctionTableContainer>
  );
};
