import { PublicKey } from "@solana/web3.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFlex } from "../../hooks/useFlex";
import {
  AppContainer,
  OptionAuctionSection,
  SpotAuctionSection,
  StyledAuctionHeader,
  StyledOptionClientSection,
  StyledOptionPurchaseSection,
  StyledSpotClientSection,
  StyledSpotPurchaseSection,
} from "./styles";

export enum AUCTION_TYPE {
  OPTION,
  SPOT,
}

export const AuctionInterface = () => {
  const [selectedAuction, setSelectedAuction] = useState<
    PublicKey | undefined
  >();
  const [auctionType, setAuctionType] = useState<AUCTION_TYPE>(
    AUCTION_TYPE.OPTION
  );

  const handleAuctionType = (newAuction: AUCTION_TYPE) => {
    setAuctionType(newAuction);
  };

  const flex = useFlex();

  const auctions = useMemo(() => {
    if (auctionType === AUCTION_TYPE.OPTION) {
      return flex.data?.optionAuctions || [];
    }
    return flex.data?.spotAuctions || [];
  }, [auctionType, flex.data?.optionAuctions, flex.data?.spotAuctions]);

  useEffect(() => {
    if (!flex.isInitialized || !auctions[0]) return;

    if (!selectedAuction) return setSelectedAuction(auctions[0].address);

    if (!auctions.find((a) => a.address.equals(selectedAuction))) {
      if (selectedAuction.equals(auctions[0].address)) return;
      // Only update if the addresses are different

      setSelectedAuction(auctions[0].address);
    }
  }, [auctions, flex.isInitialized, selectedAuction]);

  useEffect(() => {
    if (!auctions[0]) {
      setSelectedAuction(undefined);
    }
  }, [auctionType, auctions]);

  const handleAuctionSelect = useCallback(
    (addr: PublicKey) => {
      if (selectedAuction?.equals(addr)) return;
      // Only update if the addresses are different

      setSelectedAuction(addr);
    },
    [selectedAuction]
  );

  const isSpot = auctionType === AUCTION_TYPE.SPOT;

  return (
    <AppContainer showPurchase={selectedAuction !== undefined}>
      <StyledAuctionHeader
        currentAuction={auctionType}
        handleAuctionType={handleAuctionType}
      />
      {isSpot ? (
        <>
          <SpotAuctionSection
            selectedAuction={selectedAuction}
            onAuctionSelect={handleAuctionSelect}
          />
          <StyledSpotClientSection />
          <StyledSpotPurchaseSection selectedAuction={selectedAuction} />
        </>
      ) : (
        <>
          <OptionAuctionSection
            selectedAuction={selectedAuction}
            onAuctionSelect={handleAuctionSelect}
          />
          <StyledOptionClientSection />
          <StyledOptionPurchaseSection selectedAuction={selectedAuction} />
        </>
      )}
    </AppContainer>
  );
};
