import { Links } from "../../constants";
import { Paragraph } from "../Text";
import { FooterContainer } from "./styles";

export const Footer = () => {
  return (
    <FooterContainer>
      <a href={Links.GITBOOK} target="blank">
        <Paragraph bold color="highlight">
          Learn
        </Paragraph>
      </a>
      <a href={Links.SUPPORT} target="blank">
        <Paragraph bold color="highlight">
          Support
        </Paragraph>
      </a>
      <a href={Links.ANNOUNCEMENTS} target="blank">
        <Paragraph bold color="highlight">
          Auction Announcements
        </Paragraph>
      </a>
    </FooterContainer>
  );
};
