import { useEffect, useMemo, useState } from "react";
import { flexTypes } from "@zetamarkets/flex-sdk";
import {
  InputColumn,
  OptionsGeneratorInput,
  Label,
  ComboInputRow,
} from "./styles";
import { Paragraph } from "../../components/Text";
import { Kind } from "@zetamarkets/flex-sdk/dist/flex/types";
import CrossSVG from "../../assets/images/circleCross.svg";
import { ButtonToggle } from "../../components/ButtonToggle";
import { useUtils } from "../../hooks/useUtils";
import { useFlex } from "../../hooks/useFlex";
import { PublicKey } from "@solana/web3.js";

type ComboOptionRowProps = {
  listKey: number;
  optionNumber: number;
  handleComboOptionChange: (
    optionNumber: number,
    newOption: flexTypes.ComboOptionParams
  ) => void;
  handleDeleteComboOption: (optionNumber: number) => void;
  showCross: boolean;
  collateralMint: PublicKey;
};
export const ComboOptionRow = ({
  listKey,
  optionNumber,
  handleComboOptionChange,
  handleDeleteComboOption,
  showCross,
  collateralMint,
}: ComboOptionRowProps) => {
  const { getOptionPrecision } = useUtils();
  const flex = useFlex();

  const [strike, setStrike] = useState<string>("");
  const [optionAmount, setOptionAmount] = useState<string>("");
  const [optionType, setOptionType] = useState<"Call" | "Put">("Call");

  const precision = useMemo(() => {
    const fallback = 6;
    if (!flex.isInitialized) return fallback;

    return getOptionPrecision(collateralMint) ?? fallback;
  }, [getOptionPrecision, flex.isInitialized]);

  useEffect(() => {
    handleComboOptionChange(listKey, {
      strike: parseFloat(strike),
      kind: optionType === "Call" ? Kind.CALL : Kind.PUT,
      size: parseFloat(optionAmount),
    });
  }, [strike, optionAmount, optionType, listKey]);

  return (
    <ComboInputRow>
      <Paragraph bold color="subtext">
        Option {optionNumber}
      </Paragraph>
      <InputColumn>
        <Label color="subtext" bold>
          QUANTITY OF OPTIONS
        </Label>
        <OptionsGeneratorInput
          min="0"
          step="0.001"
          fixedDecimalScale={true}
          decimalScale={3}
          placeholder="0.00"
          onChange={(event) => {
            setOptionAmount(event.target.value);
          }}
          value={optionAmount}
        />
      </InputColumn>
      <InputColumn>
        <Label color="subtext" bold>
          STRIKE
        </Label>
        <OptionsGeneratorInput
          placeholder="0.00"
          allowNegative={false}
          fixedDecimalScale={true}
          decimalScale={precision}
          onChange={(event) => {
            setStrike(event.target.value);
          }}
          value={strike}
        />
      </InputColumn>
      <InputColumn>
        <Label color="subtext" bold>
          OPTION TYPE
        </Label>
        <ButtonToggle
          colorLeft={"product"}
          colorRight={"negative"}
          inverse
          labelLeft={"Call"}
          labelRight={"Put"}
          onClickLeft={() => {
            setOptionType("Call");
          }}
          onClickRight={() => {
            setOptionType("Put");
          }}
          selected={optionType}
        />
      </InputColumn>
      {showCross && (
        <img
          src={CrossSVG}
          onClick={() => {
            handleDeleteComboOption(listKey);
          }}
        />
      )}
    </ComboInputRow>
  );
};
