import create from "zustand";
import { devtools } from "zustand/middleware";

type NotificationStore = {
  notifications: Notification[];
  setNotifications: (val: Notification[]) => void;
  popNotifications: () => void;
  notify: (
    val: Notification,
    showLoader?: boolean
  ) => (val: Notification) => void;
};

export const useNotificationStore = create<NotificationStore>(
  devtools(
    (set, get) => ({
      notifications: [],
      setNotifications: (val) => set({ notifications: val }),
      popNotifications: () => {
        const notifications = get().notifications;
        const removeIndex = notifications.findIndex(
          (noti) => noti.type !== NotificationType.LOADING
        );

        if (removeIndex === -1) return;
        const newNotifications = notifications.filter(
          (_, index) => index !== removeIndex
        );
        set({
          notifications: [...newNotifications],
        });
      },
      notify: (val: Notification, showLoader?: boolean) => {
        const ts = new Date().getTime();
        const type = showLoader
          ? NotificationType.LOADING
          : NotificationType.SUCCESS;

        set({
          notifications: [
            ...get().notifications,
            { type: type, ts: ts, ...val },
          ],
        });

        return (val: Notification) => {
          let existingNotifications = get().notifications.filter(
            (noti) => noti.ts !== ts
          );
          existingNotifications = [
            ...existingNotifications,
            {
              ...val,
              type: val.type || NotificationType.SUCCESS,
              ts: new Date().getTime(),
            },
          ];
          set({ notifications: existingNotifications });
        };
      },
    }),
    { name: "notification" }
  )
);

export type Notification = {
  ts?: number;
  type?: NotificationType;
  title: string;
  description?: string;
  txid?: string;
  error?: string;
  orderId?: string[];
  // Zeta prog error code
  code?: number;
  msg?: string;
};

export enum NotificationType {
  SUCCESS,
  ERROR,
  WARNING,
  INFO,
  LOADING,
}
