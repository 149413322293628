import { Subtitle } from "../../Text";
import styled from "styled-components";

export const BlindAuction = () => {
  return (
    <BlindAuctionContainer>
      <BlindAuctionText bold>BLIND AUCTION</BlindAuctionText>
    </BlindAuctionContainer>
  );
};
const BlindAuctionContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;

  border-top: ${(props) => `1px solid ${props.theme.background.table}`};
  border-bottom: ${(props) => `1px solid ${props.theme.background.table}`};
  width: 100%;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
`;
const BlindAuctionText = styled(Subtitle)`
  color: ${(props) => props.theme.font.subtext};
`;
