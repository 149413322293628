import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { FlexClient } from "@zetamarkets/flex-sdk";
import { Wallet } from "@zetamarkets/flex-sdk/dist/common/types";
import { Bid, Position } from "@zetamarkets/flex-sdk/dist/flex/types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getSolanaConnection } from "../utils/connection";
import { envVars } from "../utils/envVars";
import { AuctionState, getAuctionState } from "../utils/general";
import { useFlex } from "./useFlex";
import { useUtils } from "./useUtils";
import {
  PUBKEY_TO_UNDERLYINGS_MAP,
  UNDERLYINGS_TO_PUBKEY_MAP,
} from "@zetamarkets/flex-sdk/dist/common/constants";

type Token = {
  underlying: string;
  pubkey: string;
};

type ClientState = {
  bids: Bid[];
  expiredPositions: Position[];
  livePositions: Position[];
  client: FlexClient;
  walletTokens: string[];
  whitelistedWalletTokens: Token[];
  updateClient: () => void;
};

type ClientContextType =
  | { isInitialized: false; data?: never }
  | { isInitialized: true; data: ClientState };

export const useSetupClient = (): ClientContextType => {
  const [client, setClient] = useState<FlexClient>();
  const [clientInterval, setClientInterval] = useState<NodeJS.Timer>();
  const [bids, setBids] = useState<Bid[]>([]);
  const [whitelistedWalletTokens, setWhitelistedWalletTokens] = useState<
    Token[]
  >([]);
  const [walletTokens, setWalletTokens] = useState<string[]>([]);
  const [expiredPositions, setExpiredPositions] = useState<Position[]>([]);
  const [livePositions, setLivePositions] = useState<Position[]>([]);
  const wallet = useSafeWallet();
  const flex = useFlex();
  const { getOptionAuction } = useUtils();

  useEffect(() => {
    if (!client) return;
    const mappedArray = [
      {
        underlying: "SOL",
        pubkey:
          envVars.VITE_NETWORK_TYPE === "devnet"
            ? UNDERLYINGS_TO_PUBKEY_MAP.devnet["SOL"]
            : UNDERLYINGS_TO_PUBKEY_MAP.mainnet["SOL"],
      },
    ];
    const allTokens = client.tokenAccounts;

    const allWalletMints = allTokens
      ?.filter((t) => {
        return parseInt(t.amount.toString()) > 1;
      })
      .map((t) => {
        return t.mint.toString();
      });

    setWalletTokens(
      [
        envVars.VITE_NETWORK_TYPE === "devnet"
          ? UNDERLYINGS_TO_PUBKEY_MAP.devnet["SOL"]
          : UNDERLYINGS_TO_PUBKEY_MAP.mainnet["SOL"],
      ].concat(allWalletMints)
    );

    const filteredArray = Object.keys(PUBKEY_TO_UNDERLYINGS_MAP.mainnet).filter(
      (value) => allWalletMints.includes(value)
    );
    const mappedFilteredArray = filteredArray.map(
      (key: keyof typeof PUBKEY_TO_UNDERLYINGS_MAP.mainnet) => {
        return {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          underlying: PUBKEY_TO_UNDERLYINGS_MAP.mainnet[key],
          pubkey: key,
        };
      }
    );
    setWhitelistedWalletTokens(mappedArray.concat(mappedFilteredArray));
  }, [client]);

  useEffect(() => {
    if (!wallet.publicKey) return;
    if (!flex.isInitialized) return;

    FlexClient.load(getSolanaConnection(), wallet as Wallet)
      .then((client) => {
        setClient(client);
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.publicKey, flex.isInitialized]);

  useEffect(() => {
    if (!wallet.connected) {
      setClient(undefined);
      clientInterval && clearInterval(clientInterval);
    }
  }, [clientInterval, wallet.connected]);

  const setClientState = useCallback(() => {
    if (!client || !flex.isInitialized) return;
    client
      .updateState()
      .then(() => {
        const sortedPositions = client
          .getLivePositions()[0]
          .sort((a, b) =>
            a.expiry < b.expiry ? -1 : a.expiry > b.expiry ? 1 : 0
          );

        const sortedBids = client.bids.sort((a, b) =>
          a.bidEnd < b.bidEnd ? -1 : a.bidEnd > b.bidEnd ? 1 : 0
        );

        const cooldownIndex = sortedBids.findIndex((b) => {
          const auction = getOptionAuction(b.auctionAccount);

          if (!auction) return false;

          const auctionState = getAuctionState(auction);

          return auctionState === AuctionState.Cooldown;
        });

        const expiredIndex = sortedBids.findIndex((b) => {
          const auction = getOptionAuction(b.auctionAccount);

          if (!auction) return false;

          const auctionState = getAuctionState(auction);

          return auctionState === AuctionState.Expired;
        });

        const liveAuctions =
          cooldownIndex !== -1
            ? sortedBids.slice(0, cooldownIndex)
            : expiredIndex !== -1
            ? sortedBids.slice(0, expiredIndex)
            : sortedBids;

        const coolingAuctions =
          cooldownIndex === -1
            ? []
            : sortedBids.slice(cooldownIndex, expiredIndex);

        const expiredAuctions =
          expiredIndex === -1 ? [] : sortedBids.slice(expiredIndex);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const formattedBids = [
          ...liveAuctions,
          ...expiredAuctions,
          ...coolingAuctions,
        ];

        setBids(sortedBids);
        setLivePositions(sortedPositions);
        setExpiredPositions(client.getExpiredPositions()[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [client, flex.isInitialized, getOptionAuction]);

  useEffect(() => {
    if (!client || !flex.isInitialized) return;

    setClientState();
    const interval = setInterval(() => {
      setClientState();
    }, parseInt(envVars.VITE_FLEX_UPDATE_INTERVAL));

    setClientInterval(interval);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, flex.isInitialized]);

  if (!client) return { isInitialized: false };

  return {
    isInitialized: true,
    data: {
      bids,
      client,
      livePositions,
      expiredPositions,
      walletTokens,
      whitelistedWalletTokens,
      updateClient: setClientState,
    },
  };
};

export const useClient = (): ClientContextType => {
  const client = useContext(ClientContext);

  return client;
};

export const ClientContext = createContext<ClientContextType>({
  isInitialized: false,
});
