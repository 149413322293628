import styled from "styled-components";

export const AppContainer = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background: ${(props) => props.theme.background.secondary};
  display: flex;
  flex-direction: column;
`;
