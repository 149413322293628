import { Flex } from "@zetamarkets/flex-sdk";
import Big from "big.js";
import { useMemo } from "react";
import styled from "styled-components";
import { useClient } from "../../hooks/useClient";
import { useFlex } from "../../hooks/useFlex";
import {
  capitalizeFirstLetter,
  getFormattedExpiry,
  getMintMapping,
} from "../../utils/general";
import { ClientSectionViewContainer, DashboardEmptyMessage } from "./styles";
import {
  Row,
  Data,
  DataText,
  Table,
  TableHead,
  Heading,
  HeadingText,
  TableBody,
  HeaderRow,
} from "../TableStyles";
import { useUtils } from "../../hooks/useUtils";
import {
  FlexOptionType,
  Option,
  Position,
  SettlementType,
} from "@zetamarkets/flex-sdk/dist/flex/types";
import { Subtitle } from "../Text";
import { Accordion } from "../Accordion";
import { PublicKey } from "@solana/web3.js";
import BN from "bn.js";

export const Options = () => {
  const client = useClient();
  const flex = useFlex();
  const {
    convertNativeMintNumberToDecimal,
    convertNativeNumberToDecimal,
    getMultiplier,
    getTokenExchangeRate,
  } = useUtils();

  const positions = useMemo(() => {
    if (!client.isInitialized || !flex.isInitialized) return null;

    if (
      !client.data.client.optionPositions.length &&
      !client.data.client.comboOptionPositions.length
    ) {
      return (
        <DashboardEmptyMessage message={"There are no option positions."} />
      );
    }

    const convertToOptionRow = (positions: Position[], isCombo?: boolean) => {
      return positions.map((pos) => {
        const optionInfo = Flex.getOption(
          pos.optionAccount,
          isCombo ? FlexOptionType.Combo : FlexOptionType.Normal
        );
        if (optionInfo == null) return null;
        const multiplier = getMultiplier(optionInfo.optionMint);
        const putMultiplier = isCombo
          ? 1
          : 1 /
            (convertNativeNumberToDecimal(
              (optionInfo as Option).strike.toNumber()
            ) *
              10);

        const underlyingCurrency = getMintMapping(optionInfo.underlyingMint);
        const collateralCurrency = getMintMapping(optionInfo.collateralMint);

        const exerciseCurrency =
          optionInfo.settlementType === SettlementType.PHYSICAL
            ? getMintMapping((optionInfo as Option).exerciseMint)
            : "-";

        const kind = isCombo
          ? "-"
          : capitalizeFirstLetter(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              (optionInfo as Option).kind
            );

        const strike = isCombo
          ? "-"
          : optionInfo.settlementType === SettlementType.PHYSICAL
          ? convertNativeMintNumberToDecimal(
              (optionInfo as Option).strike.toNumber(),
              (optionInfo as Option).exerciseMint
            )
          : convertNativeNumberToDecimal(
              (optionInfo as Option).strike.toNumber()
            );

        const sizeInUnderlying = isCombo
          ? pos.amount
          : (optionInfo as Option).kind == "call" && multiplier
          ? Big(pos.amount).mul(multiplier).toNumber()
          : pos.amount * putMultiplier;

        const exercisableStartDate = getFormattedExpiry(
          new Date(optionInfo.settlementStart * 1000).getTime() / 1000,
          "exercisable-options-expiry"
        );

        const expiryDate = getFormattedExpiry(
          new Date(optionInfo.expiry * 1000).getTime() / 1000,
          "exercisable-options-expiry"
        );

        const settlementType =
          optionInfo.settlementType === SettlementType.CASH
            ? "Cash"
            : optionInfo.settlementType === SettlementType.PHYSICAL
            ? "Physical"
            : "Undefined";

        const optionType = isCombo ? "Combo" : "Normal";
        return (
          <Row
            columns={10}
            key={pos.optionAccount.toString()}
            selectable={isCombo}
          >
            {/* Option Type */}
            <Data>
              <DataText title={optionType}>{optionType}</DataText>
            </Data>
            {/* Underlying */}
            <Data>
              <DataText title={underlyingCurrency}>
                {underlyingCurrency}
              </DataText>
            </Data>
            {/* Collateral */}
            <Data>
              <DataText title={collateralCurrency}>
                {collateralCurrency}
              </DataText>
            </Data>
            {/* Settlement Type */}
            <Data>
              <DataText title={settlementType}>{settlementType}</DataText>
            </Data>
            {/* Exercise Currency */}
            <Data>
              <DataText title={exerciseCurrency}>{exerciseCurrency}</DataText>
            </Data>
            {/* Instrument */}
            <Data>
              <DataText title={kind}>{kind}</DataText>
            </Data>
            {/* Strike */}
            <Data>
              <DataText title={!strike ? "-" : strike.toString()}>
                {strike}
              </DataText>
            </Data>
            {/* Size */}
            <Data>
              <DataText title={sizeInUnderlying.toString()}>
                {sizeInUnderlying}
              </DataText>
            </Data>
            {/* Exercisable time */}
            <Data>
              <DataText title={exercisableStartDate.toString()}>
                {exercisableStartDate}
              </DataText>
            </Data>
            {/* Expiration time */}
            <Data>
              <DataText title={expiryDate.toString()}>{expiryDate}</DataText>
            </Data>
          </Row>
        );
      });
    };

    const comboPositions = client.data.client.comboOptionPositions.map(
      (pos) => {
        const comboPositionRow = {
          optionAccount: pos.comboOptionAccount,
          amount: pos.amount,
          strike: new BN(0),
          expiry: pos.expiry,
        };

        const optionInfo = Flex.getOption(
          pos.comboOptionAccount,
          FlexOptionType.Combo
        );

        const comboParams = (
          <Row columns={10} key={pos.comboOptionAccount.toString()}>
            {/* Option Type */}
            <Data>
              <Subtitle>Combo option parameters</Subtitle>
            </Data>
            {/* Underlying */}
            <Data></Data>
            {/* Settlement Type */}
            <Data></Data>
            {/* Exercise Currency */}
            <Data></Data>
            {/* Instrument */}
            <Data>
              <DataText>
                {pos.params.map((params, index) => {
                  return (
                    <Subtitle key={index}>
                      {capitalizeFirstLetter(params.kind)}
                    </Subtitle>
                  );
                })}
              </DataText>
            </Data>
            {/* Strike */}
            <Data>
              <DataText>
                {pos.params.map((params, index) => {
                  return (
                    <Subtitle key={index}>
                      {convertNativeMintNumberToDecimal(
                        params.strike,
                        optionInfo?.collateralMint || new PublicKey("")
                      )}
                    </Subtitle>
                  );
                })}
              </DataText>
            </Data>
            {/* Size */}
            <Data>
              <DataText>
                {pos.params.map((params, index) => {
                  return <Subtitle key={index}>{params.size / 1000}x</Subtitle>;
                })}
              </DataText>
            </Data>
            {/* Exercisable time */}
            <Data></Data>
            {/* Expiration time */}
            <Data></Data>
          </Row>
        );
        // });

        return (
          <Accordion
            key={pos.comboOptionAccount.toString()}
            headerChild={
              convertToOptionRow([comboPositionRow], true)[0] || (
                <Subtitle>Cooked</Subtitle>
              )
            }
            bodyChild={comboParams}
            defaultOpen
          />
        );
      }
    );

    const normalOptionRows = convertToOptionRow(
      client.data.client.optionPositions
    );

    return normalOptionRows.concat(comboPositions);
  }, [
    client.data?.livePositions,
    client.isInitialized,
    convertNativeMintNumberToDecimal,
    convertNativeNumberToDecimal,
    flex.isInitialized,
    getMultiplier,
    getTokenExchangeRate,
  ]);

  const positionsOutput = useMemo(() => {
    if (
      !client.data?.client.optionPositions.length &&
      !client.data?.client.comboOptionPositions.length
    ) {
      return (
        <DashboardEmptyMessage message={"There are no option positions."} />
      );
    }
    return <TableBody>{positions}</TableBody>;
  }, [client.data, positions]);

  return (
    <PositionsContainer>
      <Table>
        <TableHead>
          <HeaderRow columns={10}>
            <Heading>
              <HeadingText title={"Option Type"}>OPTION TYPE</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Underlying"}>UNDERLYING</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Collateral"}>COLLATERAL</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Settlement Type"}>
                SETTLEMENT TYPE
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Exercise Currency"}>
                EXERCISE CURRENCY
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Instrument"}>INSTRUMENT</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Strike"}>STRIKE</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Size"}>SIZE</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Exercisable time"}>
                EXERCISABLE TIME
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Expiration time"}>
                EXPIRATION TIME
              </HeadingText>
            </Heading>
          </HeaderRow>
        </TableHead>
        {positionsOutput}
      </Table>
    </PositionsContainer>
  );
};
const PositionsContainer = styled(ClientSectionViewContainer)``;
