import { Flex } from "@zetamarkets/flex-sdk";
import { useCallback, useEffect, useState } from "react";
import { useClient } from "../../hooks/useClient";
import { useFlex } from "../../hooks/useFlex";
import {
  getFormattedExpiry,
  getMintMapping,
  shortenAddress,
} from "../../utils/general";
import { DashboardEmptyMessage, ExpiredContainer } from "./styles";
import {
  Row,
  Data,
  DataText,
  Table,
  TableHead,
  Heading,
  HeadingText,
  TableBody,
  DataTextLink,
  HeaderRow,
  ButtonData,
} from "../TableStyles";
import {
  NotificationType,
  useNotificationStore,
} from "../../stores/useNotificationStore";
import { BN, ProgramError } from "@project-serum/anchor";
import { useUtils } from "../../hooks/useUtils";
import {
  FlexOptionType,
  Option,
  SettlementType,
} from "@zetamarkets/flex-sdk/dist/flex/types";
import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { getAccount } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { useMemo } from "react";
import { useSnowflakeSafe } from "../../hooks/useSnowflake";
import { ClientButton } from "../ClientSection/Option/styles";
import Big from "big.js";

type GeneralOptionInfo = {
  optionMint: PublicKey;
  optionAccount: PublicKey;
  underlying: PublicKey;
  expiry: number;
  remainingCollateral: number;
  collateralMint: PublicKey;
  settlementType: SettlementType;
  vault: PublicKey;
  optionType: FlexOptionType;
  amount: number;
};

export const UserCreatedOptions = () => {
  const client = useClient();
  const flex = useFlex();
  const {
    convertNativeMintNumberToDecimal,
    convertNativeNumberToDecimal,
    getMultiplier,
    getTokenExchangeRate,
  } = useUtils();
  const wallet = useSafeWallet();
  const { createProposal } = useSnowflakeSafe();

  const notify = useNotificationStore((state) => state.notify);

  const [userCreatedOptions, setUserCreatedOptions] = useState<
    JSX.Element[] | undefined
  >([]);

  const handleSettle = useCallback(
    (optionAccount: PublicKey, optionType: FlexOptionType) => {
      if (client.data === undefined || optionAccount === undefined) return;
      const callback = notify(
        {
          title: "Collecting remaining collateral",
        },
        true
      );
      client.data.client
        .collectRemainingCollateral(optionAccount, optionType, wallet.isSafeApp)
        .then(async (returnData) => {
          void client.data.client.updateState();
          await createProposal("Collect Remaining Collateral", returnData);
          callback({
            title: "Successfully collected remaining collateral",
            type: NotificationType.SUCCESS,
          });
        })
        .catch((err) => {
          console.error(err);
          if (err instanceof ProgramError)
            callback({
              title: "Failed to collect remaining collateral",
              type: NotificationType.ERROR,
              description: err.msg,
              code: err.code,
            });
          else if (err instanceof Error)
            callback({
              title: "Failed to collect remaining collateral",
              type: NotificationType.ERROR,
            });
        });
    },
    [client, notify]
  );

  const handleBurnOption = useCallback(
    (amount: BN, optionAccount: PublicKey, optionType: FlexOptionType) => {
      if (client.data === undefined || optionAccount === undefined) return;
      const callback = notify(
        {
          title: "Burning option",
        },
        true
      );
      client.data.client
        .burnOption(amount, optionAccount, optionType, wallet.isSafeApp)
        .then(async (returnData) => {
          void client.data.client.updateState();
          await createProposal("Burn option", returnData);
          callback({
            title: "Successfully burned option",
            type: NotificationType.SUCCESS,
          });
        })
        .catch((err) => {
          console.error(JSON.stringify(err));
          if (err instanceof ProgramError)
            callback({
              title: "Failed to burn option",
              type: NotificationType.ERROR,
              description: err.msg,
              code: err.code,
            });
          else if (err instanceof Error)
            callback({
              title: "Failed to burn option",
              type: NotificationType.ERROR,
            });
        });
    },
    [client, notify]
  );

  const userCreatedOptionsOutput = useMemo(() => {
    if (!userCreatedOptions || !userCreatedOptions.length) {
      return (
        <DashboardEmptyMessage message={"There are no user created options."} />
      );
    }
    return <TableBody>{userCreatedOptions}</TableBody>;
  }, [userCreatedOptions]);

  useEffect(() => {
    const fetchNormalOptions = async () => {
      if (
        !client.isInitialized ||
        !flex.isInitialized ||
        !wallet.connected ||
        !wallet.publicKey ||
        !client.data
      )
        setUserCreatedOptions([]);

      const normalOptions: GeneralOptionInfo[] = Flex.getOptionsByCreator(
        wallet.publicKey!,
        FlexOptionType.Normal
      ).map((option) => {
        const optionPosition = client.data!.client.optionPositions.find((pos) =>
          pos.optionAccount.equals(option.optionAccount)
        );
        return {
          optionMint: option.optionMint,
          optionAccount: option.optionAccount,
          underlying: option.underlyingMint,
          expiry: option.expiry,
          remainingCollateral: option.remainingCollateral,
          collateralMint: option.collateralMint,
          settlementType: option.settlementType,
          vault: option.vault,
          optionType: FlexOptionType.Normal,
          amount: optionPosition?.amount || 0,
        };
      });
      const comboOptions: GeneralOptionInfo[] = Flex.getOptionsByCreator(
        wallet.publicKey!,
        FlexOptionType.Combo
      ).map((option) => {
        const optionPosition = client.data!.client.comboOptionPositions.find(
          (pos) => pos.comboOptionAccount.equals(option.optionAccount)
        );
        return {
          optionMint: option.optionMint,
          optionAccount: option.optionAccount,
          underlying: option.underlyingMint,
          expiry: option.expiry,
          remainingCollateral: option.remainingCollateral,
          collateralMint: option.collateralMint,
          settlementType: option.settlementType,
          vault: option.vault,
          optionType: FlexOptionType.Combo,
          amount: optionPosition?.amount || 0,
        };
      });

      const options = await Promise.all(
        normalOptions
          .concat(comboOptions)
          .sort((a, b) => {
            return b.expiry - a.expiry;
          })
          .map(async (option: GeneralOptionInfo) => {
            const optionType =
              option.optionType === FlexOptionType.Combo ? "Combo" : "Normal";
            const underlying = getMintMapping(option.underlying);
            const settlementType =
              option.settlementType === SettlementType.CASH
                ? "Cash"
                : "Physical";
            let remainingCollateral =
              Flex.clockTimestamp < option.expiry
                ? "-"
                : convertNativeMintNumberToDecimal(
                    option.remainingCollateral,
                    option.collateralMint
                  ) || 0;
            const collateralMint = getMintMapping(option.collateralMint);
            const expiry = getFormattedExpiry(
              option.expiry,
              "exercisable-options-expiry"
            );

            if (option.settlementType === SettlementType.PHYSICAL) {
              let optionVaultInfo;
              try {
                optionVaultInfo = await getAccount(
                  Flex.provider.connection,
                  option.vault
                );
              } catch (e) {
                optionVaultInfo = null;
              }
              remainingCollateral = !optionVaultInfo
                ? 0
                : convertNativeMintNumberToDecimal(
                    Number(optionVaultInfo.amount),
                    option.collateralMint
                  ) || 0;
            }
            const tokenAddress = option.optionMint.toString();
            const shortenedTokenAddress = shortenAddress(
              option.optionMint.toString()
            );
            const explorerLink = `https://solana.fm/address/${option.optionMint.toString()}?cluster=mainnet-qn1`;

            const collectCollateralButtonStr =
              remainingCollateral === 0
                ? "No remaining collateral"
                : typeof remainingCollateral === "string"
                ? "Cannot collect collateral yet"
                : "Collect remaining collateral";

            const optionInfo = Flex.getOption(
              option.optionAccount,
              option.optionType
            );
            if (optionInfo == null) return <Row columns={10} />;

            const multiplier = getMultiplier(optionInfo.optionMint);
            const putMultiplier =
              option.optionType === FlexOptionType.Combo
                ? 1
                : 1 /
                  (convertNativeNumberToDecimal(
                    (optionInfo as Option).strike.toNumber()
                  ) *
                    10);

            const sizeInUnderlying =
              option.optionType === FlexOptionType.Combo
                ? option.amount
                : (optionInfo as Option).kind == "call" && multiplier
                ? Big(option.amount).mul(multiplier).toNumber()
                : option.amount * putMultiplier;

            return (
              <Row columns={10} key={option.optionMint.toString()}>
                {/* Option Type */}
                <Data>
                  <DataText title={optionType}>{optionType}</DataText>
                </Data>
                {/* Token address */}
                <Data>
                  <DataTextLink
                    title={tokenAddress}
                    color="highlight"
                    target="_blank"
                    href={explorerLink}
                  >
                    {shortenedTokenAddress}
                  </DataTextLink>
                </Data>
                {/* Owned token amount */}
                <Data>
                  <DataText title={sizeInUnderlying.toString()}>
                    {sizeInUnderlying}
                  </DataText>
                </Data>
                {/* Underlying */}
                <Data>
                  <DataText title={underlying}>{underlying}</DataText>
                </Data>
                {/* Settlement Type */}
                <Data>
                  <DataText title={settlementType}>{settlementType}</DataText>
                </Data>
                {/* Remaining Collateral */}
                <Data>
                  <DataText title={remainingCollateral.toString()}>
                    {remainingCollateral}
                  </DataText>
                </Data>
                {/* Collateral Mint */}
                <Data>
                  <DataText title={collateralMint}>{collateralMint}</DataText>
                </Data>
                {/* Expiry */}
                <Data>
                  <DataText title={expiry}>{expiry}</DataText>
                </Data>
                {/* Collect Collateral Button */}
                <ButtonData>
                  <DataText>
                    <ClientButton
                      larger
                      disabled={
                        !wallet.connected ||
                        Flex.clockTimestamp < option.expiry ||
                        !remainingCollateral
                      }
                      action
                      onClick={() => {
                        void handleSettle(
                          option.optionAccount,
                          option.optionType
                        );
                      }}
                      label={collectCollateralButtonStr}
                    />
                  </DataText>
                </ButtonData>
                {/* Burn Button */}
                <ButtonData>
                  <DataText>
                    <ClientButton
                      larger
                      title={
                        option.amount === 0
                          ? "You own no tokens."
                          : Flex.clockTimestamp > option.expiry
                          ? "Option has expired."
                          : ""
                      }
                      disabled={
                        option.amount === 0 ||
                        Flex.clockTimestamp > option.expiry
                      }
                      action
                      onClick={() => {
                        void handleBurnOption(
                          new BN(option.amount),
                          option.optionAccount,
                          option.optionType
                        );
                      }}
                      label={"Burn all owned option tokens"}
                    />
                  </DataText>
                </ButtonData>
              </Row>
            );
          })
      );
      setUserCreatedOptions(options);
    };

    void fetchNormalOptions();
  }, [
    client.isInitialized,
    client.data?.expiredPositions,
    client.data?.client,
    flex.isInitialized,
    convertNativeMintNumberToDecimal,
    getMultiplier,
    convertNativeNumberToDecimal,
    getTokenExchangeRate,
    notify,
  ]);

  return (
    <ExpiredContainer>
      <Table>
        <TableHead>
          <HeaderRow columns={10}>
            <Heading>
              <HeadingText title={"Option Type"}>OPTION TYPE</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Token Address"}>TOKEN ADDRESS</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Owned Token Amount"}>
                OWNED TOKEN AMOUNT
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Underlying"}>UNDERLYING</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Settlement Type"}>
                SETTLEMENT TYPE
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Remaining Collateral"}>
                REMAINING COLLATERAL
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Collateral Mint"}>
                COLLATERAL MINT
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Expiry"}>EXPIRY</HeadingText>
            </Heading>
          </HeaderRow>
        </TableHead>
        {userCreatedOptionsOutput}
      </Table>
    </ExpiredContainer>
  );
};
