import styled, { css } from "styled-components";
import { Links } from "../../constants";
import { Subtitle } from "../Text";

interface TabProps {
  /**
   * The selected tab
   */
  selected: TabType;
  /**
   * Callback that will be fired on tab change
   */
  onTabChange: (tab: TabType) => void;
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

export enum TabType {
  DEX,
  FLEX,
}

export const Tabs = ({ selected, onTabChange, className }: TabProps) => {
  return (
    <TabsContainer className={className}>
      <a href={Links.DEX}>
        <Tab
          onClick={() => onTabChange(TabType.DEX)}
          selected={selected === TabType.DEX}
          purple
        >
          <TabText>DEX</TabText>
        </Tab>
      </a>
      <Tab
        onClick={() => onTabChange(TabType.FLEX)}
        selected={selected === TabType.FLEX}
      >
        <TabText>FLEX</TabText>
      </Tab>
    </TabsContainer>
  );
};

const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  background: ${(props) => props.theme.background.secondary};
  border-radius: 4px;
  width: fit-content;
`;

const Tab = styled.div<{ selected: boolean; purple?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  height: 16px;
  border-radius: 4px;

  ${(props) =>
    props.selected &&
    css`
      background: ${props.theme.background.primary};
      * {
        color: ${props.purple
          ? props.theme.colors.primary.purple
          : props.theme.font.highlight};
      }
      border: ${props.purple
        ? `1px solid ${props.theme.colors.primary.purple}`
        : `1px solid ${props.theme.buttons.highlight}`};
    `};
  ${(props) =>
    !props.selected &&
    css`
      * {
        cursor: pointer;
      }
      color: ${props.theme.font.subtext};
      border: 1px solid transparent;

      &:hover {
        transition: 200ms;
        filter: brightness(1.2);
      }
    `};
`;

const TabText = styled(Subtitle).attrs(() => ({
  color: "subtext",
}))`
  font-weight: 600;
`;
