import { ReactNode } from "react";
import { ModalContainer, ModalContent } from "./styles";

interface ModalProps {
  /**
   * Triggers the opening and closing of the modal
   */
  isOpen: boolean;
  /**
   * Close the modal if clicked outside the modal conten
   */
  closeOnOverlayClick?: boolean;
  /**
   * Handler fired when modal is closed
   */
  onClose?: () => void;
  /**
   * z-index of Modal
   */
  zIndex?: number;
  /**
   * Nodes to render in modal
   */
  children?: ReactNode;
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

export const Modal = ({
  className,
  children,
  onClose,
  isOpen,
  zIndex,
  closeOnOverlayClick,
}: ModalProps) => {
  if (!isOpen) return null;
  return (
    <ModalContainer
      zIndex={zIndex}
      onClick={
        closeOnOverlayClick
          ? (e) => {
              e.stopPropagation();
              onClose && onClose();
            }
          : undefined
      }
    >
      <ModalContent className={className} onClick={(e) => e.stopPropagation()}>
        {children}
      </ModalContent>
    </ModalContainer>
  );
};
