import { Subtitle } from "../../Text";
import EmptyIcon from "../../../assets/images/empty.svg";
import AuctionIcon from "../../../assets/images/auction.svg";
import { UnavailableContainer } from "./styles";

type EmptyMessageProps = {
  message: string;
  isAuction?: boolean;
  className?: string;
};

export const EmptyMessage = ({
  className,
  message,
  isAuction,
}: EmptyMessageProps) => {
  return (
    <UnavailableContainer className={className}>
      <img src={isAuction ? AuctionIcon : EmptyIcon} />
      <Subtitle color="selectable" bold>
        {message}
      </Subtitle>
    </UnavailableContainer>
  );
};
