import { Flex } from "@zetamarkets/flex-sdk";
import { PublicKey } from "@solana/web3.js";
import Big from "big.js";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { useClient } from "../../../hooks/useClient";
import {
  AuctionState,
  capitalizeFirstLetter,
  getAuctionState,
  getFormattedExpiry,
  getMintMapping,
  getUnderlyingStr,
  shortenAddress,
} from "../../../utils/general";
import {
  ClientSectionViewContainer,
  ClientButton,
  CooldownLabel,
} from "./styles";
import {
  Row,
  Data,
  DataText,
  Table,
  TableHead,
  Heading,
  HeadingText,
  TableBody,
  HeaderRow,
  ButtonData,
} from "../../TableStyles";
import { TimeCountdown } from "../../TimeCountdown";
import { useFlex } from "../../../hooks/useFlex";
import {
  NotificationType,
  useNotificationStore,
} from "../../../stores/useNotificationStore";
import { ProgramError } from "@project-serum/anchor";
import { useUtils } from "../../../hooks/useUtils";
import {
  FlexOptionType,
  Option,
  SettlementType,
} from "@zetamarkets/flex-sdk/dist/flex/types";
import { envVars } from "../../../utils/envVars";
import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { useSnowflakeSafe } from "../../../hooks/useSnowflake";
import { EmptyMessage } from "./EmptyMessage";

const NUM_TABLE_COLUMNS =
  envVars.VITE_DISABLE_BLIND_AUCTIONS === "true" ? 17 : 16;

export const OpenBids = () => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [cooldownTrigger, setCooldownTrigger] = useState<{}>({});
  const client = useClient();
  const { createProposal } = useSnowflakeSafe();
  const wallet = useSafeWallet();
  const flex = useFlex();
  const notify = useNotificationStore((state) => state.notify);
  const {
    convertNativeMintNumberToDecimal,
    convertNativeNumberToDecimal,
    getMultiplier,
    getTokenExchangeRate,
  } = useUtils();

  const bids = useMemo(() => {
    if (!client.isInitialized || !flex.isInitialized) return null;

    const openOptionBids = client.data.bids.find((o) => o.optionAccount);
    if (!openOptionBids) {
      return <EmptyMessage message={"There are no open bids."} />;
    }

    return client.data.bids.map((bid) => {
      // Using flex auctions here for
      const auction = flex.data.optionAuctions.find((a) =>
        a.address.equals(bid.auctionAccount)
      );

      // auctions can be null when cancelled
      let optionInfo: Option;
      let bidCurrencyMint: PublicKey | null;
      if (auction) {
        optionInfo = auction.optionInfo;
        bidCurrencyMint = auction.bidCurrencyMint;
        if (bidCurrencyMint.toString() === "11111111111111111111111111111111") {
          bidCurrencyMint = bid.bidCurrencyMint;
        }
      } else {
        if (bid.optionAccount === null) {
          return null;
        }

        optionInfo = Flex.getOption(
          bid.optionAccount,
          FlexOptionType.Normal
        ) as Option;
        if (optionInfo == null) {
          return null;
        }

        bidCurrencyMint = bid.bidCurrencyMint;

        if (bidCurrencyMint === null) {
          return null;
        }
      }

      const bidCurrency = !bidCurrencyMint
        ? null
        : getMintMapping(bidCurrencyMint);
      const bidCurrencyTitle =
        bidCurrency === "11111111111111111111111111111111"
          ? bid.bidCurrencyMint?.toString()
          : bidCurrency || "-";

      const bidCurrencyStr =
        bidCurrencyTitle && bidCurrencyTitle.length > 10
          ? shortenAddress(bidCurrencyTitle)
          : bidCurrencyTitle;

      const premiumPerUnderlying = () => {
        if (!auction || !bidCurrencyMint) return "-";

        const bidAmount = convertNativeMintNumberToDecimal(
          bid.amount,
          bidCurrencyMint
        );
        if (!bidAmount) return "-";

        if (optionInfo.kind === "call" && multiplier) {
          return Big(bidAmount)
            .div(auction.amount * multiplier)
            .toNumber();
        } else {
          return Big(bidAmount)
            .div(auction.amount * putMultiplier)
            .toNumber();
        }
      };

      const state = auction ? getAuctionState(auction) : AuctionState.Cancelled;
      const bidEndExpiryDate = new Date(bid.bidEnd * 1000);
      const cooldownEndExpiryDate = new Date(bid.cooldownEnd * 1000);
      const multiplier = getMultiplier(optionInfo.optionMint);

      const bestBid = () => {
        if (!auction) {
          return undefined;
        } else if (auction.exchangeAmount) {
          return convertNativeMintNumberToDecimal(
            auction.exchangeAmount,
            auction.bidCurrencyMint
          );
        } else {
          return auction.bids.length
            ? convertNativeMintNumberToDecimal(
                auction.bids[0].amount,
                auction.bidCurrencyMint
              )
            : undefined;
        }
      };

      const cancelButtonText = () => {
        switch (state) {
          case AuctionState.Live:
            return "Cancel";
          case AuctionState.Cooldown:
            return auction?.exchangeAmount !== 0 ? "Cancel" : "Cooldown";
          case AuctionState.Expired:
            return "Cancel";
          case AuctionState.Cancelled:
            return "Cancel";
        }
      };

      const BidTimeRemaining = () => {
        switch (state) {
          case AuctionState.Live:
            return (
              <DataText>
                <TimeCountdown
                  key={AuctionState.Live}
                  date={bidEndExpiryDate}
                  onComplete={() =>
                    setInterval(() => setCooldownTrigger({}), 5000)
                  }
                />
              </DataText>
            );
          case AuctionState.Cooldown:
            return (
              <>
                <CooldownLabel>Cooldown</CooldownLabel>
                <DataText>
                  <TimeCountdown
                    key={AuctionState.Cooldown}
                    date={cooldownEndExpiryDate}
                    onComplete={() =>
                      setInterval(() => setCooldownTrigger({}), 5000)
                    }
                  />
                </DataText>
              </>
            );
          case AuctionState.Expired:
            return <DataText color="negative">Auction Lost</DataText>;
          case AuctionState.Cancelled:
            return <DataText color="negative">Auction Cancelled</DataText>;
        }
      };

      const handleCancel = async () => {
        const callback = notify(
          {
            title: "Cancelling bid",
          },
          true
        );
        try {
          const returnData = await client.data.client.cancelBid(
            bid.address,
            wallet.isSafeApp
          );

          await createProposal("Cancel bid", returnData);
          client.data.updateClient();
          callback({
            title: "Bid cancelled",
            type: NotificationType.SUCCESS,
          });
        } catch (err) {
          console.error(err);
          if (err instanceof ProgramError)
            callback({
              title: "Cancel bid failed",
              type: NotificationType.ERROR,
              description: err.msg,
              code: err.code,
            });
          else if (err instanceof Error)
            callback({
              title: "Cancel bid failed",
              type: NotificationType.ERROR,
            });
        }
      };

      const putMultiplier =
        1 / (convertNativeNumberToDecimal(optionInfo.strike.toNumber()) * 10);

      const underlying = getUnderlyingStr(optionInfo);
      const totalPremium = bidCurrencyMint
        ? convertNativeMintNumberToDecimal(bid.amount, bidCurrencyMint)
        : "-";
      const premiumPerUnderlyingStr = premiumPerUnderlying();

      const sizeInUnderlying = auction
        ? optionInfo.kind == "call" && multiplier
          ? Big(auction.amount).mul(multiplier).toNumber()
          : auction.amount * putMultiplier
        : "-";

      const counterparty = auction ? auction.creator.toString() : "-";
      const expiry = getFormattedExpiry(optionInfo.expiry, "client-expiry");
      const bestBidStr = bestBid() || "-";
      const settlementCurrency = getMintMapping(optionInfo.collateralMint);
      const exerciseCurrency =
        optionInfo.settlementType === SettlementType.PHYSICAL
          ? getMintMapping(optionInfo.exerciseMint)
          : "N/A";
      return (
        <Row columns={NUM_TABLE_COLUMNS} key={bid.address.toString()}>
          {/* Direction */}
          <Data>
            <DataText color="positive">Buy</DataText>
          </Data>
          {/* Underlying */}
          <Data>
            <DataText title={underlying}>{underlying}</DataText>
          </Data>
          {/* Bid Currency*/}
          <Data>
            <DataText title={bidCurrencyTitle}>{bidCurrencyStr}</DataText>
          </Data>
          {/* My Bid (Total Premium) */}
          <Data>
            <DataText title={totalPremium?.toString()}>{totalPremium}</DataText>
          </Data>
          {/* Premium Per Underlying*/}
          <Data>
            <DataText title={premiumPerUnderlyingStr.toString()}>
              {premiumPerUnderlyingStr}
            </DataText>
          </Data>
          {/* Type */}
          <Data>
            <DataText>European</DataText>
          </Data>
          {/* Instrument */}
          <Data>
            <DataText>{capitalizeFirstLetter(optionInfo.kind)}</DataText>
          </Data>
          {/* Strike */}
          <Data>
            <DataText>
              {convertNativeNumberToDecimal(optionInfo.strike.toNumber())}
            </DataText>
          </Data>
          {/* Size in Underlying */}
          <Data>
            <DataText title={sizeInUnderlying.toString()}>
              {sizeInUnderlying}
            </DataText>
          </Data>
          {/* Counterparty */}
          <Data>
            <DataText title={counterparty}>{counterparty}</DataText>
          </Data>
          {/* Expiry */}
          <Data>
            <DataText title={expiry}>{expiry}</DataText>
          </Data>
          {/* Settlement Method */}
          <Data>
            <DataText title={optionInfo.settlementType}>
              {capitalizeFirstLetter(optionInfo.settlementType)}
            </DataText>
          </Data>
          {/* Exercise Currency */}
          <Data>
            <DataText title={exerciseCurrency}>{exerciseCurrency}</DataText>
          </Data>
          {/* Settlement Currency */}
          <Data>
            <DataText title={settlementCurrency}>{settlementCurrency}</DataText>
          </Data>
          {/* Bid Time Remaining */}
          <Data>
            <BidTimeRemaining />
          </Data>
          {/* Best Bid (Total Premium) */}
          {envVars.VITE_DISABLE_BLIND_AUCTIONS === "true" && (
            <Data>
              <DataText
                color={
                  auction
                    ? auction.exchangeAmount
                      ? "negative"
                      : bestBid() ===
                        (bidCurrencyMint
                          ? convertNativeMintNumberToDecimal(
                              bid.amount,
                              bidCurrencyMint
                            )
                          : 0)
                      ? "positive"
                      : "negative"
                    : "negative"
                }
                title={bestBidStr.toString()}
              >
                {bestBidStr}
              </DataText>
            </Data>
          )}
          <ButtonData>
            <ClientButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleCancel}
              disabled={cancelButtonText() !== "Cancel"}
              label={cancelButtonText()}
            />
          </ButtonData>
        </Row>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    client.isInitialized,
    client.data?.bids,
    client.data?.client,
    getMultiplier,
    flex.isInitialized,
    convertNativeNumberToDecimal,
    convertNativeMintNumberToDecimal,
    notify,
    cooldownTrigger,
    getTokenExchangeRate,
  ]);

  return (
    <OpenBidsContainer>
      <Table>
        <TableHead>
          <HeaderRow
            columns={
              client.data?.bids.length
                ? NUM_TABLE_COLUMNS
                : NUM_TABLE_COLUMNS - 1 // There will be no cancel button
            }
          >
            <Heading>
              <HeadingText title="Direction">DIRECTION</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Underlying">UNDERLYING</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Bid Currency">BID CURRENCY</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="My Bid (Total Premium)">
                MY BID (TOTAL PREMIUM)
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Premium Per Underlying">
                PREMIUM PER UNDERLYING
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Type">TYPE</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Instrument">INSTRUMENT</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Strike">STRIKE</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Size in Underlying">
                SIZE IN UNDERLYING
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Counterparty">COUNTERPARTY</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Expiry (UTC)">EXPIRY (UTC)</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Settlement Method">
                SETTLEMENT METHOD
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Exercise Currency">
                EXERCISE CURRENCY
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Settlement Currency">
                SETTLEMENT CURRENCY
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Bid Time Remaining">
                BID TIME REMAINING
              </HeadingText>
            </Heading>
            {envVars.VITE_DISABLE_BLIND_AUCTIONS === "true" && (
              <Heading>
                <HeadingText title="Best Bid (Total Premium)">
                  BEST BID (TOTAL PREMIUM)
                </HeadingText>
              </Heading>
            )}
          </HeaderRow>
        </TableHead>
        <TableBody>{bids}</TableBody>
      </Table>
    </OpenBidsContainer>
  );
};
const OpenBidsContainer = styled(ClientSectionViewContainer)``;
