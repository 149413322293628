import create from "zustand";
import { Auction, Option } from "@zetamarkets/flex-sdk/dist/flex/types";
import { Mint } from "@solana/spl-token";

export type OptionAuction = Omit<Auction, "optionInfo"> & {
  optionInfo: Option;
};

export type Auctions = {
  option: OptionAuction[];
  spot: Auction[];
};

type FlexStore = {
  isInitialized: boolean;
  setIsInitialized: (val: boolean) => void;

  auctions: Auctions;
  setAuctions: (auctions: Auctions) => void;

  mintsInfo: Record<string, Mint>;
  addMint: (mintAddr: string, info: Mint) => void;

  exchangeRateCache: Record<string, number>;
  addExchangeRate: (mintAddr: string, rate: number) => void;
};

export const useFlexStore = create<FlexStore>(
  // @ts-ignore
  (set) => ({
    isInitialized: false,
    setIsInitialized: (val) => set({ isInitialized: val }),

    auctions: { option: [], spot: [] },
    setAuctions: (auctions) => set({ auctions }),

    mintsInfo: {},
    addMint: (mintAddr, mintInfo) =>
      set((state) => ({
        mintsInfo: {
          ...state.mintsInfo,
          [mintAddr]: mintInfo,
        },
      })),

    exchangeRateCache: {},
    addExchangeRate: (mintAddr, rate) =>
      set((state) => ({
        exchangeRateCache: { ...state.exchangeRateCache, [mintAddr]: rate },
      })),
  })
);
