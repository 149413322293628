import { Link } from "react-router-dom";
import styled from "styled-components";
import { Subtitle } from "../Text";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.background.primary};
  min-height: 50px;
  border-bottom: ${(props) => `1px solid ${props.theme.background.selected}`};
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;

  margin-left: 1rem;
  > * + * {
    margin-left: 1.5rem;
  }
`;
export const RightContainer = styled.div`
  margin-right: 1rem;
`;
export const ZetaLogo = styled.img`
  height: 20px;
  width: 20px;
`;

export const FlexText = styled(Subtitle)``;

export const PageLink = styled(Link)<{ selected?: boolean }>`
  ${Subtitle} {
    cursor: ${(props) => !props.selected && "pointer"};
  }
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 2rem;

  margin-left: 1rem;

  background: ${(props) => props.theme.background.divider};
`;
