import styled from "styled-components";
import { ZetaTheme } from "../../utils/theme";

// TODO: change from isBold -> bold, isInline -> inline
export const Font = styled.p<{
  color?: keyof ZetaTheme["font"];
  inline?: boolean;
  bold?: boolean;
  clickable?: boolean;
}>`
  display: ${(props) => (props.inline ? "inline-block" : "block")};
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  color: ${(props) =>
    !props.color ? props.theme.font.primary : props.theme.font[props.color]};
  cursor: ${(props) => (props.clickable ? "pointer" : "auto")};
`;

export const Header = styled(Font)`
  font-size: 32px;
  line-height: 36px;
`;

export const Subtitle = styled(Font)`
  font-size: 16px;
  line-height: 20px;
`;
export const Paragraph = styled(Font)``;

export const Label = styled(Font)`
  font-size: 8px;
  line-height: 12px;
`;
