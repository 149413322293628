import { useMemo } from "react";
import styled from "styled-components";
import { ClientSectionViewContainer, ClientButton } from "./styles";
import { ProgramError } from "@project-serum/anchor";
import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { Flex } from "@zetamarkets/flex-sdk";
import { FlexOptionType, Option } from "@zetamarkets/flex-sdk/dist/flex/types";
import { useClient } from "../../../hooks/useClient";
import { useFlex } from "../../../hooks/useFlex";
import {
  useNotificationStore,
  NotificationType,
} from "../../../stores/useNotificationStore";
import {
  getAuctionState,
  AuctionState,
  getFormattedExpiry,
  getMintMapping,
  shortenAddress,
} from "../../../utils/general";
import {
  Row,
  DataText,
  TableHead,
  HeadingText,
  TableBody,
  Data,
  Table,
  Heading,
  HeaderRow,
  ButtonData,
} from "../../TableStyles";
import { useSnowflakeSafe } from "../../../hooks/useSnowflake";
import { useUtils } from "../../../hooks/useUtils";
import { EmptyMessage } from "./EmptyMessage";

const NUM_TABLE_COLUMNS = 6;

export const CancellableBids = () => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const client = useClient();
  const { createProposal } = useSnowflakeSafe();
  const wallet = useSafeWallet();
  const flex = useFlex();
  const notify = useNotificationStore((state) => state.notify);
  const { convertNativeMintNumberToDecimal } = useUtils();

  const bids = useMemo(() => {
    if (!client.isInitialized || !flex.isInitialized) return null;

    const openAuctionBids = client.data.client
      .getCancellableBids()
      .find((o) => o.optionAccount);
    if (!openAuctionBids) {
      return <EmptyMessage message={"There are no cancellable bids."} />;
    }

    return client.data.client.getCancellableBids().map((bid) => {
      // Using flex auctions here for
      const auction = flex.data.optionAuctions.find((a) =>
        a.address.equals(bid.auctionAccount)
      );

      // auctions can be null when cancelled
      const state = auction ? getAuctionState(auction) : AuctionState.Cancelled;

      const totalPremium = convertNativeMintNumberToDecimal(
        bid.amount,
        bid.bidCurrencyMint!
      );
      const bidEndStr = getFormattedExpiry(bid.bidEnd, "expired-bids-expiry");

      const cooldownEndStr = getFormattedExpiry(
        bid.cooldownEnd,
        "expired-bids-expiry"
      );

      let bidCurrencyMint: PublicKey | null;
      let optionInfo: Option;
      if (auction) {
        optionInfo = auction.optionInfo;
        bidCurrencyMint = auction.bidCurrencyMint;
      } else {
        if (bid.optionAccount === null) {
          return null;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        optionInfo = Flex.getOption(
          bid.optionAccount,
          FlexOptionType.Normal
        ) as Option;
        if (optionInfo == null) {
          return null;
        }

        bidCurrencyMint = bid.bidCurrencyMint;

        if (bidCurrencyMint === null) {
          return null;
        }
      }
      const bidCurrency = getMintMapping(bidCurrencyMint);
      const bidCurrencyTitle =
        bidCurrency === "11111111111111111111111111111111"
          ? bid.bidCurrencyMint?.toString()
          : bidCurrency;
      const bidCurrencyStr =
        bidCurrencyTitle && bidCurrencyTitle.length > 10
          ? shortenAddress(bidCurrencyTitle)
          : bidCurrencyTitle;

      const cancelButtonText = () => {
        switch (state) {
          case AuctionState.Live:
            return "Cancel";
          case AuctionState.Cooldown:
            return auction?.exchangeAmount !== 0 ? "Cancel" : "Cooldown";
          case AuctionState.Expired:
            return "Cancel";
          case AuctionState.Cancelled:
            return "Cancel";
        }
      };

      const handleCancel = async () => {
        const callback = notify(
          {
            title: "Cancelling bid",
          },
          true
        );
        try {
          const returnData = await client.data.client.cancelBid(
            bid.address,
            wallet.isSafeApp
          );

          await createProposal("Cancel bid", returnData);
          client.data.updateClient();
          callback({
            title: "Bid cancelled",
            type: NotificationType.SUCCESS,
          });
        } catch (err) {
          console.error(err);
          if (err instanceof ProgramError)
            callback({
              title: "Cancel bid failed",
              type: NotificationType.ERROR,
              description: err.msg,
              code: err.code,
            });
          else if (err instanceof Error)
            callback({
              title: "Cancel bid failed",
              type: NotificationType.ERROR,
            });
        }
      };

      return (
        <Row columns={NUM_TABLE_COLUMNS} key={bid.address.toString()}>
          {/* Address */}
          <Data>
            <DataText>{bid.address.toString()}</DataText>
          </Data>
          {/* Bid Currency*/}
          <Data>
            <DataText title={bidCurrencyTitle}>{bidCurrencyStr}</DataText>
          </Data>
          {/* Amount */}
          <Data>
            <DataText>{totalPremium}</DataText>
          </Data>
          {/* Bid End */}
          <Data>
            <DataText>{bidEndStr}</DataText>
          </Data>
          {/* Cooldown End */}
          <Data>
            <DataText>{cooldownEndStr}</DataText>
          </Data>
          <ButtonData>
            <ClientButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleCancel}
              disabled={cancelButtonText() !== "Cancel"}
              label={cancelButtonText()}
            />
          </ButtonData>
        </Row>
      );
    });
  }, [
    client.data?.client,
    client.isInitialized,
    convertNativeMintNumberToDecimal,
    createProposal,
    flex.data?.optionAuctions,
    flex.isInitialized,
    notify,
    wallet.isSafeApp,
  ]);

  return (
    <ExpiredBidsContainer>
      <Table>
        <TableHead>
          <HeaderRow
            columns={
              client.data?.client.getCancellableBids().length
                ? NUM_TABLE_COLUMNS
                : NUM_TABLE_COLUMNS - 1 // There will be no cancel button
            }
          >
            <Heading>
              <HeadingText title="Address">ADDRESS</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Bid Currency">BID CURRENCY</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Amount">AMOUNT</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Bid End (UTC)">BID END (UTC)</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Cooldown End (UTC)">
                COOLDOWN END (UTC)
              </HeadingText>
            </Heading>
          </HeaderRow>
        </TableHead>
        <TableBody>{bids}</TableBody>
      </Table>
    </ExpiredBidsContainer>
  );
};
const ExpiredBidsContainer = styled(ClientSectionViewContainer)``;
