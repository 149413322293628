import styled from "styled-components";

export const FooterContainer = styled.div`
  background: ${(props) => props.theme.background.primary};
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  > * + * {
    margin-left: 1.5rem;
  }
  border-top: ${(props) => `1px solid ${props.theme.background.selected}`};
  a {
    * {
      &:hover {
        cursor: pointer;
        filter: brightness(1.2);
      }
    }
  }
`;
