import styled from "styled-components";
import { Paragraph } from "../Text/styles";

export const DropdownHeaderText = styled(Paragraph)`
  margin-right: 0.5rem;
  cursor: pointer;
  @media only screen and (min-width: 450px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const DropdownContainer = styled.div<{ open?: boolean }>`
  transition: opacity linear 200ms;
  ${DropdownHeaderText} {
    color: ${(props) =>
      props.open ? props.theme.font.primary : props.theme.buttons.selectable};
  }
  @media only screen and (min-width: 450px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    background: ${(props) => props.theme.background.secondary};
    border-radius: 3px;

    border: ${(props) =>
      props.open
        ? `1px solid ${props.theme.buttons.selectable}`
        : `1px solid ${props.theme.background.secondary}`};

    transition: 200ms;

    svg {
      filter: ${(props) => props.open && "brightness(1.5)"};
    }
    &:hover {
      cursor: ${(props) => props.open && "pointer"};
      border: ${(props) =>
        !props.open && `1px solid ${props.theme.buttons.unselectable}`};
      ${DropdownHeaderText} {
        color: ${(props) => props.theme.font.primary};
      }
      svg {
        filter: brightness(1.5);
      }
    }
  }
`;

export const DropdownHeader = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 5px;
  cursor: pointer;
  height: 100%;

  ${Paragraph} {
    color: ${(props) => props.open && props.theme.font.primary};
  }
  @media only screen and (min-width: 450px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0.5rem;
  }
`;

export const DropdownVeil = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  z-index: 4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: opacity linear 200ms;
  @media only screen and (max-width: 450px) {
    z-index: 11;
  }
`;

export const DropdownBody = styled.div<{ open: boolean }>`
  position: fixed;
  z-index: 20;
  bottom: 0;
  width: 100%;
  left: 0;
  display: ${(props) => (props.open ? "block" : "none")};
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.theme.background.primary};
  width: fit-content;
  min-width: 100%;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  @keyframes slide-up {
    from {
      bottom: -200px;
    }
    to {
      bottom: 0;
    }
  }
  animation: slide-up 300ms ease-out;

  @media only screen and (min-width: 450px) {
    animation: none;
    position: absolute;
    bottom: auto;
    left: 0;
    right: 0;
    top: 30px;
    border-radius: 3px;
    border: ${(props) => `1px solid ${props.theme.buttons.selectable}`};
    background: ${(props) => props.theme.background.secondary};
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.3);
  }
`;

export const DropdownItemText = styled(Paragraph)`
  @media only screen and (min-width: 450px) {
    padding: 0.75rem 0.5rem;
    font-size: 12px;
    line-height: 16px;
  }
  width: -webkit-fill-available;
`;

export const DropdownItem = styled.div<{
  selected?: boolean;
  color?: string;
}>`
  width: -webkit-fill-available;
  min-width: 65px;
  padding: 1rem 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  * {
    cursor: ${(props) => (!props.selected ? "pointer" : "auto")};
  }
  &:hover {
    font-weight: ${(props) => !props.selected && 600};
  }

  ${DropdownItemText} {
    color: ${(props) =>
      !props.selected
        ? props.theme.font.primary
        : props.theme.font.callToAction};
  }

  @media only screen and (min-width: 450px) {
    padding: 0;

    ${DropdownItemText} {
      color: ${(props) =>
        !props.selected
          ? props.theme.font.primary
          : props.theme.font.unselectable};
    }
  }

  @media only screen and (max-width: 450px) {
    width: 100%;
    padding: 1.25rem 2rem;
  }
`;

export const DropdownBodyHeader = styled.div`
  text-align: center;
  padding: 1.5rem;
  border-bottom: ${(props) => `2px solid ${props.theme.background.secondary}`};
  color: ${(props) => props.theme.font.primary};
`;

export const DropdownBodyList = styled.div`
  max-height: 350px;
  overflow-y: scroll;
`;

export const DropdownSelectionIcon = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.selected
      ? props.theme.background.primary
      : props.theme.buttons.selectable};
  border: ${(props) =>
    props.selected
      ? `1px solid ${props.theme.buttons.callToAction}`
      : `1px solid ${props.theme.buttons.selectable}`};
  min-height: 12px;
  min-width: 12px;
  border-radius: 50%;
  font-size: ${(props) => (props.selected ? "6px" : "8px")};
  line-height: 13px;
  color: ${(props) =>
    props.selected
      ? props.theme.font.callToAction
      : props.theme.background.primary};
  margin-left: 0.5rem;
  text-align: center;

  svg {
    height: 12px;
  }
`;

export const DropdownItemWrapper = styled.div<{ selected: boolean }>`
  width: auto;
  &:last-child {
    ${DropdownItem} {
      border-bottom: none;
    }
  }
  display: flex;
  flex-direction: row;
  transition: 200ms;
  &:hover {
    cursor: pointer;
    background: ${(props) => !props.selected && props.theme.background.primary};
  }
  svg {
    height: 14px;
    width: 14px;
    color: white;
    margin-right: 0.3rem;
  }
  @media only screen and (max-width: 450px) {
    svg {
      margin-right: 0;
      color: ${(props) => props.theme.buttons.callToAction};
    }
  }
`;

export const DropdownArrow = styled.div<{ open: boolean }>`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin: 0 3px 2px 0;
  border-top: 5px solid grey;
  transition: 200ms;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "none")};
`;

export const DropdownAnchor = styled.a`
  display: flex;
  flex-direction: row;

  transition: 200ms;
`;

export const DropdownIcon = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 4px;
`;
