import shallow from "zustand/shallow";
import { Paragraph } from "../Text";
import {
  NotificationContainer,
  NotificationsListContainer,
  CrossIcon,
  NotificationTextContainer,
  NotificationHeaderContainer,
  TxidLink,
  NotificationHeaderSpan,
  IconImg,
  Loader,
  InnerLoader,
} from "./styles";
import CrossSVG from "../../assets/images/cross.svg";
import SuccessSVG from "../../assets/images/success.svg";
import InfoSVG from "../../assets/images/info.svg";
import WarningSVG from "../../assets/images/warning.svg";
import ErrorSVG from "../../assets/images/error.svg";
import { ExternalLink } from "styled-icons/remix-line";
import { useEffect } from "react";
import {
  NotificationType,
  useNotificationStore,
} from "../../stores/useNotificationStore";
import { envVars } from "../../utils/envVars";
import { Links } from "../../constants";

interface NotificationListProps {
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

export const NotificationsList = ({ className }: NotificationListProps) => {
  const { notifications, setNotifications } = useNotificationStore(
    (state) => ({
      notifications: state.notifications,
      setNotifications: state.setNotifications,
    }),
    shallow
  );

  const handleClose = (index: number) => {
    notifications.splice(index, 1);
    setNotifications([...notifications]);
  };

  const orderedNotifications = notifications.map((notification, idx) => (
    <Notification
      key={idx}
      index={idx}
      ts={notification.ts}
      type={notification.type}
      title={notification.title}
      description={notification.description}
      txid={notification.txid}
      error={notification.error}
      code={notification.code}
      msg={notification.msg}
      handleClose={handleClose}
    />
  ));
  return (
    <>
      <NotificationsListContainer
        isVisible={!!notifications.length}
        className={className}
      >
        {orderedNotifications.reverse()}
      </NotificationsListContainer>
    </>
  );
};
interface NotificationProps {
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
  ts?: number;
  type?: NotificationType;
  title: string;
  description?: string;
  txid?: string;
  error?: string;
  code?: number;
  msg?: string;
  index: number;
  handleClose: (index: number) => void;
}

export const Notification = ({
  className,
  index,
  ts,
  type,
  title,
  description,
  txid,
  error,
  msg,
  code,
  handleClose,
}: NotificationProps) => {
  const { notifications, popNotifications } = useNotificationStore(
    (state) => ({
      notifications: state.notifications,
      popNotifications: state.popNotifications,
    }),
    shallow
  );

  useEffect(() => {
    if (notifications[0].ts === ts) {
      setTimeout(() => {
        popNotifications();
      }, 5000);
    }
  }, [notifications, popNotifications, ts]);

  const network =
    envVars.VITE_NETWORK_TYPE === "mainnet-beta"
      ? "mainnet-qn1"
      : "devnet-solana";

  const txidLink = txid ? `${Links.TX}${txid}/?cluster=${network}` : "";
  return (
    <TxidLink hasTxid={txid} href={txidLink || undefined} target="_blank">
      <NotificationContainer className={className} notificationType={type}>
        {type === NotificationType.LOADING ? (
          <Loader>
            <InnerLoader />
          </Loader>
        ) : (
          <IconImg
            src={
              type === NotificationType.SUCCESS
                ? SuccessSVG
                : type === NotificationType.INFO
                ? InfoSVG
                : type === NotificationType.WARNING
                ? WarningSVG
                : ErrorSVG
            }
          />
        )}

        <NotificationTextContainer>
          <NotificationHeaderContainer>
            <NotificationHeaderSpan>
              <Paragraph
                bold
                color={
                  type === NotificationType.SUCCESS
                    ? "success"
                    : type === NotificationType.INFO
                    ? "info"
                    : type === NotificationType.WARNING
                    ? "warning"
                    : type === NotificationType.LOADING
                    ? "loading"
                    : "error"
                }
              >
                {title}
              </Paragraph>
              {txid && <ExternalLink />}
            </NotificationHeaderSpan>
            <CrossIcon
              src={CrossSVG}
              onClick={(e) => {
                e.preventDefault();
                handleClose(index);
              }}
            />
          </NotificationHeaderContainer>
          {msg ? (
            <Paragraph>{msg}</Paragraph>
          ) : description ? (
            <Paragraph>{description}</Paragraph>
          ) : (
            <></>
          )}
        </NotificationTextContainer>
      </NotificationContainer>
    </TxidLink>
  );
};
