import { useMemo, useState } from "react";
import { DashboardSectionContainer } from "./styles";
import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { useClient } from "../../hooks/useClient";
import { Tabs, TabType } from "./Tabs";
import { ExercisableOptions } from "./ExercisableOptions";
import { Options } from "./Options";
import { UserCreatedOptions } from "./UserCreatedOptions";
import { Wallet } from "../Wallet";
import { ConnectWalletContainer } from "../ClientSection/Option/styles";
import { Loader } from "../Loader";

interface DashboardSectionProps {
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

export const DashboardSection = ({ className }: DashboardSectionProps) => {
  const [selectedTab, setSelectedTab] = useState<TabType>(
    TabType.ExercisableOptions
  );
  const wallet = useSafeWallet();
  const client = useClient();

  const content = useMemo(() => {
    if (!wallet.connected) {
      return (
        <ConnectWalletContainer>
          <Wallet />
        </ConnectWalletContainer>
      );
    } else if (!client.isInitialized) {
      return (
        <ConnectWalletContainer>
          <Loader />
        </ConnectWalletContainer>
      );
    } else if (selectedTab === TabType.Options) {
      return <Options />;
    } else if (selectedTab === TabType.UserCreatedOptions) {
      return <UserCreatedOptions />;
    } else {
      return <ExercisableOptions />;
    }
  }, [client.isInitialized, selectedTab, wallet.connected]);

  return (
    <DashboardSectionContainer className={className}>
      <Tabs selected={selectedTab} onTabChange={(tab) => setSelectedTab(tab)} />
      {content}
    </DashboardSectionContainer>
  );
};
