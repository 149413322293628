import styled, { css } from "styled-components";
import {
  DropdownBody,
  DropdownContainer,
  DropdownHeader,
} from "../../components/Dropdown/styles";
import { Navbar as FlexNavbar } from "../../components/Navbar";
import { Header, Paragraph, Subtitle } from "../../components/Text";
import { Input } from "../../components/sharedStyles";
import DateTimePicker from "react-datetime-picker/dist/entry.nostyle";
import { WalletContainer } from "../../components/Wallet/styles";
import { BuyButton } from "../../components/PurchaseSection/Option/PurchaseInterface";
import { ButtonToggleContainer } from "../../components/ButtonToggle/styles";
import { TextSpan } from "../../components/PurchaseSection/Option/styles";
import { NumericFormat } from "react-number-format";

export const Label = styled(Paragraph)`
  text-align: center;
`;

export const Title = styled(Header)`
  text-align: center;
  margin: 1rem;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
`;

export const LabelValue = styled(Label)`
  color: white;
`;

export const ValueContainer = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 0.25rem;
  border-radius: 3px;
  margin-top: 0.5rem;
  background: ${(props) => props.theme.background.secondary};
`;

export const InputContainer = styled(ValueContainer)`
  background: ${(props) => props.theme.background.secondary};
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  padding-top: 0.5em;
`;

export const ExpirySelection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2em;
  padding-top: 0.5em;
  gap: 0.5rem;
`;

export const OptionDisplay = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100vw;
  height: 100%;
  background: ${(props) => props.theme.background.primary};
  padding: 3rem 0;
  flex-grow: 1;
`;

export const Navbar = styled(FlexNavbar)`
  height: 50px;
  width: 100vw;
  border-bottom: solid 1px #80828580;
`;

export const Inputs = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const OptionGeneratorContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 700px;

  ${Header} {
    font-size: 36px;
    line-height: 41px;
  }

  ${Subtitle} {
    font-size: 24px;
    line-height: 28px;
  }

  ${Paragraph} {
    font-size: 16px;
    line-height: 19px;
  }

  ${Label} {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const OptionsGeneratorInput = styled(NumericFormat)`
  background: ${(props) => props.theme.background.secondary};
  color: ${(props) => props.theme.font.selectable};
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 1px 0.6rem;
  border-radius: 4px;
`;

export const InputGroupContainer = styled(OptionGeneratorContainer)<{
  disabled?: boolean;
}>`
  width: 100%;
  margin-top: 2rem;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

export const InputGroupDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.background.secondary};
  padding: 1rem 1.5rem;
  border-radius: 8px 8px 0 0;
`;
export const InnerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * + * {
    margin-left: 0.5rem;
  }
`;

export const NumberIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.font.callToAction};
  background: ${(props) => props.theme.buttons.highlight};
  border-radius: 16px;
`;

export const StaticTitle = styled(Label)`
  text-align: center;
  width: 100%;
`;
export const StaticContentText = styled(Subtitle)<{ gradient?: boolean }>`
  text-align: center;
  width: 100%;
  ${(props) =>
    props.gradient &&
    css`
      background: linear-gradient(330.66deg, #001e9d -1.96%, #15d3ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `};
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  flex-basis: 0;
  > * + * {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }

  ${DropdownContainer} {
    height: 2rem;
    min-width: auto;
    max-width: none;
    width: -webkit-fill-available;

    ${DropdownHeader} {
      ${Paragraph} {
        color: ${(props) => props.theme.font.subtext};
        font-weight: 600;
      }
    }
    ${DropdownBody} {
      top: 38px;
      left: -1px;
    }
  }

  ${StaticContentText} {
    margin-top: 0.75rem;
  }

  ${ButtonToggleContainer} {
    width: -webkit-fill-available;
  }
`;

export const InputRow = styled.div<{ stack?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  padding: 1rem 1.5rem;
  border: ${(props) => `1px solid ${props.theme.background.secondary}`};
  border-radius: ${(props) => (props.stack ? 0 : "0 0 8px 8px")};
  border-bottom: ${(props) => props.stack && "none"};
  ${InputColumn} {
    ${OptionsGeneratorInput} {
      width: -webkit-fill-available;
    }
  }

  > * + * {
    margin-left: 1rem;
  }
`;

export const ComboInputRow = styled(InputRow)`
  border-top: none;
  border-bottom: none;
  align-items: flex-end;
  img {
    right: 0;
    cursor: pointer;
    &:hover {
      filter: brightness(1.2);
    }
  }

  ${Input} {
    max-width: 150px;
  }
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  padding: 1rem 1.5rem;
  > * + * {
    margin-top: 1rem;
  }
`;
export const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const MintContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;

  ${WalletContainer} {
    width: 200px;
    .wallet-adapter-button {
      width: 100%;
      background: ${(props) => props.theme.buttons.callToAction};
      border: ${(props) => `1px solid ${props.theme.buttons.callToAction}`};
      color: ${(props) => props.theme.font.primary};
      &:hover {
        filter: brightness(1.2);
      }
    }
  }

  ${Paragraph} {
    text-align: center;
  }
`;

export const AddButtonRow = styled(InputRow)`
  border-radius: 0;
  padding-left: 5.5rem;
  &:hover {
    filter: brightness(1.2);
  }
  * {
    cursor: pointer;
  }
  border-top: none;
  border-bottom: none;
  ${Subtitle} {
    margin-bottom: 0;
  }
  height: 40px;
  img {
    position: inherit;
  }
`;

export const MintButton = styled(BuyButton)`
  height: 36px;
  margin: 0;
`;

export const AddButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > * + * {
    margin-left: 0.5rem;
  }

  transition: 500ms;
  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
`;

export const StyledDateTimePicker = styled(DateTimePicker)`
  width: -webkit-fill-available;
  .react-datetime-picker {
    display: inline-flex;
    position: relative;
  }
  .react-datetime-picker,
  .react-datetime-picker *,
  .react-datetime-picker *:before,
  .react-datetime-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-datetime-picker--disabled {
    background-color: #5f5b6c;
    color: #6d6d6d;
  }
  .react-datetime-picker__wrapper {
    min-width: 200px;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    background: #171d2e;
    border: 1px solid #171d2e;
    color: #bcbec0;
    border-radius: 6px;
    padding: 0.1rem 0.5rem;
    font-size: 16px;
    line-height: 19px;
    font-family: "Heebo", sans-serif;
    cursor: pointer;
    color: white;
  }
  .react-datetime-picker__inputGroup {
    min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
  }
  .react-datetime-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-datetime-picker__inputGroup__input {
    min-width: 0.54em;
    height: calc(100% - 2px);
    position: relative;
    padding: 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
  .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-datetime-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-datetime-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-datetime-picker__inputGroup__amPm {
    font: inherit;
    -moz-appearance: menulist;
  }
  .react-datetime-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
    svg {
      stroke: #c4d8e8;
    }
  }
  .react-datetime-picker__button:enabled {
    cursor: pointer;
  }
  .react-datetime-picker__button:enabled:hover
    .react-datetime-picker__button__icon,
  .react-datetime-picker__button:enabled:focus
    .react-datetime-picker__button__icon {
    stroke: #fff;
  }
  .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
    stroke: #fff;
  }
  .react-datetime-picker__button svg {
    display: inherit;
  }
  .react-datetime-picker__calendar,
  .react-datetime-picker__clock {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }
  .react-datetime-picker__calendar--closed,
  .react-datetime-picker__clock--closed {
    display: none;
  }
  .react-datetime-picker__calendar {
    width: 350px;
    max-width: 100vw;
  }
  .react-datetime-picker__calendar .react-calendar {
    border-width: thin;
  }
  .react-datetime-picker__clock {
    width: 200px;
    height: 200px;
    max-width: 100vw;
    padding: 25px;
    background-color: #171d2e;
    color: #bcbec0;
    border: thin solid #a0a096;
  }

  .react-calendar {
    width: 350px;
    max-width: 100%;
    background-color: #171d2e;
    color: #bcbec0;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-top: 0.5rem;
    top: 10px;
    font-family: "Heebo", sans-serif;
    line-height: 1.125em;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }

  .react-calendar__tile {
    max-width: 100%;
    padding: 6px 4px;
    background: none;
    text-align: center;
    line-height: 16px;
    color: #bcbec0;
  }

  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    color: #79a8d7;
  }
  .react-calendar__navigation button:disabled {
    background-color: #171d2e;
    color: #bcbec0;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.25em;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #c4d8e8;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #c4d8e8;
  }

  .react-calendar__year-view__months {
    height: 250px;
  }

  .react-calendar__decade-view__years {
    height: 250px;
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 1 0.25em;
  }

  .react-calendar__tile:disabled {
    background-color: #171d2e;
    color: #5f5b6c;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: #c4d8e8;
    color: black;
    border-radius: 8px;
  }
  .react-calendar__tile--now {
    background-color: #171d2e;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #c4d8e8;
    color: black;
    border-radius: 8px;
  }
  .react-calendar__tile--hasActive {
    background: #1241e5;
    color: white;
    border-radius: 8px;
  }
  .react-calendar__tile--hasActive:enabled:hover {
    background: #c4d8e8;
    color: black;
    border-radius: 8px;
  }
  .react-calendar__tile--hasActive:enabled:focus {
    background: #1241e5;
    color: white;
    border-radius: 8px;
  }
  .react-calendar__tile--active {
    background: #1241e5;
    color: white;
    border-radius: 8px;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #c4d8e8;
    color: black;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #bcbec0;
  }
  .react-calendar__tile .react-calendar__month-view__days__day button {
    color: #79a8d7;
  }
  .react-calendar__tile .react-calendar__month-view__days__day {
    color: #79a8d7;
  }
  .react-calendar__tile .react-calendar__year-view__months__month button {
    color: #79a8d7;
  }
`;

export const ExpiryInputColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > * + * {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const ExpiryInputRow = styled(InputRow)`
  ${StyledDateTimePicker} {
    width: auto;
  }
  > * + * {
    margin-left: 2rem;
  }
`;

export const SummaryInputColumn = styled(ExpiryInputColumn)`
  text-align: end;
  justify-content: flex-end;
  align-items: flex-end;
  > * + * {
    margin: 0.5rem 0 0 0;
  }
`;

export const SubtitleTextSpan = styled(TextSpan)<{ smaller?: boolean }>`
  > * + * {
    margin-left: ${(props) => (props.smaller ? "0.4rem" : "0.5rem")};
  }
`;
