import { useState } from "react";
import { envVars } from "../../utils/envVars";
import { Header, Paragraph } from "../Text";
import UncheckedSVG from "../../assets/images/unchecked.svg";
import CheckedSVG from "../../assets/images/checked.svg";

import {
  AgreeAndContinueButton,
  CheckBoxContainer,
  CheckBoxText,
  ControlsContainer,
  ModalTitleContainer,
  TCsContent,
  TCsModal,
} from "./styles";
import { TermsAndConditionsText } from "./TermsAndConditionsText";

interface TermsAndConditionsProps {
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

export const TermsAndConditions = ({ className }: TermsAndConditionsProps) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(true);

  if (envVars.VITE_NETWORK_TYPE !== "mainnet-beta") return null;
  return (
    <TCsModal isOpen={showModal} className={className} zIndex={40}>
      <ModalTitleContainer>
        <Header bold>Terms and Conditions of Zeta FLEX</Header>
        <Paragraph>
          Date Last Revised: 16 June 2022 ("<b>Relevant Date</b>")
        </Paragraph>
      </ModalTitleContainer>

      <TCsContent>
        <TermsAndConditionsText />
      </TCsContent>
      <ControlsContainer>
        <CheckBoxContainer onClick={() => setChecked(!checked)}>
          {checked ? <img src={CheckedSVG} /> : <img src={UncheckedSVG} />}
          <CheckBoxText>
            I have read and understood the terms and conditions.
          </CheckBoxText>
        </CheckBoxContainer>
        <AgreeAndContinueButton
          label="Agree and Continue"
          disabled={!checked}
          color="callToAction"
          onClick={() => setShowModal(false)}
          isBold
        />
      </ControlsContainer>
    </TCsModal>
  );
};
