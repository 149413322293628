import styled, { keyframes } from "styled-components";
import { Button } from "../../Button";
import { Label, Paragraph } from "../../Text";
import { WalletContainer } from "../../Wallet/styles";

const loading = keyframes`
  0% {
    content: "Loading Client";
  }
  25% {
    content: "Loading Client.";
  }
  50% {
    content: "Loading Client..";
  }
  75% {
    content: "Loading Client...";
  }
`;

export const ClientSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ClientSectionViewContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

export const ClientButton = styled(Button)<{
  action?: boolean;
  disabled: boolean;
  larger?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  border: ${(props) =>
    props.disabled
      ? `1px solid ${props.theme.buttons.unselectable}`
      : props.action
      ? `1px solid ${props.theme.buttons.highlight}`
      : `1px solid ${props.theme.font.negative}`};

  color: ${(props) =>
    props.disabled
      ? props.theme.buttons.unselectable
      : props.action
      ? props.theme.buttons.highlight
      : props.theme.font.negative};
  width: 80%;
  padding: 0.3rem 0.2rem;

  height: ${(props) => (props.larger ? "auto" : "28px")};
  border-radius: 6px;

  background: ${(props) =>
    props.disabled || props.action
      ? props.theme.buttons.primary
      : "rgba(255, 97, 97, 0.25)"};
  * {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }

  &:hover {
    filter: brightness(1.2);
  }
`;

export const ButtonText = styled(Paragraph)`
  color: #0d1238;
`;

export const CooldownLabel = styled(Label)``;

export const ConnectWalletContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-grow: 1;

  ${WalletContainer} {
    .wallet-adapter-button {
      height: 36px;
      border-radius: 6px;
      padding: 0.3rem 1rem;
      font-size: 16px;
      color: ${(props) => props.theme.font.primary};
      background: ${(props) => props.theme.buttons.callToAction};
      border: ${(props) => `1px solid ${props.theme.buttons.callToAction}`};
      color: ${(props) => props.theme.font.primary};

      transition: 200ms;
      &:hover {
        filter: brightness(1.2);
      }
    }
  }
`;

export const ClientLoader = styled.div`
  height: 100%;
  color: ${(props) => props.theme.font.primary};
  &::after {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;

    content: "Loading Client";
    animation: ${loading} 2s linear infinite;
  }
`;

export const UnavailableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  > * + * {
    margin-top: 0.5rem;
  }
`;
