import styled from "styled-components";
import { Subtitle } from "../../components/Text";

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${(props) => props.theme.background.primary};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 1rem;
  ${Subtitle} {
    font-size: 18px;
  }
`;
