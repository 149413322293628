import styled from "styled-components";
import { Close } from "styled-icons/evaicons-solid";

export const ModalContainer = styled.div<{ zIndex?: number }>`
  position: fixed;
  top: 0;
  left: 0;

  z-index: ${(props) => props.zIndex ?? 20};

  height: 100%;
  width: 100vw;

  background: rgba(0, 0, 0, 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 2rem;
  max-height: 90%;
  width: 85%;
  white-space: normal;
  color: ${({ theme }) => theme.font.primary};
  background: rgba(20, 22, 38, 0.7);
  border-radius: 8px;
  z-index: 1;

  &:before {
    z-index: -1;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 1px;
    background: linear-gradient(330.66deg, #001e9d -1.96%, #15d3ff 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    /* Firefox */
    mask-composite: exclude;
  }
`;

export const CloseIcon = styled(Close)<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  right: 0;

  padding: 1rem;

  height: 25px;
  width: 25px;

  color: ${(props) => props.theme.font.selectable};

  display: ${(props) => (props.isVisible ? undefined : "none")};
  cursor: pointer;
`;
