import { PublicKey } from "@solana/web3.js";
import { Flex, utils } from "@zetamarkets/flex-sdk";
import {
  Auction,
  ComboOption,
  Kind,
  Option,
  SettlementType,
  UnderlyingType,
} from "@zetamarkets/flex-sdk/dist/flex/types";
import { getSolanaNetwork } from "./connection";

/**
 * Formats an expirySeries expiry date to a
 * string in this format: DD MMM YY (e.g 12 Sep)
 * @param expiry - this is a zeta expiry value, not a native (Date.now()) value.
 * @returns formatted expiry date string
 */
export const getFormattedExpiry = (
  expiry: number,
  type:
    | "purchase-option-detail"
    | "purchase-auction-expiry"
    | "auction-expiry"
    | "client-expiry"
    | "settlement-window"
    | "exercisable-options-expiry"
    | "option-generator"
    | "expired-bids-expiry"
) => {
  const expiryDate = new Date(expiry * 1000);
  let dateFmt: Intl.DateTimeFormatOptions;
  let joinChar = " ";
  switch (type) {
    case "purchase-option-detail":
      {
        dateFmt = {
          day: "numeric",
          month: "short",
          year: "2-digit",
        };
        joinChar = "";
      }
      break;
    case "option-generator":
      {
        dateFmt = {
          day: "numeric",
          month: "short",
          year: "2-digit",
        };
      }
      break;
    case "purchase-auction-expiry":
    case "exercisable-options-expiry":
      {
        dateFmt = {
          day: "numeric",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
          timeZone: "UTC",
          timeZoneName: "short",
        };
      }
      break;
    case "client-expiry":
    case "expired-bids-expiry":
    case "settlement-window":
    case "auction-expiry": {
      dateFmt = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        // hour12: false,
        hourCycle: "h23",
        timeZone: "UTC",
        // timeZoneName: "short",
      };

      const dateStr = new Intl.DateTimeFormat("en-US", dateFmt)
        .format(expiryDate)
        .replaceAll(",", "")
        .split("/")
        .join(" ")
        .split(" ");

      const hourAndMinutes = dateStr[3];

      return [dateStr[2], dateStr[0], dateStr[1]].join("/") + " " + dateStr[3];
    }
  }

  const dateStr = new Intl.DateTimeFormat("en-US", dateFmt)
    .format(expiryDate)
    .replaceAll(",", "") // Remove the comman
    .split(" ");

  return [dateStr[1], dateStr[0], dateStr[2], ...dateStr.slice(3)].join(
    joinChar
  );
};

/**
 *
 * Accounts for signed dollar figure (number) and converts to currency string
 *
 * @param dollarAmount: number
 *
 */
export const convertDollarNumberToString = (
  dollarAmount: number | undefined,
  minDecimals?: number,
  maxDecimals?: number
) => {
  if (dollarAmount === undefined) return "$ -";
  return `${dollarAmount < 0 ? "-" : ""}$${Math.abs(
    dollarAmount
  ).toLocaleString(undefined, {
    minimumFractionDigits: minDecimals !== undefined ? minDecimals : 2,
    maximumFractionDigits: maxDecimals !== undefined ? maxDecimals : 4,
  })}`;
};

export enum AuctionState {
  Live,
  Cooldown,
  Expired,
  Cancelled,
}
/**
 *
 * @param auction Auction to get the state for
 * @returns The state of the auction.
 */
export const getAuctionState = (auction: Auction): AuctionState => {
  const bidEnd = new Date(auction.bidEnd * 1000);
  const cooldownEnd = new Date(auction.cooldownEnd * 1000);
  const now = new Date(Flex.clockTimestamp * 1000);

  if (now < bidEnd) return AuctionState.Live;

  if (now < cooldownEnd) return AuctionState.Cooldown;

  return AuctionState.Expired;
};

/**
 * Captilizes the first letter of a string
 * @param str string to capitilize
 * @returns string with first letter capitilzed
 */
export const capitalizeFirstLetter = (str: string) => {
  const firstLet = str[0].toUpperCase();
  const rest = str.slice(1);
  return firstLet + rest;
};

/**
 * Provides a shortened version of a publicKey as a string
 */
export const shortenAddress = (address: string, chars = 4): string => {
  return `${address.slice(0, chars)}...${address.slice(-chars)}`;
};

/**
 * Get the human readble form of the specified mint
 */
export const getMintMapping = (mint: PublicKey) => {
  const underlyingMapping = utils.getUnderlyingMapping(
    getSolanaNetwork(),
    mint
  );

  return underlyingMapping;
};

export const getUnderlyingStr = (option: Option) => {
  const underlyingMapping = getMintMapping(option.underlyingMint);

  const underlying = Flex.getUnderlying(option.underlyingMint);

  if (option.settlementType === SettlementType.PHYSICAL) {
    const exerciseMapping = getMintMapping(option.exerciseMint);
    const collateralMapping = getMintMapping(option.collateralMint);
    if (option.kind === Kind.CALL) {
      return `${exerciseMapping}/${collateralMapping}`;
    } else {
      return `${collateralMapping}/${exerciseMapping}`;
    }
  }

  const isCToken =
    option.kind === Kind.CTOKENPUT ||
    underlying.underlyingType === UnderlyingType.SOLEND_C_TOKEN;

  if (!isCToken) return `${underlyingMapping}/USD`;

  if (option.kind === Kind.CTOKENPUT) return `${underlyingMapping}/cUSD`;

  return `${underlyingMapping}/USD`;
};

export const isOption = (
  option: Option | ComboOption | undefined
): option is Option => {
  if (!option) return false;

  if ((option as Option).strike) return true;

  return false;
};
