import { PublicKey } from "@solana/web3.js";
import { AuctionTable } from "./AuctionTable";
import { AuctionSelectionContainer } from "./styles";

interface AuctionSelectionProps {
  selectedAuction: PublicKey | undefined;
  onAuctionSelect: (addr: PublicKey) => void;
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

export enum AuctionType {
  Active = "Active",
  Upcoming = "Upcoming",
}

export const AuctionSelection = ({
  selectedAuction,
  onAuctionSelect,
  className,
}: AuctionSelectionProps) => {
  return (
    <AuctionSelectionContainer className={className}>
      <AuctionTable
        selectedAuction={selectedAuction}
        onSelect={onAuctionSelect}
      />
    </AuctionSelectionContainer>
  );
};
