import React, { useState, useRef, useEffect } from "react";
import { AccordionContainer, AccordionHeader, AccordionBody } from "./styles";

type AccordionProps = {
  /**
   * Header component
   */
  headerChild: JSX.Element;
  /**
   * Body component
   */
  bodyChild: React.ReactNode;
  className?: string;
  /**
   * Allows body to close the accordion
   */
  bodyClose?: boolean;
  defaultOpen?: boolean;
};

export const Accordion = ({
  headerChild,
  bodyChild,
  className,
  bodyClose,
  defaultOpen,
}: AccordionProps) => {
  const [open, setOpen] = useState<boolean>(defaultOpen || false);
  const contentRef = useRef<any>(null);
  const toggleActive = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = open
        ? `${contentRef.current.scrollHeight}px`
        : "0px";
    }
  }, [contentRef, open]);

  const headerWithProps = React.cloneElement(headerChild, { active: open });

  return (
    <AccordionContainer className={className}>
      <AccordionHeader selected={open} onClick={toggleActive}>
        {headerWithProps}
      </AccordionHeader>
      <AccordionBody
        selected={open}
        onClick={() => {
          bodyClose && toggleActive();
        }}
        ref={contentRef}
      >
        {bodyChild}
      </AccordionBody>
    </AccordionContainer>
  );
};

export default Accordion;
