import { ProgramError } from "@project-serum/anchor";
import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { PublicKey } from "@solana/web3.js";
import Big from "big.js";
import { useState, useMemo } from "react";
import styled from "styled-components";
import { useClient } from "../../../hooks/useClient";
import { useFlex } from "../../../hooks/useFlex";
import { useSnowflakeSafe } from "../../../hooks/useSnowflake";
import { useUtils } from "../../../hooks/useUtils";
import {
  useNotificationStore,
  NotificationType,
} from "../../../stores/useNotificationStore";
import {
  getAuctionState,
  AuctionState,
  getMintMapping,
} from "../../../utils/general";
import { Paragraph, Subtitle } from "../../Text";
import { Input } from "../../sharedStyles";
import { Button } from "../../Button";

export interface PurchaseInterfaceProps {
  selectedAuction: PublicKey | undefined;
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
  /**
   * Bid currency mint if any bid currency is allowed
   */
  bidCurrencyMint?: string | undefined;
}

export const PurchaseInterface = ({
  selectedAuction,
  className,
  bidCurrencyMint,
}: PurchaseInterfaceProps) => {
  const [totalPremiumInput, setTotalPremiumInput] = useState<string>("");
  const client = useClient();
  const flex = useFlex();
  const { createProposal } = useSnowflakeSafe();
  const notify = useNotificationStore((state) => state.notify);
  const {
    getBidCurrencyBalance,
    convertNativeNumberToDecimal,
    convertDecimalToNativeMintNumber,
    getMultiplier,
    getOptionAuction,
    getOptionPrecision,
  } = useUtils();

  const wallet = useSafeWallet();

  const auction = useMemo(
    () => getOptionAuction(selectedAuction),
    [getOptionAuction, selectedAuction]
  );

  const anyBidCurrencyMint = useMemo(() => {
    if (!bidCurrencyMint) return undefined;
    return new PublicKey(bidCurrencyMint);
  }, [bidCurrencyMint]);

  const precision = useMemo(() => {
    const fallback = 6;
    if (!selectedAuction || !flex.isInitialized) return fallback;

    if (!auction) return fallback;

    return (
      getOptionPrecision(anyBidCurrencyMint || auction.bidCurrencyMint) ??
      fallback
    );
  }, [
    getOptionPrecision,
    flex.isInitialized,
    selectedAuction,
    auction,
    anyBidCurrencyMint,
  ]);

  const inputProps = useMemo(() => {
    const inputStep = Big(1).div(Big(10).pow(precision)).toNumber();
    const placeholder = "0." + "0".repeat(precision);

    return { inputStep, placeholder };
  }, [precision]);

  const totalPremiumVal = useMemo(() => {
    // Only round when dp > precision otherwise cannot have '1.100' in input
    // It will becoem '1 .1'
    const retVal: string =
      totalPremiumInput.split(".")[1]?.length > precision
        ? Big(totalPremiumInput)
            .round(precision, Big.roundDown)
            .toFixed(precision)
            .toString()
        : totalPremiumInput;

    return retVal;
  }, [precision, totalPremiumInput]);

  const bidCurrencyStr = useMemo(() => {
    if (!auction) return "-";
    const bidCurrency = getMintMapping(auction.bidCurrencyMint);
    return bidCurrency === "11111111111111111111111111111111"
      ? "Any"
      : bidCurrency;
  }, [auction]);

  const pricePerUnderlyingVal = useMemo(() => {
    let retVal: string;
    const fallback = "";
    if (!flex.isInitialized || !totalPremiumVal) return fallback;

    if (!auction) return fallback;

    const multiplier = getMultiplier(auction.optionInfo.optionMint);

    const putMultiplier =
      1 /
      (convertNativeNumberToDecimal(auction.optionInfo.strike.toNumber()) * 10);

    if (!multiplier) return fallback;

    retVal =
      auction.optionInfo.kind == "call"
        ? Big(totalPremiumVal).div(auction.amount).div(multiplier).toString()
        : Big(totalPremiumVal)
            .div(auction.amount * putMultiplier)
            .toString();
    // Only round when dp > precision otherwise cannot have '1.100' in input
    // It will becoem '1 .1'
    retVal =
      retVal.split(".")[1]?.length > precision
        ? Big(retVal)
            .round(precision, Big.roundDown)
            .toFixed(precision)
            .toString()
        : retVal;

    return retVal;
  }, [
    precision,
    flex.isInitialized,
    totalPremiumVal,
    auction,
    getMultiplier,
    convertNativeNumberToDecimal,
  ]);

  const handleBuyClick = async () => {
    if (!wallet.publicKey || !client.isInitialized || !flex.isInitialized)
      return;

    if (!auction) return;
    const callback = notify(
      {
        title: "Placing bid",
      },
      true
    );
    try {
      const bidPrice = new Big(totalPremiumInput);

      if (bidPrice.lte(0)) return;

      const formattedPrice = convertDecimalToNativeMintNumber(
        bidPrice.toNumber(),
        anyBidCurrencyMint || auction.bidCurrencyMint
      );

      if (!formattedPrice) return;

      const returnData = await client.data.client.bidAuction(
        formattedPrice,
        auction.address,
        anyBidCurrencyMint,
        wallet.isSafeApp
      );

      await createProposal("Bid Auction", returnData);
      client.data.updateClient();

      callback({
        title: "Bid placed",
        type: NotificationType.SUCCESS,
      });
    } catch (err) {
      console.error(err);
      if (err instanceof ProgramError)
        callback({
          title: "Place bid failed",
          type: NotificationType.ERROR,
          description: err.msg,
          code: err.code,
        });
      else if (err instanceof Error)
        callback({
          title: "Place bid failed",
          type: NotificationType.ERROR,
        });
    }
  };

  const isBuyDisabled: boolean = useMemo(() => {
    if (
      !flex.isInitialized ||
      !client.isInitialized ||
      !wallet.connected ||
      !totalPremiumInput ||
      !selectedAuction
    )
      return true;

    if (!auction) return true;

    const bigPriceInput = Big(totalPremiumInput);
    const bidCurrencyBalance = getBidCurrencyBalance(
      auction,
      client.data.client,
      anyBidCurrencyMint
    );
    // PRTODO: bidCurrencyBalance to always be defined
    if (!bidCurrencyBalance) return true;

    if (bidCurrencyBalance && bigPriceInput.gt(bidCurrencyBalance)) return true;

    return (
      !bigPriceInput.toNumber() ||
      !(getAuctionState(auction) === AuctionState.Live) ||
      !!client.data?.bids.find((b) => b.auctionAccount.equals(selectedAuction))
    );
  }, [
    flex.isInitialized,
    client.isInitialized,
    client.data?.client,
    client.data?.bids,
    wallet.connected,
    totalPremiumInput,
    selectedAuction,
    auction,
    getBidCurrencyBalance,
    anyBidCurrencyMint,
  ]);

  return (
    <PurchaseInterfaceContainer className={className}>
      <PriceWrapper>
        <Label>Total Premium</Label>
        <PriceInputContainer>
          <Input
            type="number"
            min={0}
            step={inputProps.inputStep}
            placeholder={inputProps.placeholder}
            disabled={!client}
            onChange={(event) => {
              setTotalPremiumInput(event.target.value);
            }}
            value={totalPremiumVal}
          />
        </PriceInputContainer>
      </PriceWrapper>
      <PriceWrapper>
        <ValueContainer>
          <LabelValue color="subtext" bold>
            {bidCurrencyStr}
          </LabelValue>
        </ValueContainer>
        <Label>Bid Currency</Label>
      </PriceWrapper>
      <PriceWrapper>
        <ValueContainer>
          <LabelValue color="subtext" bold>
            {pricePerUnderlyingVal || "-"}
          </LabelValue>
        </ValueContainer>
        <Label>Price Per Underlying</Label>
      </PriceWrapper>
      <BuyButton
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleBuyClick}
        disabled={isBuyDisabled}
        title={
          isBuyDisabled
            ? "Requirements not met, you can still attempt to place a bid."
            : undefined
        }
        label="Buy"
        color="callToAction"
      />
    </PurchaseInterfaceContainer>
  );
};
const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start
  width: 100%;
`;

const PurchaseInterfaceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;
`;

const Label = styled(Paragraph)`
  width: 100%;
  font-weight: 500;
`;

const LabelValue = styled(Subtitle)``;

const ValueContainer = styled.div`
  width: 100%;
  max-width: 100%;
  border-radius: 3px;
  margin-top: 0.5rem;
  background: ${(props) => props.theme.background.secondary};
`;

const PriceInputContainer = styled(ValueContainer)`
  background: ${(props) => props.theme.background.primary};
  padding: 0.25re;
`;

export const BuyButton = styled(Button)<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 16px;
  background: ${(props) =>
    props.disabled
      ? props.theme.buttons.unselectable
      : props.theme.buttons.callToAction};
  &:hover {
    filter: brightness(1.2);
  }
  * {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "cursor")};
  }
  width: 100%;
  height: 2rem;
  border-radius: 5px;
  color: ${(props) =>
    props.disabled ? props.theme.background.primary : "white"};
  font-weight: 600;
  margin: 0.5rem 0;
`;
