import styled, { css } from "styled-components";
import { Paragraph } from "../../Text";

interface TabProps {
  /**
   * The selected tab
   */
  selected: TabType;
  /**
   * Callback that will be fired on tab change
   */
  onTabChange: (tab: TabType) => void;
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

export enum TabType {
  OpenBids,
  CancellableBids,
}

export const Tabs = ({ selected, onTabChange, className }: TabProps) => {
  return (
    <TabsContainer className={className}>
      <Tab onClick={() => onTabChange(TabType.OpenBids)}>
        <TabText selected={selected === TabType.OpenBids}>Open Bids</TabText>
      </Tab>
      <Tab onClick={() => onTabChange(TabType.CancellableBids)}>
        <TabText selected={selected === TabType.CancellableBids}>
          Cancellable Bids
        </TabText>
      </Tab>
    </TabsContainer>
  );
};

const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  min-height: 60px;
`;

const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 1rem;

  height: 100%;
`;

const TabText = styled(Paragraph).attrs(() => ({
  color: "subtext",
}))<{ selected: boolean }>`
  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      font-weight: bold;
      color: ${props.theme.font.highlight};
      cursor: auto;
    `};
`;
