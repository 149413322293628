import styled from "styled-components";
import { Button } from "../Button";
import { Modal } from "../Modal";
import { Subtitle, Header } from "../Text";

export const TCsModal = styled(Modal)`
  width: 80%;
  /* height: 80%; */

  max-width: 500px;
  max-height: 600px;

  @media only screen and (max-width: 450px) {
    max-height: 80%;
  }
  background: #030c1c;
`;

export const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;

  ${Header} {
    font-size: 24px;
  }

  margin-bottom: 2rem;
`;

export const TCsContent = styled.div`
  padding: 1rem;
  border-radius: 5px;
  white-space: pre-wrap;
  background: ${(props) => props.theme.background.secondary};
  border-radius: 4px;
  overflow-y: scroll;

  /* 
  Format the TCs properly
  Otherwise 2-3 words show on a line
  Somtimes
  */
  word-break: break-all;
  /* For some reason the list number doesn't show up as white */
  li {
    color: white;
  }
`;

export const ControlsContainer = styled.div`
  margin-top: 1rem;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  * {
    cursor: pointer;
  }
  margin: 1rem 0;
`;

export const CheckBoxText = styled(Subtitle)`
  margin-left: 1rem;
`;

export const AgreeAndContinueButton = styled(Button)`
  margin-top: 0.5rem;
  width: 100%;
  height: 2rem;
  background: ${(props) => props.theme.buttons.callToAction};
  border: ${(props) => `1px solid ${props.theme.buttons.callToAction}`};
  font-size: 16px;
  color: ${(props) => props.theme.font.primary};

  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  &:hover {
    filter: brightness(1.1);
  }
`;
