import { PublicKey } from "@solana/web3.js";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { useClient } from "../../../hooks/useClient";
import {
  AuctionState,
  getAuctionState,
  getMintMapping,
  shortenAddress,
} from "../../../utils/general";
import { ClientSectionViewContainer, CooldownLabel } from "./styles";
import {
  Row,
  Data,
  DataText,
  Table,
  TableHead,
  Heading,
  HeadingText,
  TableBody,
  HeaderRow,
} from "../../TableStyles";
import { TimeCountdown } from "../../TimeCountdown";
import { useFlex } from "../../../hooks/useFlex";
import {
  NotificationType,
  useNotificationStore,
} from "../../../stores/useNotificationStore";
import { ProgramError } from "@project-serum/anchor";
import { useUtils } from "../../../hooks/useUtils";
import { envVars } from "../../../utils/envVars";
import { useSnowflakeSafe } from "../../../hooks/useSnowflake";
import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { ClientButton } from "../Option/styles";
import { EmptyMessage } from "../Option/EmptyMessage";

const NUM_TABLE_COLUMNS =
  envVars.VITE_DISABLE_BLIND_AUCTIONS === "true" ? 8 : 7;

export const OpenBids = () => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [cooldownTrigger, setCooldownTrigger] = useState<{}>({});
  const client = useClient();
  const { createProposal } = useSnowflakeSafe();
  const wallet = useSafeWallet();
  const flex = useFlex();
  const notify = useNotificationStore((state) => state.notify);
  const {
    convertNativeMintNumberToDecimal,
    convertNativeNumberToDecimal,
    getMultiplier,
    getTokenExchangeRate,
  } = useUtils();

  const bids = useMemo(() => {
    if (!client.isInitialized || !flex.isInitialized) return null;

    const openSpotBids = client.data.bids.find((o) => !o.optionAccount);
    if (!openSpotBids) {
      return <EmptyMessage message={"There are no open bids."} />;
    }

    return client.data.bids.map((bid) => {
      // Using flex auctions here for
      const auction = flex.data.spotAuctions.find((a) =>
        a.address.equals(bid.auctionAccount)
      );

      if (!auction) return;
      // auctions can be null when cancelled
      let bidCurrencyMint: PublicKey | null = auction.bidCurrencyMint;

      if (bidCurrencyMint.toString() === "11111111111111111111111111111111") {
        bidCurrencyMint = bid.bidCurrencyMint;
      }
      const underlyingStr: string = getMintMapping(auction.auctionTokenMint);
      const sizeInUnderlying = convertNativeMintNumberToDecimal(
        auction.amount,
        auction.auctionTokenMint
      );

      const bidCurrency = !bidCurrencyMint
        ? null
        : getMintMapping(bidCurrencyMint);
      const bidCurrencyTitle =
        bidCurrency === "11111111111111111111111111111111"
          ? bid.bidCurrencyMint?.toString()
          : bidCurrency || "-";

      const bidCurrencyStr =
        bidCurrencyTitle && bidCurrencyTitle.length > 10
          ? shortenAddress(bidCurrencyTitle)
          : bidCurrencyTitle;

      const totalPremium = bidCurrencyMint
        ? convertNativeMintNumberToDecimal(bid.amount, bidCurrencyMint)
        : "-";

      const counterparty = auction ? auction.creator.toString() : "-";

      const state = auction ? getAuctionState(auction) : AuctionState.Cancelled;
      const bidEndExpiryDate = new Date(bid.bidEnd * 1000);
      const cooldownEndExpiryDate = new Date(bid.cooldownEnd * 1000);

      const bestBid = () => {
        if (!auction) {
          return undefined;
        } else if (auction.exchangeAmount) {
          return convertNativeMintNumberToDecimal(
            auction.exchangeAmount,
            auction.bidCurrencyMint
          );
        } else {
          return auction.bids.length
            ? convertNativeMintNumberToDecimal(
                auction.bids[0].amount,
                auction.bidCurrencyMint
              )
            : undefined;
        }
      };

      const cancelButtonText = () => {
        switch (state) {
          case AuctionState.Live:
            return "Cancel";
          case AuctionState.Cooldown:
            return auction?.exchangeAmount !== 0 ? "Cancel" : "Cooldown";
          case AuctionState.Expired:
            return "Cancel";
          case AuctionState.Cancelled:
            return "Cancel";
        }
      };

      const BidTimeRemaining = () => {
        switch (state) {
          case AuctionState.Live:
            return (
              <DataText>
                <TimeCountdown
                  key={AuctionState.Live}
                  date={bidEndExpiryDate}
                  onComplete={() =>
                    setInterval(() => setCooldownTrigger({}), 5000)
                  }
                />
              </DataText>
            );
          case AuctionState.Cooldown:
            return (
              <>
                <CooldownLabel>Cooldown</CooldownLabel>
                <DataText>
                  <TimeCountdown
                    key={AuctionState.Cooldown}
                    date={cooldownEndExpiryDate}
                    onComplete={() =>
                      setInterval(() => setCooldownTrigger({}), 5000)
                    }
                  />
                </DataText>
              </>
            );
          case AuctionState.Expired:
            return <DataText color="negative">Auction Lost</DataText>;
          case AuctionState.Cancelled:
            return <DataText color="negative">Auction Cancelled</DataText>;
        }
      };

      const handleCancel = async () => {
        const callback = notify(
          {
            title: "Cancelling bid",
          },
          true
        );
        try {
          const returnData = await client.data.client.cancelBid(
            bid.address,
            wallet.isSafeApp
          );

          await createProposal("Cancel Bid", returnData);
          client.data.updateClient();
          callback({
            title: "Bid cancelled",
            type: NotificationType.SUCCESS,
          });
        } catch (err) {
          console.error(err);
          if (err instanceof ProgramError)
            callback({
              title: "Cancel bid failed",
              type: NotificationType.ERROR,
              description: err.msg,
              code: err.code,
            });
          else if (err instanceof Error)
            callback({
              title: "Cancel bid failed",
              type: NotificationType.ERROR,
            });
        }
      };

      return (
        <Row columns={NUM_TABLE_COLUMNS} key={bid.address.toString()}>
          {/* Underlying */}
          <Data>
            <DataText title={underlyingStr}>{underlyingStr}</DataText>
          </Data>
          {/* Bid Currency*/}
          <Data>
            <DataText title={bidCurrencyTitle}>{bidCurrencyStr}</DataText>
          </Data>
          {/* My Bid (Total Premium) */}
          <Data>
            <DataText title={totalPremium?.toString()}>{totalPremium}</DataText>
          </Data>
          {/* Size in Underlying */}
          <Data>
            <DataText
              title={sizeInUnderlying ? sizeInUnderlying.toString() : "-"}
            >
              {sizeInUnderlying}
            </DataText>
          </Data>
          {/* Counterparty */}
          <Data>
            <DataText title={counterparty}>{counterparty}</DataText>
          </Data>
          {/* Bid Time Remaining */}
          <Data>
            <BidTimeRemaining />
          </Data>
          {/* Best Bid (Total Premium) */}
          {envVars.VITE_DISABLE_BLIND_AUCTIONS === "true" && (
            <Data>
              <DataText
                color={
                  auction
                    ? auction.exchangeAmount
                      ? "negative"
                      : bestBid() ===
                        (bidCurrencyMint
                          ? convertNativeMintNumberToDecimal(
                              bid.amount,
                              bidCurrencyMint
                            )
                          : 0)
                      ? "positive"
                      : "negative"
                    : "negative"
                }
                title={"best bid"}
              >
                {"best bid"}
              </DataText>
            </Data>
          )}
          <Data>
            <ClientButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleCancel}
              disabled={cancelButtonText() !== "Cancel"}
              label={cancelButtonText()}
            />
          </Data>
        </Row>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    client.isInitialized,
    client.data?.bids,
    client.data?.client,
    getMultiplier,
    flex.isInitialized,
    convertNativeNumberToDecimal,
    convertNativeMintNumberToDecimal,
    notify,
    cooldownTrigger,
    getTokenExchangeRate,
  ]);

  return (
    <OpenBidsContainer>
      <Table>
        <TableHead>
          <HeaderRow
            columns={
              client.data?.bids.length
                ? NUM_TABLE_COLUMNS
                : NUM_TABLE_COLUMNS - 1 // There will be no cancel button
            }
          >
            <Heading>
              <HeadingText title="Auction Token">AUCTION TOKEN</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Bid Currency">BID CURRENCY</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Bid Amount">BID AMOUNT</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Quantity">QUANTITY</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Counterparty">COUNTERPARTY</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title="Bid Time Remaining">
                BID TIME REMAINING
              </HeadingText>
            </Heading>
            {envVars.VITE_DISABLE_BLIND_AUCTIONS === "true" && (
              <Heading>
                <HeadingText title="Best Bid (Total Premium)">
                  BEST BID (TOTAL PREMIUM)
                </HeadingText>
              </Heading>
            )}
          </HeaderRow>
        </TableHead>
        <TableBody>{bids}</TableBody>
      </Table>
    </OpenBidsContainer>
  );
};
const OpenBidsContainer = styled(ClientSectionViewContainer)``;
