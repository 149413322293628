import { Flex } from "@zetamarkets/flex-sdk";
import Big from "big.js";
import { useMemo } from "react";
import { useClient } from "../../hooks/useClient";
import { useFlex } from "../../hooks/useFlex";
import {
  capitalizeFirstLetter,
  getFormattedExpiry,
  getMintMapping,
  shortenAddress,
} from "../../utils/general";
import { DashboardEmptyMessage, ExpiredContainer } from "./styles";
import {
  Row,
  Data,
  DataText,
  Table,
  TableHead,
  Heading,
  HeadingText,
  TableBody,
  DataTextLink,
  HeaderRow,
  ButtonData,
} from "../TableStyles";
import {
  NotificationType,
  useNotificationStore,
} from "../../stores/useNotificationStore";
import { ProgramError } from "@project-serum/anchor";
import { useUtils } from "../../hooks/useUtils";
import {
  FlexOptionType,
  Option,
  Position,
  SettlementType,
} from "@zetamarkets/flex-sdk/dist/flex/types";
import { ExpiredButton } from "./ExpiredButton";
import { Subtitle } from "../Text";
import { Accordion } from "../Accordion";
import { PublicKey } from "@solana/web3.js";
import { BN } from "bn.js";
import { useSnowflakeSafe } from "../../hooks/useSnowflake";
import { useSafeWallet } from "@snowflake-so/safe-adapter-react";

export const ExercisableOptions = () => {
  const client = useClient();
  const { createProposal } = useSnowflakeSafe();
  const flex = useFlex();
  const {
    convertNativeMintNumberToDecimal,
    convertNativeNumberToDecimal,
    getMultiplier,
    getTokenExchangeRate,
  } = useUtils();
  const wallet = useSafeWallet();
  const notify = useNotificationStore((state) => state.notify);

  const expired = useMemo(() => {
    if (!client.isInitialized) return null;
    if (!flex.isInitialized) return null;
    const filteredNormalPositions = client.data.client.optionPositions.filter(
      (pos) => {
        const optionInfo = Flex.getOption(
          pos.optionAccount,
          FlexOptionType.Normal
        );
        if (optionInfo == null) return false;
        const now = Flex.clockTimestamp;
        const expiry = optionInfo.expiry;
        const settlementStartDate = optionInfo.settlementStart;

        if (optionInfo.settlementType === SettlementType.CASH) {
          return now > expiry;
        } else {
          return settlementStartDate < now && now < expiry;
        }
      }
    );

    const convertToOptionRow = (positions: Position[], isCombo?: boolean) => {
      return positions.map((pos) => {
        const optionInfo = Flex.getOption(
          pos.optionAccount,
          isCombo ? FlexOptionType.Combo : FlexOptionType.Normal
        );
        if (optionInfo == null) return null;
        const pnl = convertNativeMintNumberToDecimal(
          optionInfo.profitPerOption * pos.amount,
          optionInfo.collateralMint
        );
        const pnlStr = (pnl || "-").toString();
        const multiplier = getMultiplier(optionInfo.optionMint);
        // No phys settled puts - would need to convert with mint decimals in future
        const putMultiplier = isCombo
          ? 1
          : 1 /
            (convertNativeNumberToDecimal(
              (optionInfo as Option).strike.toNumber()
            ) *
              10);
        const tokenAddress = optionInfo.optionMint.toString();
        const shortenedTokenAddress = shortenAddress(
          optionInfo.optionMint.toString()
        );
        const underlyingCurrency = getMintMapping(optionInfo.underlyingMint);
        const collateralCurrency = getMintMapping(optionInfo.collateralMint);
        const exerciseCurrency =
          optionInfo.settlementType === SettlementType.PHYSICAL
            ? getMintMapping((optionInfo as Option).exerciseMint)
            : "-";
        const kind = isCombo
          ? "-"
          : capitalizeFirstLetter(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              (optionInfo as Option).kind
            );
        const strike = isCombo
          ? "-"
          : optionInfo.settlementType === SettlementType.PHYSICAL
          ? convertNativeMintNumberToDecimal(
              (optionInfo as Option).strike.toNumber(),
              (optionInfo as Option).exerciseMint
            )
          : convertNativeNumberToDecimal(
              (optionInfo as Option).strike.toNumber()
            );
        const sizeInUnderlying = isCombo
          ? pos.amount
          : (optionInfo as Option).kind == "call" && multiplier
          ? Big(pos.amount).mul(multiplier).toNumber()
          : pos.amount * putMultiplier;
        const exercisableStartDate = getFormattedExpiry(
          new Date(optionInfo.settlementStart * 1000).getTime() / 1000,
          "exercisable-options-expiry"
        );
        const settlementEndDate = getFormattedExpiry(
          new Date(optionInfo.expiry * 1000).getTime() / 1000,
          "exercisable-options-expiry"
        );
        const settlementType =
          optionInfo.settlementType === SettlementType.CASH
            ? "Cash"
            : optionInfo.settlementType === SettlementType.PHYSICAL
            ? "Physical"
            : "Undefined";
        const optionType = isCombo ? "Combo" : "Normal";

        const explorerLink = `https://solana.fm/address/${optionInfo.optionMint.toString()}?cluster=mainnet-qn1`;

        const handleSettle = (qty?: number) => {
          if (client.data === undefined || optionInfo === undefined) return;

          const exerciseOption = isCombo
            ? client.data.client.exerciseComboOption(
                optionInfo.optionAccount,
                qty,
                wallet.isSafeApp
              )
            : client.data.client.exerciseOption(
                optionInfo.optionAccount,
                qty,
                wallet.isSafeApp
              );

          const callback = notify(
            {
              title: isCombo ? "Exercising combo option" : "Exercising option",
            },
            true
          );
          exerciseOption
            .then(async (returnData) => {
              await createProposal(
                isCombo ? "Exercise Combo Option" : "Exercise Option",
                returnData
              );

              callback({
                title: "Option settled",
                type: NotificationType.SUCCESS,
              });
              client.data.updateClient();
            })
            .catch((err) => {
              console.error(err);
              if (err instanceof ProgramError)
                callback({
                  title: "Settling failed",
                  type: NotificationType.ERROR,
                  description: err.msg,
                  code: err.code,
                });
              else if (err instanceof Error)
                callback({
                  title: "Settling failed",
                  type: NotificationType.ERROR,
                });
            });
        };
        return (
          <Row
            columns={13}
            key={pos.optionAccount.toString()}
            selectable={isCombo}
          >
            {/* Option Type */}
            <Data>
              <DataText title={optionType}>{optionType}</DataText>
            </Data>
            {/* Token address */}
            <Data>
              <DataTextLink
                title={tokenAddress}
                color="highlight"
                target="_blank"
                href={explorerLink}
              >
                {shortenedTokenAddress}
              </DataTextLink>
            </Data>
            {/* Underlying */}
            <Data>
              <DataText title={underlyingCurrency}>
                {underlyingCurrency}
              </DataText>
            </Data>
            {/* Collateral */}
            <Data>
              <DataText title={collateralCurrency}>
                {collateralCurrency}
              </DataText>
            </Data>
            {/* Settlement Type */}
            <Data>
              <DataText title={settlementType}>{settlementType}</DataText>
            </Data>
            {/* Exercise Currency */}
            <Data>
              <DataText title={exerciseCurrency}>{exerciseCurrency}</DataText>
            </Data>
            {/* Instrument */}
            <Data>
              <DataText title={kind}>{kind}</DataText>
            </Data>
            {/* Strike */}
            <Data>
              <DataText title={!strike ? "-" : strike.toString()}>
                {strike}
              </DataText>
            </Data>
            {/* Size */}
            <Data>
              <DataText title={sizeInUnderlying.toString()}>
                {sizeInUnderlying}
              </DataText>
            </Data>
            {/* PNL */}
            <Data>
              <DataText title={pnlStr}>{pnlStr}</DataText>
            </Data>
            {/* Exercisable time */}
            <Data>
              <DataText title={exercisableStartDate.toString()}>
                {exercisableStartDate}
              </DataText>
            </Data>
            {/* Expiration time */}
            <Data>
              <DataText title={settlementEndDate.toString()}>
                {settlementEndDate}
              </DataText>
            </Data>
            {/* Settle Button */}
            <ButtonData>
              <DataText>
                <ExpiredButton
                  option={optionInfo as Option}
                  position={pos}
                  disabled={pnl === undefined || pnl < 0}
                  onSettle={handleSettle}
                />
              </DataText>
            </ButtonData>
          </Row>
        );
      });
    };

    const comboPositions = client.data.client.comboOptionPositions.map(
      (pos) => {
        const comboPositionRow = {
          optionAccount: pos.comboOptionAccount,
          amount: pos.amount,
          strike: new BN(0),
          expiry: pos.expiry,
        };

        const optionInfo = Flex.getOption(
          pos.comboOptionAccount,
          FlexOptionType.Combo
        );

        const comboParams = (
          <Row columns={13} key={pos.comboOptionAccount.toString()}>
            {/* Option Type */}
            <Data>
              <Subtitle>Combo option parameters</Subtitle>
            </Data>
            {/* Underlying */}
            <Data></Data>
            {/* Settlement Type */}
            <Data></Data>
            {/* Exercise Currency */}
            <Data></Data>
            <Data></Data>
            <Data></Data>
            {/* Instrument */}
            <Data>
              <DataText>
                {pos.params.map((params, index) => {
                  return (
                    <Subtitle key={index}>
                      {capitalizeFirstLetter(params.kind)}
                    </Subtitle>
                  );
                })}
              </DataText>
            </Data>
            {/* Strike */}
            <Data>
              <DataText>
                {pos.params.map((params, index) => {
                  return (
                    <Subtitle key={index}>
                      {convertNativeMintNumberToDecimal(
                        params.strike,
                        optionInfo?.collateralMint || new PublicKey("")
                      )}
                    </Subtitle>
                  );
                })}
              </DataText>
            </Data>
            {/* Size */}
            <Data>
              <DataText>
                {pos.params.map((params, index) => {
                  return <Subtitle key={index}>{params.size / 1000}x</Subtitle>;
                })}
              </DataText>
            </Data>
            {/* Exercisable time */}
            <Data></Data>
            {/* Expiration time */}
            <Data></Data>
          </Row>
        );
        // });

        return (
          <Accordion
            key={pos.comboOptionAccount.toString()}
            headerChild={
              convertToOptionRow([comboPositionRow], true)[0] || (
                <Subtitle>Cooked</Subtitle>
              )
            }
            bodyChild={comboParams}
            defaultOpen
          />
        );
      }
    );

    const normalOptionRows = convertToOptionRow(filteredNormalPositions);

    return normalOptionRows.concat(comboPositions);
  }, [
    client.isInitialized,
    client.data?.expiredPositions,
    client.data?.client,
    flex.isInitialized,
    convertNativeMintNumberToDecimal,
    getMultiplier,
    convertNativeNumberToDecimal,
    getTokenExchangeRate,
    notify,
  ]);

  const exercisableOptionsOutput = useMemo(() => {
    if (!client.isInitialized) return null;
    if (!flex.isInitialized) return null;
    const filteredNormalPositions = client.data.client.optionPositions.filter(
      (pos) => {
        const optionInfo = Flex.getOption(
          pos.optionAccount,
          FlexOptionType.Normal
        );
        if (optionInfo == null) return false;
        const now = Flex.clockTimestamp;
        const expiry = optionInfo.expiry;
        const settlementStartDate = optionInfo.settlementStart;

        if (optionInfo.settlementType === SettlementType.CASH) {
          return now > expiry;
        } else {
          return settlementStartDate < now && now < expiry;
        }
      }
    );
    const filteredComboPositions =
      client.data.client.comboOptionPositions.filter((pos) => {
        const optionInfo = Flex.getOption(
          pos.comboOptionAccount,
          FlexOptionType.Combo
        );
        if (optionInfo == null) return false;
        const now = Flex.clockTimestamp;
        const expiry = optionInfo.expiry;
        return now > expiry;
      });

    if (!filteredNormalPositions.length && !filteredComboPositions.length)
      return (
        <DashboardEmptyMessage
          message={"There are no exercisable positions."}
        />
      );

    return <TableBody>{expired}</TableBody>;
  }, [client.data, expired]);

  return (
    <ExpiredContainer>
      <Table>
        <TableHead>
          <HeaderRow columns={13}>
            <Heading>
              <HeadingText title={"Option Type"}>OPTION TYPE</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Token Address"}>TOKEN ADDRESS</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Underlying"}>UNDERLYING</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Collateral"}>COLLATERAL</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Settlement Type"}>
                SETTLEMENT TYPE
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Exercise Currency"}>
                EXERCISE CURRENCY
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Instrument"}>INSTRUMENT</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Strike"}>STRIKE</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Size"}>SIZE</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"PNL"}>PNL</HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Exercisable time"}>
                EXERCISABLE TIME
              </HeadingText>
            </Heading>
            <Heading>
              <HeadingText title={"Expiration time"}>
                EXPIRATION TIME
              </HeadingText>
            </Heading>
          </HeaderRow>
        </TableHead>
        {exercisableOptionsOutput}
      </Table>
    </ExpiredContainer>
  );
};
