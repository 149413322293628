// @ts-nocheck
import { FlexEnv } from "../@types/dotenv";

export const envVars: FlexEnv = {
  VITE_DEVNET_RPC_URL:
    process.env.VITE_DEVNET_RPC_URL ?? import.meta.env?.VITE_DEVNET_RPC_URL,
  VITE_FLEX_PROGRAM_ID:
    process.env.VITE_FLEX_PROGRAM_ID ?? import.meta.env?.VITE_FLEX_PROGRAM_ID,
  VITE_FLEX_UPDATE_INTERVAL:
    process.env.VITE_FLEX_UPDATE_INTERVAL ??
    import.meta.env?.VITE_FLEX_UPDATE_INTERVAL,
  VITE_MAINNET_RPC_URL:
    process.env.VITE_MAINNET_RPC_URL ?? import.meta.env?.VITE_MAINNET_RPC_URL,
  VITE_NETWORK_TYPE:
    process.env.VITE_NETWORK_TYPE ?? import.meta.env?.VITE_NETWORK_TYPE,
  VITE_APP_PASSWORD:
    process.env.VITE_APP_PASSWORD ?? import.meta.env?.VITE_APP_PASSWORD,
  VITE_DISABLE_BLIND_AUCTIONS:
    process.env.VITE_DISABLE_BLIND_AUCTIONS ??
    import.meta.env?.VITE_DISABLE_BLIND_AUCTIONS,
};
