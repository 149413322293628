import { useState } from "react";
import { ExpiredButtonContainer } from "./styles";
import {
  Option,
  Position,
  SettlementType,
} from "@zetamarkets/flex-sdk/dist/flex/types";
import { TimeCountdown } from "../TimeCountdown";
import { SettlementModal } from "./SettlementModal";
import { ClientButton } from "../ClientSection/Option/styles";

export const ExpiredButton = ({
  option,
  position,
  onSettle: handleSettle,
  disabled,
}: {
  option: Option;
  position: Position;
  onSettle: (qty?: number) => void;
  disabled: boolean;
}) => {
  const [physicalSettleModalOpen, setPhysicalSettleModalOpen] =
    useState<boolean>(false);

  if (option.settlementType === SettlementType.CASH)
    return (
      <ExpiredButtonContainer>
        <ClientButton
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={() => {
            handleSettle();
          }}
          disabled={disabled}
          label="Settle"
          inverse
          color="highlight"
          action
        />
      </ExpiredButtonContainer>
    );

  const handlePhysicalSettle = () => {
    setPhysicalSettleModalOpen(true);
  };

  const settlementEndDate = new Date(option.settlementEnd * 1000);
  return (
    <ExpiredButtonContainer>
      <SettlementModal
        isOpen={physicalSettleModalOpen}
        onClose={() => setPhysicalSettleModalOpen(false)}
        option={option}
        position={position}
        onSettle={handleSettle}
      />
      <TimeCountdown date={settlementEndDate} />
      <ClientButton
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handlePhysicalSettle}
        disabled={disabled}
        label="Settle"
        action
      />
    </ExpiredButtonContainer>
  );
};
