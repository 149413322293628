import { useSafeWallet } from "@snowflake-so/safe-adapter-react";
import { useSafe } from "@snowflake-so/safe-apps-provider";
import { Signer, TransactionInstruction } from "@solana/web3.js";

export const useSnowflakeSafe = () => {
  const { isSafeApp } = useSafeWallet();
  const { sdk: snowflakeSafeSdk } = useSafe();

  const createProposal = async (
    proposalName: string,
    returnData:
      | {
          ixs: TransactionInstruction[];
          signers: Signer[];
        }
      | string
  ) => {
    if (isSafeApp && typeof returnData !== "string") {
      await snowflakeSafeSdk?.txs.createProposal({
        display: {
          proposalName,
        },
        executeInstructions: returnData.ixs,
        signers: returnData.signers.filter((signer) => signer),
      });
    }
  };

  return { createProposal };
};
