import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  html, body {
    margin: 0;
    background: #1f1f21;
    overflow-x: clip;

    /* Prevents text to be selected */
    /* iOS Safari */
    /* -webkit-touch-callout: none;  */
     /* Safari */
    /* -webkit-user-select: none; */
     /* Konqueror HTML */
    /* -khtml-user-select: none; */
     /* Old versions of Firefox */
    /* -moz-user-select: none; */
     /* Internet Explorer/Edge */
    /* -ms-user-select: none; */
     /* Non-prefixed version, currently */
    /* user-select: none; */


    @media only screen and (max-width: 450px) {
      input {
        -webkit-touch-callout: auto; /* iOS Safari */
        -webkit-user-select: auto; /* Safari */
        -khtml-user-select: auto; /* Konqueror HTML */
        -moz-user-select: auto; /* Old versions of Firefox */
        -ms-user-select: auto; /* Internet Explorer/Edge */
        user-select: auto; /* Non-prefixed version, currently */
      }
    }
  }

  html,
  div,
  body,
  button,
  h1,
  h2,
  p,
  small {
    margin: 0;
    padding: 0;
    font-family: "Heebo", sans serif;
    font-style: normal;
    font-weight: 400;
  }

  h1 {
    font-size: 32px;
    line-height: 36px;
  }

  h2 {
    font-size: 16px;
    line-height: 20px;
  }

  p {
    font-size: 12px;
    line-height: 16px;
  }

  small {
    font-size: 8px;
    line-height: 12px;
  }

  button {
    border: none;
    background: none;
  }

  a {
    text-decoration: none;
  }

  input {
    all: unset;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  @media only screen and (min-width: 450px) {
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: #171D2E;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      border: 1px solid #5F5B6C;
      background: #171D2E;
      
      border-radius: 5px;
      
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #5F5B6C;
    }
  }
`;
