import { useMemo } from "react";
import { ThemeProvider } from "styled-components";
import {
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { ConnectionProvider } from "@solana/wallet-adapter-react";
import { theme } from "./utils/theme";
import { getSolanaRpcUrl } from "./utils/connection";
import { GlobalStyle } from "./utils/globalStyles";
import { WalletModalProvider } from "@snowflake-so/safe-adapter-react-ui";
import { SafeProvider } from "@snowflake-so/safe-apps-provider";
import { SafeWalletProvider } from "@snowflake-so/safe-adapter-react";

interface ProvidersProps {
  children: React.ReactNode;
}

export const Providers = ({ children }: ProvidersProps) => {
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolletExtensionWalletAdapter(),
      new SolflareWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolletWalletAdapter(),
      new TorusWalletAdapter(),
      // new LedgerWalletAdapter(),
    ],
    []
  );
  const rpcEndpoint = getSolanaRpcUrl();

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={rpcEndpoint}>
        <SafeProvider>
          <SafeWalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
              <GlobalStyle />
              {children}
            </WalletModalProvider>
          </SafeWalletProvider>
        </SafeProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};
