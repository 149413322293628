import { ReactNode, useMemo } from "react";
import styled from "styled-components";
import { Paragraph } from "../../Text";
import { PublicKey } from "@solana/web3.js";
import { useUtils } from "../../../hooks/useUtils";

interface OrderbookProps {
  selectedAuction: PublicKey | undefined;
  /**
   * Classname that will be passed in to the root
   */
  className?: string;
}

export const Orderbook = ({ selectedAuction, className }: OrderbookProps) => {
  const { convertNativeMintNumberToDecimal, getOptionAuction } = useUtils();

  const auction = useMemo(
    () => getOptionAuction(selectedAuction),
    [getOptionAuction, selectedAuction]
  );
  const bidRows = useMemo(() => {
    let rowsToPad = 5;
    const auctionBids = auction?.bids;
    let bids: ReactNode[] = [];
    if (auctionBids) {
      bids = auctionBids.map((bid) => {
        return (
          <Row key={bid.address.toString()}>
            <Cell>
              <CellText>{bid.bidder.toString()}</CellText>
            </Cell>
            <Cell>
              <CellText>
                {convertNativeMintNumberToDecimal(
                  bid.amount,
                  auction.bidCurrencyMint
                )}
              </CellText>
            </Cell>
          </Row>
        );
      });

      rowsToPad = Math.max(0, rowsToPad - bids.length);
    }

    for (let i = 0; i < rowsToPad; i++) {
      bids.push(<Row key={i} />);
    }

    return bids;
  }, [
    auction?.bids,
    auction?.bidCurrencyMint,
    convertNativeMintNumberToDecimal,
  ]);

  return (
    <OrderbookContainer className={className}>
      <Table>
        <TableHeader>
          <Row>
            <Heading>
              <HeadingText>Bidder</HeadingText>
            </Heading>
            <Heading>
              <HeadingText>
                Bid <wbr /> (Total Premium)
              </HeadingText>
            </Heading>
          </Row>
        </TableHeader>
        <TableBody>{bidRows}</TableBody>
      </Table>
    </OrderbookContainer>
  );
};
const OrderbookContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  border: 1px solid #80828533;
`;
const Table = styled.table`
  border: 1px solid #80828533;
  border-collapse: collapse;
  width: 100%;
  height: 300px;

  table-layout: fixed;
`;
const TableHeader = styled.thead``;
const Heading = styled.th`
  border: 1px solid #80828533;
`;
const HeadingText = styled(Paragraph).attrs(() => ({
  color: "subtext",
}))`
  margin: 0.5rem 0;
  text-align: center;
`;
const TableBody = styled.tbody``;
const Row = styled.tr`
  height: 50px;
`;
const Cell = styled.td`
  text-align: center;
  border: 1px solid #80828533;
  vertical-align: middle;
  width: 50%;
  padding: 0.25rem;
  box-sizing: border-box;
`;
const CellText = styled(Paragraph)`
  margin: 0.5rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;
