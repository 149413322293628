import { Connection } from "@solana/web3.js";
import { Network } from "@zetamarkets/flex-sdk";
import { envVars } from "./envVars";

export const SOLANA_NETWORKS = {
  Mainnet: envVars.VITE_MAINNET_RPC_URL || "http://zeta.rpcpool.com/",
  Devnet: envVars.VITE_DEVNET_RPC_URL || "https://api.devnet.solana.com",
  Localnet: "http://127.0.0.1:8899",
  Testnet: "http://api.testnet.solana.com",
} as const;

let _connection: Connection | undefined;

export const getSolanaConnection = (): Connection => {
  if (_connection) {
    return _connection;
  }

  const url = getSolanaRpcUrl();
  _connection = new Connection(url, "processed");

  console.log("Created new connection", _connection);

  return _connection;
};

export const getSolanaRpcUrl = (): string => {
  switch (envVars.VITE_NETWORK_TYPE) {
    case "localnet":
      return SOLANA_NETWORKS.Localnet;
    case "devnet":
      return SOLANA_NETWORKS.Devnet;
    case "mainnet-beta":
      return SOLANA_NETWORKS.Mainnet;
    case "testnet":
      return SOLANA_NETWORKS.Testnet;
  }
};

export const getSolanaNetwork = (): Network => {
  switch (envVars.VITE_NETWORK_TYPE) {
    case "localnet":
      return Network.LOCALNET;
    case "mainnet-beta":
      return Network.MAINNET;
    case "devnet":
      return Network.DEVNET;
    default:
      return Network.DEVNET;
  }
};
