import Countdown, {
  CountdownRenderProps,
  CountdownProps,
} from "react-countdown";

export const TimeCountdown = (props: CountdownProps) => {
  return <Countdown {...props} renderer={countdownRenderer} />;
};

export const countdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
}: CountdownRenderProps) => {
  const daysStr = days ? `${days}d ` : "";
  const hoursStr = hours ? `${hours}h ` : "";
  const minutesStr = minutes ? `${minutes}m ` : "";
  const secondsStr = seconds ? `${seconds}s` : "";
  return <span>{`${daysStr}${hoursStr}${minutesStr}${secondsStr}`}</span>;
};
