import styled from "styled-components";
import { Navbar as FlexNavbar } from "../../components/Navbar";
import {
  OptionAuctionSelection,
  SpotAuctionSelection,
} from "../../components/AuctionSelection";
import {
  OptionClientSection,
  SpotClientSection,
} from "../../components/ClientSection";
import {
  OptionPurchaseSection,
  SpotPurchaseSection,
} from "../../components/PurchaseSection";
import { AuctionHeader } from "./AuctionHeader";

export const Navbar = styled(FlexNavbar)`
  grid-area: header;
`;

export const StyledAuctionHeader = styled(AuctionHeader)`
  grid-area: auction-header;
  margin: 0;
  padding-left: 1rem;
`;

export const SpotAuctionSection = styled(SpotAuctionSelection)`
  grid-area: auction-selection;
  overflow: hidden;
  max-height: 100%;
`;

export const StyledSpotClientSection = styled(SpotClientSection)`
  grid-area: client;
`;

export const StyledSpotPurchaseSection = styled(SpotPurchaseSection)`
  grid-area: purchase;
`;

export const OptionAuctionSection = styled(OptionAuctionSelection)`
  grid-area: auction-selection;
  overflow: hidden;
  max-height: 100%;
`;

export const StyledOptionClientSection = styled(OptionClientSection)`
  grid-area: client;
`;

export const StyledOptionPurchaseSection = styled(OptionPurchaseSection)`
  grid-area: purchase;
`;

export const AppContainer = styled.div<{ showPurchase: boolean }>`
  display: grid;
  flex-grow: 1;
  grid-template-columns: ${(props) =>
    props.showPurchase
      ? "[selection-end] 8fr [purchase-end] 270px"
      : "[selection-end] 8fr [purchase-end] 0px"};
  grid-template-rows: [ui-end] 80px 1fr 1fr;
  grid-template-areas:
    "auction-header purchase"
    "auction-selection purchase"
    "client            purchase";
  height: 100%;
  width: 100vw;
  position: relative;
  overflow: hidden;
  & > div {
    background: ${(props) => props.theme.background.primary};
    /* These props for the grid items not to grow out of their templates */
    overflow: hidden; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */
    min-height: 0; /* NEW; needed for Firefox */
  }
  ${StyledOptionPurchaseSection} {
    background: ${(props) => props.theme.background.secondary};
    overflow-y: auto;
  }
  ${StyledSpotPurchaseSection} {
    background: ${(props) => props.theme.background.secondary};
    overflow-y: auto;
  }
  ${Navbar} {
    /* This allows the wallet pop show combatting the overflow: hidden above */
    overflow: unset;
  }
  /* --------------------------------------------- */
`;
