import styled from "styled-components";

export const PurchaseSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem;
  border-left: ${(props) => `1px solid ${props.theme.background.table}`};
`;

export const AuctionPropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  > * + * {
    margin-top: 1rem;
  }
  margin: 0.5rem 0 1rem 0;
`;

export const AuctionProperty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TextSpan = styled.span`
  display: flex;
  > * + * {
    margin-left: 0.3rem;
  }
`;
