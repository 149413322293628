import Big from "big.js";
import { ComponentProps, useEffect, useMemo, useState } from "react";
import { useClient } from "../../hooks/useClient";
import {
  ExerciseInput,
  InputContainer,
  InputHeading,
  InterfaceContainer,
  MaxButton,
  SettleButton,
  SettlementModalContainer,
  HeadingSpan,
  TextLabel,
  TextValue,
  TokenAmountContainer,
  HelperTextContainer,
  InlineHighlightParagraph,
} from "./styles";
import { useUtils } from "../../hooks/useUtils";
import { Kind, Option, Position } from "@zetamarkets/flex-sdk/dist/flex/types";
import { Modal } from "../Modal";
import { Paragraph, Subtitle } from "../Text";
import { getMintMapping } from "../../utils/general";

type SettlementModalProps = ComponentProps<typeof Modal> & {
  option: Option;
  position: Position;
  onSettle: (qty?: number) => void;
};

export const SettlementModal = ({
  isOpen,
  option,
  position,
  onClose: handleClose,
  onSettle: handleSettle,
}: SettlementModalProps) => {
  const [input, setInput] = useState<string>("");
  const [tokenMultipler, setTokenMultipler] = useState<number>();
  const client = useClient();
  const {
    convertNativeMintNumberToDecimal,
    convertNativeNumberToDecimal,
    getMultiplier,
  } = useUtils();

  useEffect(() => {
    if (!client.isInitialized) return;
    if (tokenMultipler) return;

    let multiplier = getMultiplier(option.optionMint);
    if (option.kind === Kind.PUT) {
      multiplier =
        1 / (convertNativeNumberToDecimal(option.strike.toNumber()) * 10);
    }
    setTokenMultipler(multiplier);
  }, [
    client.data?.client.tokenAccounts,
    client.isInitialized,
    option,
    tokenMultipler,
  ]);

  const collateralCurrency = getMintMapping(option.collateralMint);
  const exerciseCurrency = getMintMapping(option.exerciseMint);
  const strike = convertNativeMintNumberToDecimal(
    option.strike.toNumber(),
    option.exerciseMint
  );
  const kindStr = option.kind === Kind.CALL ? "Call" : "Put";
  const tokenAmount = useMemo(() => {
    if (!input) return "-";
    if (!tokenMultipler) return "Loading...";

    return Big(input).toNumber();
  }, [input, tokenMultipler]);

  return (
    <SettlementModalContainer
      isOpen={isOpen}
      closeOnOverlayClick
      onClose={handleClose}
    >
      <InterfaceContainer>
        <HeadingSpan>
          <InputHeading bold>Exercise</InputHeading>
          <InputHeading bold color="product">
            {kindStr}
          </InputHeading>
        </HeadingSpan>
        <TextLabel color="subtext">TOKEN AMOUNT</TextLabel>
        <InputContainer>
          <ExerciseInput
            placeholder="0.00"
            step="0.0001"
            type="number"
            min={0}
            max={position.amount}
            onChange={(event) => {
              if (!event.target.value || !tokenMultipler) return setInput("");
              setInput(
                Math.min(
                  parseFloat(event.target.value),
                  position.amount * tokenMultipler
                ).toString()
              );
            }}
            value={input}
          />
          <MaxButton
            onClick={() => {
              if (!tokenMultipler) return;
              setInput(
                Big(position.amount).mul(tokenMultipler).toNumber().toString()
              );
            }}
          >
            Max
          </MaxButton>
        </InputContainer>

        <TokenAmountContainer>
          <TextLabel>MAX EXERCISABLE SIZE</TextLabel>
          <TextValue>
            {!tokenMultipler
              ? "-"
              : Big(position.amount).mul(tokenMultipler).toNumber()}
          </TextValue>
        </TokenAmountContainer>
        <SettleButton
          color={"callToAction"}
          isBold
          disabled={parseFloat(input) === 0 || !input}
          onClick={() => {
            if (!tokenMultipler || parseFloat(input) === 0 || !input) return;
            handleSettle(Big(parseFloat(input)).div(tokenMultipler).toNumber());
          }}
          label="Settle"
        />
      </InterfaceContainer>

      <HelperTextContainer>
        <Subtitle>
          You are about to{" "}
          <InlineHighlightParagraph>
            buy {input === "" ? 0 : input} {collateralCurrency}
          </InlineHighlightParagraph>{" "}
          at a price of{" "}
          <InlineHighlightParagraph>
            {strike || "-"} {exerciseCurrency} per token
          </InlineHighlightParagraph>
          .
        </Subtitle>
        <Subtitle>
          You will need{" "}
          <InlineHighlightParagraph>
            {typeof tokenAmount === "string" || !strike
              ? 0
              : tokenAmount * strike}{" "}
            {exerciseCurrency}
          </InlineHighlightParagraph>{" "}
          in your wallet to perform this transaction.
        </Subtitle>
        <Paragraph color="selectable">
          You should check the market price of {collateralCurrency} to ensure
          you do not overpay for this transaction.
        </Paragraph>
      </HelperTextContainer>
    </SettlementModalContainer>
  );
};
