import styled from "styled-components";

export const WalletContainer = styled.div<{ connected: boolean }>`
  .wallet-adapter-button {
    font-size: 12px;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    width: 140px;
    border: ${(props) => `1px solid ${props.theme.background.secondary}`};
    background: ${(props) => props.theme.background.primary};
    color: ${(props) => props.theme.font.primary};
    transition: 200ms;
    &:hover {
      font-weight: 600;
      background: ${(props) => props.theme.background.secondary};
    }
  }

  .wallet-adapter-button-start-icon {
    display: none;
  }

  .wallet-adapter-dropdown-list-item {
    border: ${(props) => `1px solid ${props.theme.background.secondary}`};
    background: ${(props) => props.theme.background.primary};
    &:hover {
      background: ${(props) => props.theme.background.secondary};
    }
  }

  @media only screen and (max-width: 450px) {
    .wallet-adapter-button {
      margin: 0;
      width: fit-content;
    }
  }
`;
